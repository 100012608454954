import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { Subscription } from 'rxjs';
import { StateService } from '@uirouter/core';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { VIEW_NAME } from 'src/app/shared/tokens';
import { ClientFilterService } from './client-filter/client-filter.service';
import { ClientSettingsComponent } from '../settings/client-settings.component';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-client-list',
  template: '<wp-entity-list></wp-entity-list> ',
  providers: [
    { provide: FilterService, useClass: ClientFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
})
export class ClientListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;

  constructor(
    @Inject(VIEW_NAME) private viewName: string,
    private service: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private modal: NgbModal,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'projects.clients.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'Organization',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('client', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
    ]);

    this.selectedRowListener = this.gridService.selectedGroup$.subscribe(
      (row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission('Organization', PermissionType.Modify);
      },
    );
  }

  private create = () => {
    this.modal.open(ClientSettingsComponent, { size: 'lg' });
  };

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
  }
}
