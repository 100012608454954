@for (selectedKpiType of selectedKpiTypes; track selectedKpiType) {
  <tr class="project-summary__right project__row-total">
    @for (total of getTotalsByKpiType(selectedKpiType); track total.id) {
      <ng-container>
        <td
          class="project__row-total-cell"
          [ngStyle]="{
            width: summaryService.slotWidth + 'px'
          }"
        >
          {{ total.hours | projectSummaryTotal: summaryService.settings.valueMode }}
        </td>
      </ng-container>
    }
  </tr>
}

<ng-container *ngIf="isExpanded">
  @for (teamMember of summaryPageDto.teamMembers; track teamMember.id) {
    <ng-container>
      @for (selectedKpiType of selectedKpiTypes; track selectedKpiType) {
        <tr class="project-summary__right team-member__row">
          <td [attr.colspan]="summaryService.slots.length">
            <table
              class="wp-nested-table"
              [ngStyle]="{ width: summaryService.getDataTableWidth() + 'px' }"
            >
              <tbody>
                <tr>
                  @for (slot of summaryService.slots; track slot.id) {
                    <td>
                      @for (
                        entry of getSummaryEntriesByTeamMemberId(
                          teamMember.id,
                          selectedKpiType,
                          slot
                        );
                        track entry.teamMemberId
                      ) {
                        <ng-container>
                          {{ entry.value | projectSummaryTotal: summaryService.settings.valueMode }}
                        </ng-container>
                      }
                    </td>
                  }
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      }
    </ng-container>
  } @empty {
    <tr class="project-summary__right no-data__row">
      <td [attr.colspan]="summaryService.slots.length"></td>
    </tr>
  }
</ng-container>
