import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  ElementRef,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CustomFieldConfiguration } from 'src/app/shared/models/entities/settings/custom-field-configuration.model';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  UntypedFormControl,
} from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTimesheetCustomField]',
  templateUrl: './timesheet-custom-field.component.html',
  styleUrls: ['./timesheet-custom-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetCustomFieldComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetCustomFieldComponent
  implements ControlValueAccessor, OnInit
{
  @ViewChild('editor') editor: ElementRef;
  @Input() field: CustomFieldConfiguration;

  public editMode: boolean;
  public readonly: boolean;
  public formControl = new UntypedFormControl('');

  private get input() {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  constructor(private cdr: ChangeDetectorRef) {
    this.formControl.valueChanges.subscribe(() => {
      const value = this.formControl.value.trim();
      this.propagateChange(value);
      this.cdr.markForCheck();
    });
  }

  public ngOnInit(): void {
    this.readonly = this.field.isOnlyForApi;
  }

  public propagateChange = (_: any) => null;

  writeValue(value: any): void {
    this.formControl.setValue(value ? value : '', { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched = (fn: any) => null;

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  public startEdit() {
    if (this.readonly) {
      return;
    }
    this.editMode = true;
    setTimeout(() => {
      this.input.focus();
      this.input.select();
    });
  }

  public stopEdit() {
    this.editMode = false;
  }
}
