@for (selectedKpiType of selectedKpiTypes; track selectedKpiType; let isFirst = $first) {
  <tr class="project-summary__left project__row">
    <ng-container *ngIf="isFirst">
      <td [attr.rowspan]="selectedKpiTypes.length" class="toggler-cell">
        <div class="d-flex justify-content-center">
          <span
            (click)="onToggleClick()"
            [ngClass]="{
              'bi-chevron-down': isExpanded,
              'bi-chevron-right': !isExpanded
            }"
            class="bi"
          ></span>
        </div>
      </td>
      <td [attr.rowspan]="selectedKpiTypes.length" class="project__row-cell p-0">
        <div [title]="summaryPageDto.name" class="d-flex align-items-center h-100">
          <div
            #projectContainer
            (click)="openProjectInfo(projectContainer, summaryPageDto.id)"
            class="wp-action text w-100"
          >
            {{ summaryPageDto.name }}
          </div>
        </div>
      </td>
    </ng-container>

    <td class="project__row-kpi-cell">
      {{ 'projects.projectSummary.filter.properties.viewSettings.' + selectedKpiType | translate }}
    </td>
    <td class="project__row-total-cell">
      {{
        getProjectTotal(selectedKpiType) | projectSummaryTotal: summaryService.settings.valueMode
      }}
    </td>
  </tr>
}

<ng-container *ngIf="isExpanded">
  @for (teamMember of summaryPageDto.teamMembers; track teamMember.id) {
    <ng-container>
      @for (selectedKpiType of selectedKpiTypes; track selectedKpiType; let isFirst = $first) {
        <tr class="project-summary__left team-member__row">
          <td
            *ngIf="isFirst"
            [attr.rowspan]="selectedKpiTypes.length"
            [title]="teamMember.name"
            class="team-member__row-cell"
            colspan="2"
          >
            <div class="d-flex align-items-center">
              <img
                [src]="
                  teamMember.resourceType === resourceType.user
                    ? (teamMember.resourceId | avatar: 60)
                    : '/assets/images/avatar/role.svg'
                "
                class="team-member__row-cell--avatar"
              />
              <div
                #userContainer
                (click)="openUserInfo(userContainer, teamMember)"
                class="w-100"
                [ngClass]="{
                  text: true,
                  'wp-action': teamMember.resourceType === resourceType.user
                }"
              >
                {{ teamMember.name }}
              </div>
            </div>
          </td>

          <td class="team-member__row-kpi-cell">
            {{
              'projects.projectSummary.filter.properties.viewSettings.' + selectedKpiType
                | translate
            }}
          </td>

          <td class="team-member__row-total-cell">
            {{
              getUserTotal(teamMember.id, selectedKpiType)
                | projectSummaryTotal: summaryService.settings.valueMode
            }}
          </td>
        </tr>
      }
    </ng-container>
  } @empty {
    <tr class="project-summary__left no-data__row position-relative w-100">
      <td class="text-center text-body-secondary" colspan="4">
        {{ 'shared.noDisplayData' | translate }}
      </td>
    </tr>
  }
</ng-container>
