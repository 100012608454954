import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TimeOffRequestService } from '../../shared/time-off-request.service';
import { MessageService } from 'src/app/core/message.service';
import { DayPart } from 'src/app/shared/models/enums/day-part.enum';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';

@Component({
  selector: 'wp-time-off-period-modal',
  templateUrl: './time-off-period-modal.component.html',
  styleUrls: ['./time-off-period-modal.component.scss'],
  providers: [TimeOffRequestService],
})
export class TimeOffPeriodModalComponent implements OnInit {
  @Input() request: Record<string, any>;

  public form = this.fb.group({
    id: null,
    user: null,
    startDate: [null, Validators.required],
    finishDate: [null, Validators.required],
    startDayIntervalValue: [null, Validators.required],
    finishDayIntervalValue: [null, Validators.required],
    startDayHours: 1,
    finishDayHours: 1,
  });

  public isSaving = false;

  constructor(
    private notification: NotificationService,
    private message: MessageService,
    private service: TimeOffRequestService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private data: DataService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private customFieldService: CustomFieldService,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    if (
      this.form.value.startDate === this.form.value.finishDate &&
      this.form.value.startDayIntervalValue.id === DayPart.Hours &&
      this.form.value.startDayHours === 0
    ) {
      this.notification.warningLocal(
        'timeOff.period.periodHasToBeGreaterThanZero',
      );
      return;
    }

    this.isSaving = true;
    const data = {
      id: this.request.id,
      name: this.request.name,
      rowVersion: this.request.rowVersion,
      userId: this.request.userId,
      description: this.request.description,
      timeOffTypeId: this.request.timeOffTypeId,
      startDate: this.form.value.startDate,
      finishDate: this.form.value.finishDate,
      startDayInterval: this.form.value.startDayIntervalValue.id,
      finishDayInterval: this.form.value.finishDayIntervalValue.id,
      finishDayHours:
        this.form.value.finishDayIntervalValue.id === DayPart.Hours
          ? this.form.value.finishDayHours
          : null,
      startDayHours:
        this.form.value.startDayIntervalValue.id === DayPart.Hours
          ? this.form.value.startDayHours
          : null,
    };

    this.customFieldService.assignValues(
      data,
      this.request,
      CustomFieldEntityType.TimeOffRequest,
    );

    this.data
      .collection('TimeOffRequests')
      .entity(this.request.id)
      .update(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          if (error.code === Exception.BtConcurrencyException.code) {
            const msg = this.translateService.instant(
              Exception.BtConcurrencyException.message,
            );
            const btnText = this.translateService.instant(
              'shared.actions.reload',
            );
            this.messageService.message(msg, '', [], btnText).then(
              () => this.activeModal.dismiss('error'),
              () => this.activeModal.dismiss('error'),
            );
          } else {
            this.message.error(error.message);
          }
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  ngOnInit(): void {
    this.form.patchValue(this.request);

    if (this.form.value.startDayHours === null) {
      this.form.controls['startDayHours'].setValue(1);
    }

    if (this.form.value.finishDayHours === null) {
      this.form.controls['finishDayHours'].setValue(1);
    }

    this.form.controls['startDayIntervalValue'].setValue(
      this.service
        .getDayParts()
        .find((d) => d.id === this.request.startDayInterval),
    );
    this.form.controls['finishDayIntervalValue'].setValue(
      this.service
        .getDayParts()
        .find((d) => d.id === this.request.finishDayInterval),
    );
  }
}
