<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ getHeader() | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>

  <div class="modal-body">
    <form class="form" [formGroup]="form">
      <ul ngbNav #nav="ngbNav" class="nav-tabs mb-15">
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'settings.lifecycles.card.props.transition.tabs.main' | translate }}</a>
          <ng-template ngbNavContent>
            <div class="mt-15">
              <div class="row">
                <div class="col-9">
                  <div class="form-group">
                    <label>{{
                      'settings.lifecycles.card.props.transition.name.label' | translate
                    }}</label>
                    <wp-text-box
                      formControlName="name"
                      placeholder="{{
                        'settings.lifecycles.card.props.transition.name.ph' | translate
                      }}"
                    ></wp-text-box>
                  </div>
                  <div class="form-group">
                    <label>
                      {{ 'settings.workflows.card.function.nameLocalization' | translate }}
                    </label>
                    <wp-naming-localization [labelStrings]="labelStrings"></wp-naming-localization>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>{{
                  'settings.lifecycles.card.props.transition.icon.label' | translate
                }}</label>
                <div class="d-flex">
                  <div class="col-9 me-2">
                    <wp-text-box
                      formControlName="iconClass"
                      placeholder="{{
                        'settings.lifecycles.card.props.transition.icon.label' | translate
                      }}"
                    ></wp-text-box>
                  </div>
                  <span class="align-self-center"
                    ><i class="bi" [ngClass]="form.value.iconClass"></i
                  ></span>
                </div>
                <div class="form-text text-body-secondary">
                  {{ 'settings.lifecycles.card.props.transition.icon.hint' | translate }}
                  <a [href]="biLink.link" target="_blank"> {{ biLink.title }} </a>
                  {{ 'settings.lifecycles.card.props.transition.icon.example' | translate }}
                </div>
              </div>

              <wp-performers
                class="p-0"
                [performerLines]="form.controls.performers"
                [readonly]="false"
                [lifecycleId]="lifecycleId"
                [performerTypeName]="performerTypeNames.lifecycle"
              ></wp-performers>

              <div class="row">
                <div class="col-9">
                  <div class="form-group">
                    <label>{{
                      'settings.lifecycles.card.props.transition.nextState' | translate
                    }}</label>
                    <wp-select-box
                      [values]="service.lifecycleStates"
                      formControlName="nextState"
                      [placeholder]="
                        'settings.lifecycles.card.props.transition.nextState' | translate
                      "
                    ></wp-select-box>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]>
          <a ngbNavLink>{{ 'settings.lifecycles.card.props.transition.tabs.form' | translate }}</a>
          <ng-template ngbNavContent>
            <div
              class="form-group form-check"
              title="{{ 'shared.props.isActive.hint' | translate }}"
            >
              <input
                formControlName="hasTransitionForm"
                type="checkbox"
                class="form-check-input"
                id="hasTransitionForm"
              />
              <label for="hasTransitionForm">{{
                'settings.lifecycles.card.props.transition.form.props.hasTransitionForm' | translate
              }}</label>
            </div>
            <ng-container *ngIf="form.value.hasTransitionForm">
              <wp-transition-form
                [transitionFormFormGroup]="form.controls.transitionForm"
                [lifecycleId]="lifecycleId"
              ></wp-transition-form>
            </ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      style="width: 75px"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.ok' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
