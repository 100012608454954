import { View } from './view';
import { GridColumn } from './grid-column.interface';
import { CustomFieldEntityType } from '../enums/custom-field-entity-type.enum';

/** Описание списка. */
export interface List {
  /** Версия. Используется для автоматического сброса локальных настроек при изменении описания.  */
  version: number;

  /** Уникальное имя. */
  name: string;

  /** Список представлений. */
  views: View[];

  /** Список колонок */
  columns: GridColumn[];

  /** Список колонок данных. Используется для автоматического формирования запроса им загрузки данных. */
  dataColumns?: DataColumn[];

  /** Имя коллекции для запроса данных. */
  dataCollection?: string;

  /** Тип расширяемой сущности, для обогащения списка дополнительными полями. */
  customFieldEntityType?: CustomFieldEntityType;

  /** Determines whether custom field columns are editable or not. */
  editableCustomFields?: boolean;

  /** Префикс для загрузки доп. полей. Используется если расширяется не корневая сущность, например при загрузке ProjectTotals. */
  customFieldPrefixForDataField?: string;

  /** Признак возможности сохранения настройки представления в профиль. */
  customizable?: boolean;
}

/** Описание колонки данных. */
export interface DataColumn {
  /** Имя колонки, для которой загружаются данные. */
  column: string;

  /** Стратегия загрузки данных. */
  loadingStrategy: LoadingStrategy;

  /** Поле в сущности API.
   * Для загрузки полей навигации использовать составное имя вида "project.manager.name".
   * Массив допустимо использовать для загрузки свойств одной сущности, например ["project.manager.name", "project.manager.id"].
   * В случае массива первый элемент будет использоваться для сортировки, в примере выше сортировка будет по имени проекта.
   */
  field: string | string[];
}

/** Стратегия загрузки. */

export enum LoadingStrategy {
  /** Всегда загружать поле. */
  Always,

  /** Загружать поле только если оно выбрано для отображения. */
  WhenShowing,
}
