<tmt-form-header [name]="form.value.name"></tmt-form-header>
<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
<form class="form" [hidden]="isLoading" novalidate [formGroup]="form">
  <div class="container-fluid">
    <wp-grid
      [formArray]="form.controls.states"
      [options]="gridOptions"
      [readonly]="readonly"
      style="display: block"
    >
    </wp-grid>
  </div>
</form>

<ui-view [hidden]="isLoading"></ui-view>
