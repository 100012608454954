import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { CustomFieldEntityType } from '../models/enums/custom-field-entity-type.enum';

export const PROGRAM_LIST: List = {
  version: 1,
  name: 'programs',
  dataCollection: 'ProgramTotals',
  customFieldEntityType: CustomFieldEntityType.Program,
  customFieldPrefixForDataField: 'program.',
  views: [
    {
      name: 'my',
      columns: [
        {
          column: 'programName',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'programCode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'programDescription',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'programIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      contextFilter: [
        {
          program: {
            or: [
              {
                managerId: {
                  type: 'guid',
                  value: '#user',
                },
              },
              {
                coManagers: {
                  any: {
                    userId: {
                      type: 'guid',
                      value: '#user',
                    },
                  },
                },
              },
            ],
          },
        },
      ],
      order: { column: 'name', reverse: false },
    },
    {
      name: 'active',
      columns: [
        {
          column: 'programName',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'programCode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'programManager',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'programDescription',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'programIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      contextFilter: [
        {
          program: {
            isActive: true,
          },
        },
      ],
      order: { column: 'Name', reverse: false },
    },
    {
      name: 'all',
      columns: [
        {
          column: 'programName',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'programCode',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'programManager',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'programDescription',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectsCount',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'programIsActive',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'programModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'Name', reverse: false },
    },
  ],

  columns: [
    <GridNavigationColumn>{
      name: 'programName',
      state: 'program',
      type: GridColumnType.Navigation,
      header: 'projects.programs.list.columns.name.header',
      hint: 'projects.programs.list.columns.name.hint',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'programDescription',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.description.header',
      hint: 'projects.projects.list.columns.description.hint',
    },
    {
      name: 'programManager',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.manager.header',
      hint: 'projects.projects.list.columns.manager.header',
    },
    {
      name: 'programCode',
      type: GridColumnType.String,
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
    },
    {
      name: 'projectsCount',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Integer,
      header: 'projects.programs.list.columns.projectsCount.header',
      hint: 'projects.programs.list.columns.projectsCount.hint',
    },
    {
      name: 'programIsActive',
      type: GridColumnType.Boolean,
      header: 'projects.programs.list.columns.isActive.header',
      hint: 'projects.programs.list.columns.isActive.hint',
    },
    {
      name: 'programCreated',
      type: GridColumnType.Date,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'programCreatedBy',
      type: GridColumnType.String,
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
    },
    {
      name: 'programModified',
      type: GridColumnType.Date,
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
    },
    {
      name: 'programModifiedBy',
      type: GridColumnType.String,
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
    },

    {
      name: 'plannedExpenses',
      header: 'projects.projects.list.columns.plannedExpensesBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedExpensesOverPeriod',
      header:
        'projects.projects.list.columns.plannedExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualExpenses',
      header: 'projects.projects.list.columns.actualExpensesBC.header',
      hint: 'projects.projects.list.columns.actualExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualExpensesOverPeriod',
      header:
        'projects.projects.list.columns.actualExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    {
      name: 'plannedRevenue',
      header: 'projects.projects.list.columns.plannedRevenueBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedRevenueOverPeriod',
      header:
        'projects.projects.list.columns.plannedRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualRevenue',
      header: 'projects.projects.list.columns.actualRevenueBC.header',
      hint: 'projects.projects.list.columns.actualRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualRevenueOverPeriod',
      header: 'projects.projects.list.columns.actualRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    {
      name: 'actualCost',
      header: 'projects.projects.list.columns.actualCostBC.header',
      hint: 'projects.projects.list.columns.actualCostBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualCostOverPeriod',
      header: 'projects.projects.list.columns.actualCostOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    {
      name: 'plannedProfit',
      header: 'projects.projects.list.columns.plannedProfitBC.header',
      hint: 'projects.projects.list.columns.plannedProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'plannedProfitOverPeriod',
      header: 'projects.projects.list.columns.plannedProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualProfit',
      header: 'projects.projects.list.columns.actualProfitBC.header',
      hint: 'projects.projects.list.columns.actualProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    {
      name: 'actualProfitOverPeriod',
      header: 'projects.projects.list.columns.actualProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    {
      name: 'plannedProfitability',
      header: 'projects.projects.list.columns.plannedProfitabilityBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'plannedProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'actualProfitability',
      header: 'projects.projects.list.columns.actualProfitabilityBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'actualProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },

    {
      name: 'estimatedHours',
      header: 'projects.projects.list.columns.estimatedHours.header',
      hint: 'projects.projects.list.columns.estimatedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },

    {
      name: 'actualHours',
      header: 'projects.projects.list.columns.actualHours.header',
      hint: 'projects.projects.list.columns.actualHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHoursOverPeriod',
      header: 'projects.projects.list.columns.actualHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.actualHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },

    {
      name: 'plannedHours',
      header: 'projects.projects.list.columns.plannedHours.header',
      hint: 'projects.projects.list.columns.plannedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHoursOverPeriod',
      header: 'projects.projects.list.columns.plannedHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
  ],

  dataColumns: [
    {
      column: 'programName',
      field: 'program.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programDescription',
      field: 'program.description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programManager',
      field: 'program.manager.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programCode',
      field: 'program.code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectsCount',
      field: 'projectsCount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programIsActive',
      field: 'program.isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'programCreated',
      field: 'program.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programModified',
      field: 'program.modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programCreatedBy',
      field: 'program.createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'programModifiedBy',
      field: 'program.modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'plannedExpenses',
      field: 'plannedExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriod',
      field: 'plannedExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpenses',
      field: 'actualExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriod',
      field: 'actualExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenue',
      field: 'plannedRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriod',
      field: 'plannedRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenue',
      field: 'actualRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'actualRevenueOverPeriod',
      field: 'actualRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'actualCost',
      field: 'actualCost',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriod',
      field: 'actualCostOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'plannedProfit',
      field: 'plannedProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriod',
      field: 'plannedProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfit',
      field: 'actualProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriod',
      field: 'actualProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitability',
      field: 'plannedProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriod',
      field: 'plannedProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitability',
      field: 'actualProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriod',
      field: 'actualProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'estimatedHours',
      field: 'estimatedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHours',
      field: 'actualHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHoursOverPeriod',
      field: 'actualHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHours',
      field: 'plannedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHoursOverPeriod',
      field: 'plannedHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
