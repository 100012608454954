<div class="form-group">
  <div
    (dropEvent)="addAttachments($event)"
    class="comments-input-container form-control"
    [class.focused]="isFocused"
    wpDndZone
  >
    <tmt-rich-editor-box (focused)="onFocused($event)" [formControl]="commentTextControl" />

    <div class="d-flex gap-3 flex-wrap attachments-container">
      <ng-container *ngIf="comment; then attachments; else files"></ng-container>
    </div>

    <div class="toolbar">
      <div class="comments-attach-files">
        <i
          title="{{ 'shared.comments.actions.addAttachment' | translate }}"
          (click)="attachFiles()"
          class="bi bi-paperclip"
        ></i>
      </div>

      <div class="d-flex gap-2">
        <button *ngIf="comment" (click)="undo()" class="btn">
          {{ 'shared.actions.cancel' | translate }}
        </button>
        <button
          *ngIf="isSaveButtonVisible || comment"
          (click)="save()"
          [isBusy]="commentsService.loading$ | async"
          class="comments-save-button btn btn-primary"
          wpButtonSpinner
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    </div>

    <div class="dnd-banner">
      <span>{{ 'shared.comments.dropzone.text' | translate }}</span>
      <i class="bi bi-upload"></i>
    </div>
  </div>
</div>

<ng-template #files>
  <tmt-comments-attachment
    *ngFor="let attachment of commentsService.commentAttachments$ | async; trackBy: trackFileById"
    [fileExt]="attachment.name.split('.').pop()"
    [fileName]="attachment.name"
    [file]="attachment"
    (onClick$)="
      attachmentsManagerService.openViewer(commentsService.commentAttachments, attachment.id)
    "
  >
  </tmt-comments-attachment>
</ng-template>

<ng-template #attachments>
  <tmt-comments-attachment
    *ngFor="let attachment of comment.attachments"
    [attachment]="attachment"
    [fileExt]="attachment.fileExt"
    [fileName]="attachment.fileName"
    (onClick$)="attachmentsManagerService.openViewer(comment.attachments, attachment.id)"
  >
  </tmt-comments-attachment>
</ng-template>
