import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { WORKFLOW_TASK_LIST } from 'src/app/shared/lists/workflow-task.list';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { WorkflowTaskCardComponent } from 'src/app/workflow-tasks/card/workflow-task-card.component';
import { WorkflowTaskListComponent } from 'src/app/workflow-tasks/list/workflow-task-list.component';
import { WorkflowTaskEntityCellComponent } from 'src/app/workflow-tasks/list/task-entity-cell/workflow-task-entity-cell.component';
import { WorkflowTaskFilterComponent } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    WorkflowTaskCardComponent,
    WorkflowTaskListComponent,
    WorkflowTaskEntityCellComponent,
    WorkflowTaskFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommentsModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'workflowTasks',
          url: '/tasks/{view:viewName}?{navigation}',
          params: {
            navigation: 'my.workflowTasksAll',
          },
          component: WorkflowTaskListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: WORKFLOW_TASK_LIST },
          ],
        },

        {
          name: 'task',
          params: {
            navigation: 'team.tasks',
          },
          url: '/tasks/{entityId:guid}?{navigation}',
          component: WorkflowTaskCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class WorkflowTasksModule {}
