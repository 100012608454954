<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>{{ 'shared2.filters.created.value' | translate }}</label>
        <wp-date-period-box
          formControlName="created"
          placeholder="{{ 'shared2.filters.created.ph' | translate }}"
        />
      </div>
      <div class="form-group">
        <label>{{ 'shared2.filters.type.value' | translate }}</label>
        <wp-select-box
          [allowInactive]="filterService.allowInactive$ | async"
          collection="IssueTypes"
          placeholder="{{ 'shared2.filters.type.ph' | translate }}"
          formControlName="type"
        />
      </div>
      <div class="form-group">
        <label>{{ 'shared2.filters.assigned.value' | translate }}</label>
        <wp-user-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.assigned.ph' | translate }}"
          formControlName="assigned"
        />
      </div>
    </div>
    <div class="col-5">
      <tmt-state-select formControlName="states" collection="Issues" />
    </div>
  </div>
</form>
