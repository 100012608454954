import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UIRouterGlobals } from '@uirouter/core';
import {
  StateBuilderService,
  WorkflowEntityState,
} from 'src/app/core/state-builder.service';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'tmt-workflow-task-entity-cell',
  templateUrl: './workflow-task-entity-cell.component.html',
  styleUrls: ['./workflow-task-entity-cell.component.scss'],
})
export class WorkflowTaskEntityCellComponent
  implements GridTemplateCell, OnInit
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public state: WorkflowEntityState;

  constructor(
    private stateBuilderService: StateBuilderService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {}

  public ngOnInit(): void {
    this.state = this.stateBuilderService.getStateForWorkflowEntity(
      this.formGroup?.getRawValue().id,
      this.formGroup?.getRawValue().entity.type,
      this.formGroup?.getRawValue().entity.id,
    );

    this.state.params['routeMode'] = RouteMode.continue;
    this.state.params['navigation'] = this.uiRouterGlobals.params?.navigation;
  }
}
