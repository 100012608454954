import { Pipe, PipeTransform } from '@angular/core';
import { CustomFieldConfiguration } from '../models/entities/settings/custom-field-configuration.model';
import { CustomFieldType } from '../models/enums/custom-field-type.enum';
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'wpCustomFieldValue',
})
export class CustomFieldValuePipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private numberPipe: DecimalPipe,
  ) {}

  transform(value: any, field: CustomFieldConfiguration): string {
    if (!value) {
      return null;
    }

    switch (field.type) {
      case CustomFieldType.date:
        return this.datePipe.transform(value, 'shortDate');
      case CustomFieldType.decimal:
        return this.numberPipe.transform(value, '1.0-2');
      case CustomFieldType.integer:
        return this.numberPipe.transform(value, '1.0');
      default:
        return value;
    }
  }
}
