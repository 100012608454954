<form [formGroup]="service.form" class="accounting-entry-container">
  <div class="d-flex">
    <div class="form-group">
      <label>
        {{ 'finance.entries.card.properties.type' | translate }}
      </label>
      <div class="clearfix"></div>

      <div class="btn-group btn-group-toggle" radioGroup name="radioBasic" formControlName="typeId">
        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="financialAccountType.revenue.id" />
          <i class="bi bi-arrow-right bi-15 pe-2"></i>
          {{ financialAccountType.revenue.name | translate }}
        </label>

        <label radioLabel class="btn-default trim fw-normal">
          <input radioButton type="radio" [value]="financialAccountType.expenses.id" />
          <i class="bi bi-arrow-left bi-15 pe-2"></i>
          {{ financialAccountType.expenses.name | translate }}
        </label>
      </div>
    </div>
  </div>

  <!-- Financial Account -->
  <div class="form-group">
    <label>
      {{ 'finance.entries.card.properties.account' | translate }}
    </label>
    <wp-select-box
      [allowNull]="false"
      [values]="service.accounts$"
      placeholder="{{ 'finance.entries.card.properties.account' | translate }}"
      formControlName="account"
    ></wp-select-box>
  </div>

  <!-- Date & Amount -->
  <div class="d-flex">
    <div class="form-group">
      <label>
        {{ 'finance.entries.card.properties.date' | translate }}
      </label>
      <wp-date-box
        [allowNull]="false"
        [excludePeriod]="service.closedPeriod"
        formControlName="date"
      ></wp-date-box>
    </div>

    <div class="form-group ms-4">
      <label class="me-2">
        {{ 'finance.entries.card.properties.amount' | translate }}
      </label>
      <i
        class="group-hint bi bi-question-circle text-gray ms-auto"
        [ngbTooltip]="'finance.entries.card.hints.amount' | translate"
      ></i>
      <wp-currency-box
        formControlName="amount"
        [allowNull]="false"
        [isCurrencyEditable]="false"
        [min]="service.minAllowedNumber"
      ></wp-currency-box>
    </div>

    <div class="form-group ms-4">
      <label>
        {{ 'finance.entries.card.properties.amountBC' | translate }}
      </label>
      <wp-number-box
        formControlName="amountBC"
        type="currency"
        [allowNull]="false"
        [readonly]="true"
        [min]="service.minAllowedNumber"
      ></wp-number-box>
    </div>
  </div>

  <!-- Creation Date & Mode -->
  <div *ngIf="service.mode !== 'create'" class="d-flex">
    <div class="form-group">
      <label>
        {{ 'shared.props.created' | translate }}
      </label>
      <wp-date-box [allowNull]="false" [readonly]="true" formControlName="created"></wp-date-box>
    </div>

    <div class="form-group ms-4">
      <label>
        {{ 'finance.entries.card.properties.mode' | translate }}
      </label>
      <wp-select-box formControlName="mode" [readonly]="true"></wp-select-box>
    </div>
  </div>

  <!-- Project -->
  <div class="form-group" *ngIf="!(service.isTimeOffLaborCost$ | async)">
    <label>
      {{ 'finance.entries.card.properties.project' | translate }}
    </label>
    <wp-select-box
      [allowNull]="false"
      collection="Projects"
      [query]="service.projectsQuery"
      placeholder="{{ 'finance.entries.card.properties.project' | translate }}"
      formControlName="project"
    ></wp-select-box>
  </div>

  <!-- Stage (Task) -->
  <div class="form-group" *ngIf="!(service.isTimeOffLaborCost$ | async)">
    <label>
      {{ 'finance.entries.card.properties.task' | translate }}
    </label>
    <tmt-task-box
      [allowNull]="false"
      [projectId]="service.form.value.project?.id"
      placeholder="{{ 'finance.entries.card.properties.task' | translate }}"
      formControlName="projectTask"
    />
  </div>

  <!-- TimeOff type -->
  <div class="form-group" *ngIf="service.isTimeOffLaborCost$ | async">
    <label>
      {{ 'finance.entries.card.properties.timeOffType' | translate }}
    </label>
    <wp-select-box
      collection="timeOffTypes"
      placeholder="{{ 'finance.entries.card.properties.timeOffType' | translate }}"
      formControlName="timeOffType"
    ></wp-select-box>
  </div>

  <!-- User, Hours & Cost manual -->
  <ng-container *ngIf="(service.entryMode$ | async).code === accountingEntryMode.manual.code">
    @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
      <div class="d-flex gap-4">
        <div class="form-group user-form-group">
          <label>
            {{ 'finance.entries.card.properties.user' | translate }}
          </label>
          <wp-select-box
            collection="Users"
            placeholder="{{ 'finance.entries.card.properties.user' | translate }}"
            formControlName="user"
            [readonly]="!(this.service.isUserEditable$ | async)"
          ></wp-select-box>
        </div>

        <div
          class="form-group number-form-group"
          *ngIf="(service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)"
        >
          <label>
            {{ 'finance.entries.card.properties.hours' | translate }}
          </label>
          <wp-number-box
            [allowNull]="false"
            [readonly]="!(this.service.isUserEditable$ | async)"
            [min]="service.minAllowedNumber"
            formControlName="hours"
            type="work"
          ></wp-number-box>
        </div>
      </div>
    }
  </ng-container>

  <!-- User, Hours & Cost automatic -->
  <ng-container *ngIf="(service.entryMode$ | async).code === accountingEntryMode.automatic.code">
    @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
      <div class="d-flex gap-4">
        <div class="form-group select-form-group">
          <label>
            {{ 'finance.entries.card.properties.user' | translate }}
          </label>
          <wp-select-box
            collection="Users"
            placeholder="{{ 'finance.entries.card.properties.user' | translate }}"
            formControlName="user"
            [readonly]="!(this.service.isUserEditable$ | async)"
          ></wp-select-box>
        </div>

        <div
          class="form-group number-form-group"
          *ngIf="(service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)"
        >
          <label>
            {{ 'finance.entries.card.properties.hours' | translate }}
          </label>
          <wp-number-box
            [allowNull]="false"
            [readonly]="true"
            [min]="service.minAllowedNumber"
            formControlName="hours"
            type="work"
          ></wp-number-box>
        </div>

        <div
          class="form-group number-form-group"
          *ngIf="(service.isLaborCost$ | async) || (service.isTimeOffLaborCost$ | async)"
        >
          <label>
            {{ 'finance.entries.card.properties.costRate' | translate }}
          </label>
          <wp-number-box
            [allowNull]="false"
            [readonly]="true"
            formControlName="costRate"
            type="currency"
          ></wp-number-box>
        </div>
      </div>
    }
  </ng-container>

  <!-- Legal entity & Project cost center -->
  @if ((service.financialAccountType$ | async) === financialAccountType.expenses.id) {
    <div class="d-flex gap-1">
      <div class="form-group select-form-group">
        <label>
          {{ 'finance.entries.card.properties.legalEntity' | translate }}
        </label>
        <wp-select-box
          collection="LegalEntities"
          placeholder="{{ 'finance.entries.card.properties.legalEntity' | translate }}"
          formControlName="legalEntity"
          [readonly]="true"
        ></wp-select-box>
      </div>
      @if (service.isShownProjectCostCenter$ | async) {
        <div class="form-group select-form-group">
          <label>
            {{ 'finance.entries.card.properties.projectCostCenter' | translate }}
          </label>
          <wp-select-box
            [values]="service.projectCostCenters$ | async"
            placeholder="{{ 'finance.entries.card.properties.projectCostCenter' | translate }}"
            formControlName="projectCostCenter"
            [readonly]="(service.entryMode$ | async).code === accountingEntryMode.automatic.code"
          ></wp-select-box>
        </div>
      }
    </div>
  }

  <!-- Description -->
  <div class="form-group">
    <label>
      {{ 'shared.props.description' | translate }}
    </label>
    <wp-multiline-text-box
      placeholder="{{ 'shared.props.description' | translate }}"
      formControlName="description"
      rows="3"
    ></wp-multiline-text-box>
  </div>

  <!-- Document & Document Date -->
  <div *ngIf="service.mode !== 'create'" class="d-flex gap-4">
    <div class="form-group select-form-group">
      <label>
        {{ 'finance.entries.card.properties.document' | translate }}
      </label>
      <a
        *ngIf="service.form.value.document?.id"
        class="d-inline-block trim w-100 mt-2 mb-0"
        [uiSref]="service.documentLink"
        [uiParams]="{
          entityId: service.form.value.document?.id,
          navigation: navigationService.selectedNavigationItem?.name,
          routeMode: routeMode.continue,
          route: navigationService.storedRoute
        }"
        title="{{ service.form.value.document?.name }}"
        >{{ service.form.value.document?.name }}</a
      >
      <p *ngIf="!service.form.value.document?.id" class="disabled mt-2 mb-0">
        {{ 'shared.noDisplayData' | translate }}
      </p>
    </div>

    <div class="form-group document-date-form-group">
      <label>
        {{ 'finance.entries.card.properties.documentDate' | translate }}
      </label>
      <wp-date-box
        [allowNull]="false"
        [readonly]="true"
        formControlName="documentDate"
      ></wp-date-box>
    </div>
  </div>
</form>
