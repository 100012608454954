import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridNavigationColumn,
  GridColumnType,
} from '../models/inner/grid-column.interface';
export const RESOURCE_REQUEST_LIST: List = {
  name: 'resource-requests',
  dataCollection: 'ResourceRequests',
  version: 4,
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'project',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'resourcePool',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'from',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'to',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'requestedHours',
          width: 140,
          visibleByDefault: true,
        },
        {
          column: 'resourceDescription',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },

    {
      name: 'open',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'project',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'resourcePool',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'from',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'to',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'requestedHours',
          width: 140,
          visibleByDefault: true,
        },
        {
          column: 'resourceDescription',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          state: { code: 'Open' },
        },
      ],
    },

    {
      name: 'myOpen',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'project',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'resourcePool',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'from',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'to',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'requestedHours',
          width: 140,
          visibleByDefault: true,
        },
        {
          column: 'resourceDescription',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          state: { code: 'Open' },
          createdById: { type: 'guid', value: '#user' },
        },
      ],
    },

    {
      name: 'withTask',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'project',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'resourcePool',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'from',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'to',
          visibleByDefault: true,
          width: 140,
        },
        {
          column: 'requestedHours',
          width: 140,
          visibleByDefault: true,
        },
        {
          column: 'resourceDescription',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          workflowInstances: {
            any: {
              tasks: {
                any: {
                  assignedId: {
                    type: 'guid',
                    value: '#user',
                  },
                  isActive: true,
                },
              },
            },
          },
        },
      ],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      state: 'resourceRequest',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    },
    {
      name: 'project',
      header: 'resources.requests.list.columns.project',
      hint: 'resources.requests.list.columns.project',
      type: GridColumnType.String,
    },
    {
      name: 'role',
      header: 'resources.requests.list.columns.role',
      hint: 'resources.requests.list.columns.role',
      type: GridColumnType.String,
    },
    {
      name: 'resourcePool',
      header: 'resources.requests.list.columns.resourcePool',
      hint: 'resources.requests.list.columns.resourcePool',
      type: GridColumnType.String,
    },
    {
      name: 'resourceDescription',
      header: 'resources.requests.list.columns.resource',
      hint: 'resources.requests.list.columns.resource',
      type: GridColumnType.String,
    },
    {
      name: 'from',
      header: 'resources.requests.list.columns.from.header',
      hint: 'resources.requests.list.columns.from.hint',
      fixedWidth: true,
      type: GridColumnType.Date,
    },
    {
      name: 'to',
      header: 'resources.requests.list.columns.to.header',
      hint: 'resources.requests.list.columns.to.hint',
      fixedWidth: true,
      type: GridColumnType.Date,
    },
    {
      name: 'requestedHours',
      header: 'resources.requests.list.columns.requestedHours',
      hint: 'resources.requests.list.columns.requestedHours',
      fixedWidth: true,
      type: GridColumnType.Work,
    },
    {
      name: 'state',
      header: 'resources.requests.list.columns.state.header',
      hint: 'resources.requests.list.columns.state.hint',
      type: GridColumnType.State,
      fixedWidth: true,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'role',
      field: 'role.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resourcePool',
      field: 'resourcePool.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resourceDescription',
      field: 'resourceDescription',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'from',
      field: 'from',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'to',
      field: 'to',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'requestedHours',
      field: 'requestedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
  ],
};
