import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Lifecycle } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { State } from 'src/app/shared/models/entities/state.model';
import { AppService } from 'src/app/core/app.service';
import { StateModalComponent } from './state-modal/state-modal.component';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';

@Injectable()
export class LifecycleCardService {
  constructor(
    private translate: TranslateService,
    private data: DataService,
    private modal: NgbModal,
    public app: AppService,
  ) {}

  /* Все состояния текущего ЖЦ, необходимые при редактировании какого-либо одного состояния */
  public lifecycleStates: NamedEntity[] = [];
  public entityName2Collection = {
    ResourcePool: 'ResourcePools',
    ActOfAcceptance: 'ActsOfAcceptance',
    ResourceRequest: 'ResourceRequests',
    Invoice: 'Invoices',
    RateMatrix: 'RateMatrices',
    Issue: 'Issues',
  };

  private reloadSubject = new Subject<void>();
  public reload$ = this.reloadSubject.asObservable();

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private lifecycleName: string;

  /** Запустить проверку изменений */
  public detectChanges() {
    this.changesSubject.next();
  }

  /** Method for reloading */
  reload() {
    this.reloadSubject.next();
  }

  /** Получить жизненный цикл. */
  getLifecycle(lifecycleId: string) {
    this.lifecycleStates = [];
    return this.data
      .collection('Lifecycles')
      .entity(lifecycleId)
      .get<Lifecycle>({
        expand: ['states'],
      })
      .pipe(
        tap((lifecycle) => {
          const statesCopy = [];
          lifecycle.states.map((state) => {
            statesCopy.push({ id: state.id, name: state.name });
          });
          this.lifecycleStates = statesCopy;
          this.lifecycleName = lifecycle.entityType;
        }),
      );
  }

  /** Получить состояние жизненного цикла. */
  getState(stateId: string) {
    return this.data
      .collection('States')
      .entity(stateId)
      .get<State>()
      .pipe(
        /* Получаем ЖЦ, находим текущий state и готовим
        запросы на получение схем для его transitions */
        switchMap((state) =>
          forkJoin({
            state: of(state),
            transitions: this.getTransitions(stateId),
          }),
        ),
        /* Преобразуем transitions в свойство state'а */
        switchMap((res: any) => {
          const fullState = { ...res.state, transitions: res.transitions };
          return of(fullState);
        }),
      );
  }

  /** Получить переходы состояния. */
  getTransitions(stateId: string) {
    return this.data
      .collection('States')
      .entity(stateId)
      .function(`GetTransitions`)
      .get()
      .pipe(
        /* Готовим запросы, чтобы получить имена performers каждого transition */
        switchMap((res: any) => {
          const transitions = JSON.parse(res) as Array<any>;
          const userNameRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.userId) {
                userNameRequests[performer?.userId] = this.getUserName(
                  performer.userId,
                );
              }
            });
          });

          const groupNameRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.groupId) {
                groupNameRequests[performer?.groupId] = this.getGroupName(
                  performer.groupId,
                );
              }
            });
          });

          const permissionSetRequests = {};
          transitions?.map((transition) => {
            transition.performers?.map((performer) => {
              if (performer.permissionSetId) {
                permissionSetRequests[performer?.permissionSetId] =
                  this.getPermissionSetName(performer.permissionSetId);
              }
            });
          });
          return forkJoin({
            transitions: of(transitions),
            ...groupNameRequests,
            ...userNameRequests,
            ...permissionSetRequests,
          });
        }),
        /* Записываем полученные имена users в соответствующий performer */
        switchMap((res) => {
          res.transitions?.map((transition) => {
            /* Добавляем поля, необходимые для работы с исполнителями на клиенте */
            /** Для исполнителей */
            transition.performers?.map((performer) => {
              if (
                performer.userId &&
                performer.userId === res[performer.userId]?.id
              ) {
                performer.id = performer.userId;
                performer.name = res[performer.userId].name;
                performer.type = 'user';
              } else if (
                performer.permissionSetId &&
                performer.permissionSetId === res[performer.permissionSetId]?.id
              ) {
                performer.id = performer.permissionSetId;
                performer.name = res[performer.permissionSetId].name;
                performer.type = 'permissionSet';
              } else if (performer.role) {
                performer.id = performer.role;
                performer.name = this.getRoleName(performer.role);
                performer.type = 'role';
              } else if (
                performer.groupId &&
                performer.groupId === res[performer.groupId]?.id
              ) {
                performer.id = performer.groupId;
                performer.name = res[performer.groupId].name;
                performer.type = 'group';
              }
            });
            /**Для запрашиваемых параметров */
            transition.transitionForm?.requestedProperties?.map((property) => {
              const nameControl: NamedEntity = { id: null, name: null };
              nameControl.id = property.name;
              nameControl.name = this.getTransitionPropertyDisplayName(
                this.entityName2Collection[this.lifecycleName],
                property.name,
              );
              property.nameControl = nameControl;
            });
          });

          return of(res.transitions);
        }),
      );
  }

  /** Создать состояние. */
  createState(state) {
    return this.data.collection('States').insert(state);
  }

  /** Обновить состояние. */
  updateState(state) {
    return this.data.collection('States').entity(state.id).update(state);
  }

  /** Удалить состояние. */
  deleteState(stateId) {
    return this.data.collection('States').entity(stateId).delete();
  }

  /** Получить имя пользователя. */
  getUserName(userId: string) {
    return this.data
      .collection('Users')
      .entity(userId)
      .get({ select: ['id', 'name'] });
  }

  /** Get user group name. */
  getGroupName(groupId: string) {
    return this.data
      .collection('Groups')
      .entity(groupId)
      .get({ select: ['id', 'name'] });
  }

  /** Получить имя набора прав. */
  getPermissionSetName(permissionSetId: string) {
    return this.data
      .collection('permissionSets')
      .entity(permissionSetId)
      .get({ select: ['id', 'name'] });
  }

  /** Создать/отредактировать состояние */
  editState(lifecycleId: string, stateId?: string) {
    const modalRef = this.modal.open(StateModalComponent, {
      size: 'lg',
    });
    const instance = modalRef.componentInstance as StateModalComponent;

    instance.lifecycleId = lifecycleId;
    instance.stateId = stateId ? stateId : null;

    modalRef.result.then(
      () => {
        this.reloadSubject.next();
      },
      () => null,
    );
  }

  /** Возвращает доступные языки. */
  getLanguages() {
    return this.data.model.function('GetLanguages').query<NamedEntity[]>();
  }

  /** Получить имя роли. */
  public getRoleName(role: string): string {
    return this.translate.instant(`enums.lifecycleRole.${_.camelCase(role)}`);
  }

  /** Получить имя свойства перехода. */
  public getTransitionPropertyDisplayName(
    lifecycleName: string,
    property: string,
  ): string {
    return this.translate.instant(
      `enums.transitionFormProperties.${lifecycleName}.${property}`,
    );
  }

  /** Обновить индекс состояния. */
  updateStateIndex(stateId: string, index: number) {
    return this.data.collection('States').entity(stateId).patch({ index });
  }
}
