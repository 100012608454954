import {
  DestroyRef,
  Inject,
  inject,
  Injectable,
  Injector,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, Observable } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';
import { ProjectCostCenterModalComponent } from 'src/app/projects/card/project-cost-centers/project-cost-center-modal/project-cost-center-modal.component';
import { ProjectCostCentersToolbarComponent } from 'src/app/projects/card/project-cost-centers/project-cost-centers-toolbar/project-cost-centers-toolbar.component';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CurrencyValue } from 'src/app/shared/models/entities/settings/currency-value.model';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class ProjectCostCentersService {
  public formArray = this.fb.array([]);

  public get readonly(): boolean {
    return !this.projectCardService.project.costCentersEditAllowed;
  }

  public gridOptions: Grid2Options = {
    css: 'wp-nested-table',
    sorting: false,
    resizableColumns: true,
    selectionType: SelectionType.range,
    toolbar: ProjectCostCentersToolbarComponent,
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.createCostCenter();
        },
      },
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup: UntypedFormGroup) => !!formGroup,
        handlerFn: () => {
          this.editCostCenter(this.gridService.selectedGroupValue);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) => !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteCostCenter(formGroup.value.id),
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => true,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.editCostCenter(formGroup.getRawValue());
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteCostCenter(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  /** Prevents cost centers autosaving. */
  private isStopSaving = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public projectId: string,
    private projectCardService: ProjectCardService,
    private listService: ListService,
    private savingQueueService: SavingQueueService,
    private gridService: GridService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private notificationService: NotificationService,
    private modal: NgbModal,
    private injector: Injector,
    private customFieldService: CustomFieldService,
  ) {
    this.savingQueueService.delayDuration = 0;
  }

  /** Loads project cost centers. */
  public load(): void {
    this.savingQueueService.save().then(
      () => {
        this.gridService.setLoadingState(true);
        this.formArray.clear();

        const query: any = {
          select: [
            'id',
            'name',
            'isActive',
            'actualCost',
            'actualExpenses',
            'created',
            'modified',
          ],
          expand: [
            {
              legalEntity: {
                select: ['id', 'name'],
              },
              createdBy: {
                select: ['id', 'name'],
              },
              modifiedBy: {
                select: ['id', 'name'],
              },
            },
          ],
        };
        this.customFieldService.enrichQuery(
          query,
          CustomFieldEntityType.ProjectCostCenter,
        );

        this.dataService
          .collection('Projects')
          .entity(this.projectId)
          .collection('ProjectCostCenters')
          .query(query)
          .subscribe({
            next: (costCenters: ProjectCostCenter[]) => {
              costCenters.forEach((costCenter) => {
                (costCenter.actualCost as unknown as CurrencyValue) = {
                  value: costCenter.actualCost,
                  currencyCode:
                    this.projectCardService.project.currency.alpha3Code,
                };
                (costCenter.actualExpenses as unknown as CurrencyValue) = {
                  value: costCenter.actualExpenses,
                  currencyCode:
                    this.projectCardService.project.currency.alpha3Code,
                };
                const group = this.getCostCenterFormGroup();
                group.patchValue(costCenter, { emitEvent: false });
                this.formArray.push(group);
              });

              this.gridService.setLoadingState(false);
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
              this.gridService.setLoadingState(false);
            },
          });
      },
      () => null,
    );
  }

  /** Opens modal window for creating cost center. */
  public createCostCenter(): void {
    const modalRef = this.modal.open(ProjectCostCenterModalComponent, {
      injector: this.injector,
    });

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Opens modal window for editing cost center.
   *
   * @param costCenter editing cost center.
   */
  public editCostCenter(costCenter: ProjectCostCenter): void {
    const modalRef = this.modal.open(ProjectCostCenterModalComponent, {
      injector: this.injector,
    });

    const instance =
      modalRef.componentInstance as ProjectCostCenterModalComponent;
    instance.editingCostCenter = costCenter;

    modalRef.result.then(
      (costCenter) => {
        const costCenterFormGroup = this.formArray.controls.find(
          (control) => control.value.id === costCenter.id,
        );
        this.isStopSaving = true;
        costCenterFormGroup.patchValue(costCenter);
        this.isStopSaving = false;
        this.gridService.detectChanges();
      },
      () => null,
    );
  }

  /**
   * Deletes cost center.
   *
   * @param costCenterId id of deleting cost center.
   */
  public deleteCostCenter(costCenterId: string) {
    this.dataService
      .collection('ProjectCostCenters')
      .entity(costCenterId)
      .delete()
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === costCenterId,
          );
          this.formArray.controls.splice(index, 1);
          const groupForSelection =
            this.formArray.controls[index] ??
            this.formArray.controls[index - 1] ??
            null;

          this.gridService.selectGroup(groupForSelection as UntypedFormGroup);

          this.gridService.detectChanges();
          this.notificationService.successLocal(
            'projects.projects.card.costCenters.messages.deleted',
          );
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }

  /**
   * Returns DTO for cost center saving.
   *
   * @param costCenter cost center.
   * @returns  cost center DTO.
   */
  public getCostCenterDTO(
    costCenter: ProjectCostCenter,
  ): Partial<ProjectCostCenter> {
    return {
      id: costCenter.id,
      name: costCenter.name,
      projectId: this.projectId,
      legalEntityId: costCenter.legalEntity.id,
      isActive: costCenter.isActive,
    };
  }

  /**
   * Generates an update request for a project cost center.
   *
   * @param costCenter The project cost center to be updated.
   * @returns An observable of the updated project cost center.
   */
  public getCostCenterEditRequest(
    costCenter: ProjectCostCenter,
    isExistCostCenter: boolean,
  ): Observable<ProjectCostCenter> {
    const costCenterDTO = this.getCostCenterDTO(costCenter);
    this.customFieldService.assignValues(
      costCenterDTO,
      costCenter,
      CustomFieldEntityType.ProjectCostCenter,
      true,
    );

    return isExistCostCenter
      ? this.dataService
          .collection('ProjectCostCenters')
          .entity(costCenter.id)
          .update(costCenterDTO)
      : this.dataService.collection('ProjectCostCenters').insert(costCenterDTO);
  }

  /**
   * Returns cost center form group.
   *
   * @returns cost center form group.
   */
  private getCostCenterFormGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      name: [
        null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      legalEntity: null,
      isActive: null,
      actualCost: null,
      actualExpenses: null,
      createdBy: null,
      modifiedBy: null,
      created: null,
      modified: null,
    });

    ['legalEntity', 'modifiedBy', 'createdBy'].forEach((key) => {
      group.controls[key].disable();
    });

    this.customFieldService.enrichFormGroup(
      group,
      CustomFieldEntityType.ProjectCostCenter,
      false,
      false,
    );
    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared.messages.entityNotSaved',
          );
          return;
        }

        this.savingQueueService.addToQueue(
          group.value.id,
          this.getCostCenterEditRequest(group.getRawValue(), true),
        );
      });

    return group;
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }
}
