import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { RawParams } from '@uirouter/core';
import { DataService } from 'src/app/core/data.service';
import { TransitionFormProperty } from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { NotificationService } from 'src/app/core/notification.service';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import {
  MassOperationHelper,
  MassOperationResult,
} from 'src/app/shared/helpers/mass-operation.helper';
import { Exception } from 'src/app/shared/models/exception';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ErrorGroup } from 'src/app/shared/components/mass-operation/model/mass-operation-error.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ChangeStateParams } from 'src/app/shared/components/mass-operation/model/change-state-params.model';
import { MassOperationType } from 'src/app/shared/components/mass-operation/model/mass-change-type.model';

@Component({
  selector: 'tmt-mass-operation',
  templateUrl: './mass-operation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedModule, UIRouterModule],
})
export class MassOperationComponent implements OnInit {
  @Input() massOperationType: MassOperationType;
  /** Entity items for visualize mass operation errors. */
  @Input() items: NamedEntity[];
  @Input() entityIds: string[];
  /** Collection for manage mass operations. */
  @Input() collection: string;
  /** Entity name for wrapper collection. */
  @Input() entityPropertyName?: string;
  /** The name of the state to link to errored entity. */
  @Input() state: string;
  /** Properties for mass change states.  */
  @Input() changeStateParams?: ChangeStateParams;
  public transitionProperties: TransitionFormProperty[];

  public form: UntypedFormGroup = this.fb.group({});
  public operationInProgress = false;
  public progress = 0;
  public actionsCount: number;
  public successActionsCount: number;
  public failActionsCount: number;
  public massOperationCompleted = false;
  public errorGroups: ErrorGroup[] = [];
  public stateParams: RawParams = {
    navigation: this.navigationService.selectedNavigationItem?.name,
    routeMode: RouteMode.continue,
  };

  private massOperationHelper: MassOperationHelper;

  private destroyRef = inject(DestroyRef);

  constructor(
    public lifecycle: LifecycleCardService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.actionsCount = this.entityIds.length;
    this.successActionsCount = 0;

    if (this.massOperationType === 'changeState') {
      this.initMassChangeStates();
    }
  }

  /** Executes mass operation. */
  public apply(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    switch (this.massOperationType) {
      case 'changeState': {
        this.initChangeStateMassOperationHelper();
        break;
      }
      case 'delete':
        this.initDeleteMassOperationHelper();
        break;
      default:
        return;
    }

    this.operationInProgress = true;
    this.massOperationHelper
      .start()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        this.successActionsCount = this.massOperationHelper.completedCount;
        this.progress = Math.round(v);

        if (v === 100) {
          this.operationInProgress = false;
          this.massOperationCompleted = true;
          this.errorsHandler(this.massOperationHelper.errors);
        }
        this.cdr.detectChanges();
      });
  }

  /** Initializes the MassOperationHelper for change state operations. */
  private initChangeStateMassOperationHelper(): void {
    const data = {
      stateId: this.changeStateParams?.nextStateId,
      transitionFormValue: {
        propertyValues: [],
        comment: this.form.value.comment,
      },
    };

    Object.keys(this.form.value).forEach((key) => {
      if (key !== 'comment') {
        data.transitionFormValue.propertyValues.push({
          name: key,
          value: this.form.value[key],
        });
      }
    });

    this.massOperationHelper = new MassOperationHelper(
      this.entityIds.map((id) =>
        this.data
          .collection(this.collection)
          .entity(id)
          .action('SetState')
          .execute(data),
      ),
      {
        takeUntil: takeUntilDestroyed(this.destroyRef),
      },
    );
  }

  /** Initializes the MassOperationHelper for delete operations. */
  private initDeleteMassOperationHelper(): void {
    this.massOperationHelper = new MassOperationHelper(
      this.entityIds.map((entityId) =>
        this.data.collection(this.collection).entity(entityId).delete(),
      ),
      {
        takeUntil: takeUntilDestroyed(this.destroyRef),
      },
    );
  }

  /** Closes modal with "Ok" result. */
  public ok(): void {
    this.activeModal.close('ok');
  }

  /** Closes modal with "Cancel" result. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Transforms error details to string;
   *
   * @param error Errors.
   * @returns Errors in single message.
   */
  public detailsMessage(error: Exception): string {
    return error.details?.map((d) => d.message)?.join('\n');
  }

  private errorsHandler(errors: MassOperationResult<Exception>[]): void {
    this.failActionsCount = errors.length;

    errors.forEach((error) => {
      const errorTitle = error.result.message ?? error.result.code;
      const item = {
        error: error.result,
        item: this.items[error.originalIndex],
      };

      const groupIndex = this.errorGroups.findIndex(
        (group) => group.title === errorTitle,
      );

      if (groupIndex === -1) {
        this.errorGroups.push({
          title: errorTitle,
          items: [item],
        });
      } else {
        this.errorGroups[groupIndex].items.push(item);
      }
    });
  }

  /**
   * Initializes the form controls for mass state changes.
   * This method sets up the form controls based on the transition properties
   * and comment requirements specified in the changeStateParams.
   */
  private initMassChangeStates(): void {
    this.transitionProperties =
      this.changeStateParams?.transitionForm?.transitionFormPropertyValues;
    if (this.changeStateParams?.transitionForm?.requestComment) {
      this.form.addControl(
        'comment',
        this.fb.control(
          null,
          this.changeStateParams?.transitionForm?.commentIsRequired
            ? Validators.required
            : null,
        ),
      );
    }

    this.transitionProperties?.forEach((property) => {
      this.form.addControl(
        property.name,
        this.fb.control(
          undefined,
          property.isRequired ? Validators.required : null,
        ),
      );
    });
  }
}
