import {
  GridNavigationColumn,
  GridColumnType,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const ISSUE_LIST: List = {
  version: 1,
  name: 'issues',
  dataCollection: 'Issues',
  customFieldEntityType: CustomFieldEntityType.Issue,
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'description',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'type',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'assigned',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      availableTotals: [TotalType.Count],
      state: 'issue',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
    },
    {
      name: 'description',
      type: GridColumnType.String,
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
    },
    {
      name: 'type',
      header: 'shared2.props.type',
      hint: 'shared2.type',
      type: GridColumnType.Entity,
    },
    {
      name: 'assigned',
      header: 'shared2.props.assigned',
      hint: 'shared2.props.assigned',
      type: GridColumnType.User,
    },
    {
      name: 'state',
      header: 'shared2.props.state',
      hint: 'shared2.props.state',
      type: GridColumnType.State,
    },
    {
      name: 'created',
      header: 'shared2.props.created.value',
      hint: 'shared2.props.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared2.props.modified.value',
      hint: 'shared2.props.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.short',
      hint: 'shared2.props.isActive.value',
      type: GridColumnType.Boolean,
    },
    {
      name: 'createdBy',
      header: 'shared2.props.createdBy.value',
      hint: 'shared2.props.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared2.props.modifiedBy.value',
      hint: 'shared2.props.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'type',
      field: ['type.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'assigned',
      field: ['assigned.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
