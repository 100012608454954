import { Inject, Injectable, Optional } from '@angular/core';
import { GridView } from 'src/app/shared/models/inner/grid-view.interface';
import {
  UserView,
  UserViewColumn,
} from 'src/app/shared/models/inner/user-view';
import {
  DataColumn,
  List,
  LoadingStrategy,
} from 'src/app/shared/models/inner/list';
import * as objectPath from 'object-path';

import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { FilterService } from '../components/features/filter/filter.service';
import { View, ViewColumn } from '../models/inner/view';
import { AppService } from 'src/app/core/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewSettingsModalComponent } from '../components/features/view-settings-modal/view-settings-modal.component';
import {
  GridColumn,
  GridColumnType,
} from '../models/inner/grid-column.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomFieldConfiguration } from '../models/entities/settings/custom-field-configuration.model';
import { CustomFieldType } from '../models/enums/custom-field-type.enum';
import { clone } from 'lodash';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

/**
 * Вспомогательный сервис для работы с типизированными списками и представлениями.
 * Работает в контексте установленных зависимостей LIST и VIEW_NAME.
 */
@Injectable()
export class ListService {
  /** Список используемых колонок-данных. */
  private usingDataColumns: DataColumn[] = [];

  private get currentView(): View {
    return this.list.views.find((v: View) => v.name === this.viewName);
  }

  constructor(
    @Inject(LIST) protected list: List,
    @Inject(VIEW_NAME) protected viewName: string,
    protected app: AppService,
    @Optional() private filterService: FilterService,
    private modalService: NgbModal,
  ) {
    this.list = list;

    // remove inaccessible columns.
    this.list.columns = this.list.columns.filter(
      (listColumn) =>
        !listColumn.requiredFeature ||
        this.app.checkFeature(listColumn.requiredFeature),
    );

    this.currentView.columns = this.currentView.columns.filter(
      (viewColumn: ViewColumn) =>
        this.list.columns.some((c) => c.name === viewColumn.column),
    );

    this.extendListWithCustomFields(
      this.currentView.resizableColumns,
      this.list.editableCustomFields,
    );

    this.checkView();
  }

  private checkView() {
    this.usingDataColumns = [];

    let updatingUserViewIsRequired = false;

    const userView = this.getUserView();

    // Заполнить отображаемые колонки.
    userView.columns.forEach(
      (userViewColumn: UserViewColumn, index: number) => {
        const listColumn: GridColumn = this.list.columns.find(
          (column: GridColumn) => column.name === userViewColumn.column,
        );

        // Если колонка есть в выбранных, но нет в списке - изменилась конфигурация и надо обновить представление.
        if (!listColumn) {
          userView.columns.splice(index, 1);
          updatingUserViewIsRequired = true;
          return;
        }
      },
    );

    // Заполнить колонки данных.
    if (this.list.dataColumns) {
      this.list.dataColumns.forEach((dataColumn: DataColumn) => {
        const listColumn: GridColumn = this.list.columns.find(
          (column: GridColumn) => column.name === dataColumn.column,
        );

        let userViewColumn: UserViewColumn = null;

        if (listColumn) {
          userViewColumn = userView.columns.find(
            (column: UserViewColumn) => column.column === listColumn.name,
          );
        }

        if (
          dataColumn.loadingStrategy === LoadingStrategy.Always ||
          userViewColumn
        ) {
          this.usingDataColumns.push(dataColumn);
        }
      });
    }

    if (updatingUserViewIsRequired) {
      this.saveUserView(userView);
    }
  }

  /** Возвращает FormGroup для строки (any).
   * Не устанавливает правила валидации. Рекомендуется для readonly гридов как вспомогательный метод.
   * Имена контролов устанавливает в порядке приоритета:
   * - поле field из DataColumn (если загружаем данные по представлению, то свойства в row будут по имени поля (column)),
   * - первый элемент, если field - это массив (чаще всего это имя сущности).
   */
  getFormGroupForRow(row: any): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(row.id),
    });

    for (const dataColumn of this.usingDataColumns) {
      const field = dataColumn.field;
      let path = field;
      if (Array.isArray(field)) {
        let length = field[0].lastIndexOf('.');
        length = length === -1 ? field.length : length;
        path = field[0].substring(0, length);
      }

      const value = objectPath.get(row, path);
      formGroup.addControl(dataColumn.column, new UntypedFormControl(value));
    }

    return formGroup;
  }

  /**
   * Возвращает параметры запроса к OData API по описанию списка и пользовательскому представлению.
   *
   * @param currentPage - текущая страница.
   * @param contextFilter - параметры фильтра.
   * @param pageSize - размер страницы.
   */
  public getODataQuery = (
    currentPage = 0,
    pageSize: number,
    contextFilter?: any,
  ): any => {
    const userView = this.getUserView();

    const result: any = {
      expand: {},
      select: ['id'],
      filter: this.filterService?.getODataFilter() ?? null,
      top: pageSize,
      skip: pageSize * currentPage,
    };

    if (contextFilter) {
      if (result.filter) {
        result.filter.push(contextFilter);
      } else {
        result.filter = [contextFilter];
      }
    }

    // Формирование запроса.
    this.list.dataColumns.forEach((dataColumn: DataColumn) => {
      const listColumn: GridColumn = this.list.columns.find(
        (column: GridColumn) => column.name === dataColumn.column,
      );

      let userViewColumn: UserViewColumn = null;

      if (listColumn) {
        userViewColumn = userView.columns.find(
          (column: UserViewColumn) => column.column === listColumn.name,
        );

        // Добавить сортировку.
        if (
          userView.order &&
          userView.order.column &&
          userView.order.column === listColumn.name
        ) {
          const orderField = Array.isArray(dataColumn.field)
            ? dataColumn.field[0]
            : dataColumn.field;

          let orderClause = orderField.replace(/\./g, '/');
          if (userView.order.reverse) {
            orderClause += ' desc';
          }
          result.orderBy = [orderClause];
        }
      }

      if (
        dataColumn.loadingStrategy === LoadingStrategy.Always ||
        userViewColumn
      ) {
        const processDataField = (dataField: string) => {
          const fields = dataField.split('.');

          const iterateLevel = (level: number, obj: any) => {
            const field = fields[level];
            if (!obj.select) {
              obj.select = ['id'];
            }

            if (fields.length - 1 === level) {
              obj.select = obj.select.concat([field]);
            } else {
              if (!obj.expand) {
                obj.expand = {};
              }
              if (!obj.expand[field]) {
                obj.expand[field] = {};
              }
              iterateLevel(level + 1, obj.expand[field]);
            }
          };

          iterateLevel(0, result);
        };

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        Array.isArray(dataColumn.field)
          ? dataColumn.field.forEach((dataField) => processDataField(dataField))
          : processDataField(dataColumn.field);
      }
    });

    // OData 8 does not allow empty/whitespace select and expand.
    if (Object.keys(result.select).length === 0) {
      delete result.select;
    }
    if (Object.keys(result.expand).length === 0) {
      delete result.expand;
    }

    return result;
  };

  /** Возвращает GridView из описания списка и пользовательского представления. */
  getGridView = (): GridView => {
    const userView = this.getUserView();

    const gridView: GridView = {
      name: userView.name,
      order: userView.order,
      columns: [],
    };

    const totalLogicalWidth = userView.columns.reduce(
      (accumulator: number, viewColumn: UserViewColumn) => {
        const listColumn: GridColumn = this.list.columns.find(
          (column: GridColumn) => column.name === viewColumn.column,
        );

        if (listColumn == null || listColumn.fixedWidth) {
          return accumulator;
        }
        return accumulator + viewColumn.width;
      },
      0,
    );

    userView.columns.forEach((viewColumn: UserViewColumn) => {
      const listColumn: GridColumn = this.list.columns.find(
        (column: GridColumn) => column.name === viewColumn.column,
      );

      // Колонку удалили, но осталась в представлении.
      // TODO как-то сразу удалять из сохраненного представления надо
      if (listColumn == null) {
        return;
      }

      const gridColumn = clone(listColumn);

      gridColumn.total = viewColumn.total;
      gridColumn.width = '';

      if (this.currentView.resizableColumns) {
        gridColumn.width = viewColumn.width + 'px';
      } else {
        if (!listColumn.fixedWidth) {
          gridColumn.width = (viewColumn.width / totalLogicalWidth) * 100 + '%';
        } else {
          gridColumn.width = viewColumn.width + 'px';
        }
      }

      gridView.columns.push(gridColumn);
    });

    return gridView;
  };

  /** Возвращает пользовательское представление по умолчанию. */
  getDefaultUserView = (): UserView => {
    const view = this.list.views.find((v: View) => v.name === this.viewName);
    const defaultUserView: UserView = {
      version: this.list.version,
      name: this.viewName,
      columns: [],
      order: view.order,
    };

    view.columns.forEach((viewColumn: ViewColumn) => {
      if (!viewColumn.visibleByDefault) {
        return;
      }

      defaultUserView.columns.push({
        column: viewColumn.column,
        total: viewColumn.totalByDefault,
        width: viewColumn.width,
      });
    });

    return defaultUserView;
  };

  /** Возвращает пользовательское представление.
   * Если сохраненного нет или версия отличается от метаданных, то возвращается представление по умолчанию.
   */
  getUserView = (): UserView => {
    const views = this.app.clientProfile.userViews;
    if (
      this.list.customizable !== false &&
      views[this.list.name] &&
      views[this.list.name][this.viewName] &&
      views[this.list.name][this.viewName].version >= this.list.version
    ) {
      return views[this.list.name][this.viewName];
    }
    return this.getDefaultUserView();
  };

  /** Сохраняет представление в клиентский профиль пользователя. */
  saveUserView(userView: UserView, lazy = false) {
    if (!this.app.clientProfile.userViews[this.list.name]) {
      this.app.clientProfile.userViews[this.list.name] = {};
    }

    this.app.clientProfile.userViews[this.list.name][userView.name] = userView;
    if (lazy) {
      this.app.saveProfileLazy();
    } else {
      this.app.saveProfile();
    }
  }

  /** Вызывает диалог настройки представления. */
  public setUserView(): Promise<void> {
    const modal = this.modalService.open(ViewSettingsModalComponent, {
      size: this.currentView.resizableColumns ? 'md' : 'lg',
    });

    const instance = modal.componentInstance as ViewSettingsModalComponent;

    instance.modern = this.currentView.resizableColumns;
    instance.userView = this.getUserView();
    instance.defaultUserView = this.getDefaultUserView();
    instance.list = this.list;
    instance.viewName = this.viewName;

    return modal.result.then(
      (userView: UserView) => {
        this.saveUserView(userView);
        this.checkView();
      },
      () => {
        throw new Error('rejected');
      },
    );
  }

  /** Set vie column width and save settings. */
  public setColumnWidth(columnName: string, width: number) {
    const userView = this.getUserView();
    userView.columns.find((c) => c.column === columnName).width = width;
    this.saveUserView(userView, true);
  }

  /**
   * Extends current list and its views with custom field columns.
   *
   * @param resizableColumns determines whether grid has resizable columns.
   * @param editableColumns determines whether custom field columns are editable.
   */
  public extendListWithCustomFields(
    resizableColumns?: boolean,
    editableColumns?: boolean,
  ): void {
    if (!this.list.customFieldEntityType) {
      return;
    }

    const fields = this.app.session.configuration.customFields.filter(
      (field: CustomFieldConfiguration) =>
        this.list.customFieldEntityType === field.entityType &&
        field.isShownInEntityLists,
    );

    this.addColumns(fields, editableColumns);

    this.list.views.forEach((view: View) => {
      fields.forEach((field: CustomFieldConfiguration) => {
        if (view.columns.find((c) => c.column === field.dataField)) {
          return;
        }

        view.columns.push({
          column: field.dataField,
          width: resizableColumns ? 120 : 1,
          visibleByDefault: false,
        });
      });
    });
  }

  /**
   * Extends current list with custom field columns.
   *
   * @param fields custom fields configurations.
   * @param editableColumns determines whether custom field columns are editable.
   */
  private addColumns(
    fields: CustomFieldConfiguration[],
    editableColumns?: boolean,
  ): void {
    fields.forEach((field: CustomFieldConfiguration) => {
      if (this.list.columns.find((c) => c.name === field.dataField)) {
        return;
      }

      const additionalParams = {} as any;
      let columnType: GridColumnType;
      let dataField = field.dataField;

      if (editableColumns) {
        additionalParams.forceCellUpdating = true;
        switch (field.type) {
          case CustomFieldType.string:
            columnType = GridColumnType.StringControl;
            additionalParams.propagationMode =
              PropagationMode.onExitFromEditing;
            break;
          case CustomFieldType.lookup:
            columnType = GridColumnType.SelectControl;
            additionalParams.collection = 'LookupValues';
            additionalParams.query = {
              filter: [
                { customFieldId: { eq: { type: 'guid', value: field.id } } },
              ],
            };
            break;
          case CustomFieldType.date:
            columnType = GridColumnType.DateControl;
            break;
          case CustomFieldType.decimal:
            columnType = GridColumnType.NumberControl;
            additionalParams.controlType = 'decimal';
            additionalParams.contentType = GridColumnType.Decimal;
            additionalParams.propagationMode =
              PropagationMode.onExitFromEditing;

            break;
          case CustomFieldType.integer:
            columnType = GridColumnType.NumberControl;
            additionalParams.controlType = 'integer';
            additionalParams.contentType = GridColumnType.Integer;
            additionalParams.propagationMode =
              PropagationMode.onExitFromEditing;
            break;
        }
      } else {
        switch (field.type) {
          case CustomFieldType.string:
            columnType = GridColumnType.String;
            break;
          case CustomFieldType.lookup:
            columnType = GridColumnType.String;
            break;
          case CustomFieldType.date:
            columnType = GridColumnType.Date;
            break;
          case CustomFieldType.decimal:
            columnType = GridColumnType.Decimal;
            break;
          case CustomFieldType.integer:
            columnType = GridColumnType.Integer;
            break;
        }
        dataField =
          field.type === CustomFieldType.lookup
            ? dataField + '.name'
            : dataField;
      }

      const local = field.localizationStrings.find(
        (row: any) => row.culture === this.app.session.language,
      ).label;

      if (this.list.customFieldPrefixForDataField) {
        dataField = this.list.customFieldPrefixForDataField + dataField;
      }

      this.list.columns.push(<GridColumn>{
        name: field.dataField,
        type: columnType,
        header: local,
        hint: local,
        ...additionalParams,
      });

      this.list.dataColumns.push(<DataColumn>{
        column: field.dataField,
        field: dataField,
        loadingStrategy: LoadingStrategy.WhenShowing,
      });
    });
  }
}
