import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ListService } from 'src/app/shared/services/list.service';
import { IssueFilterService } from 'src/app/issues/list/filter/issue-filter.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';

@Component({
  selector: 'tmt-issue-list',
  template: '<wp-entity-list /> ',
  providers: [
    { provide: FilterService, useClass: IssueFilterService },
    LifecycleListService,
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private gridService: GridService,
    private stateService: StateService,
    private actionPanelService: ActionPanelService,
    private appService: AppService,
    private entityListService: EntityListService,
    private modalService: NgbModal,
    private lifecycleListService: LifecycleListService,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.issueListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'Issue',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('issue', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: () => this.entityListService.delete(),
      }),
    ]);
    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: any) => {
        this.actionPanelService.action('card').isShown = rows?.length === 1;
      });
  }

  /* Opens issue creation modal. */
  private create(): void {
    this.modalService.open(IssueCreationComponent);
  }
}
