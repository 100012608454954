import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageService } from 'src/app/core/message.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { RoleName } from 'src/app/shared/models/enums/role-name.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { concat, Observable, of } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { takeLast } from 'rxjs/operators';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';

@Component({
  selector: 'wp-user-creation',
  templateUrl: './user-creation.component.html',
})
export class UserCreationComponent implements OnInit {
  isSaving = false;
  userForm: UntypedFormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    invitation: true,
    permissionSet: null,
    authProvider: [null, Validators.required],
  });

  public permissionSetsQuery = { filter: [{ roleName: RoleName.User }] };
  private _isInvitationInputVisible = false;

  public authProviders$ = this.data
    .collection('Users')
    .function('GetAuthProviders')
    .query<AuthProvider[]>();

  get isInvitationInputVisible() {
    return this._isInvitationInputVisible;
  }

  constructor(
    private message: MessageService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,

    private state: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(
      this.userForm,
      CustomFieldEntityType.User,
      true,
    );
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.userForm,
      CustomFieldEntityType.User,
    );
  }

  ngOnInit() {
    this.userForm.controls['permissionSet'].valueChanges.subscribe(() => {
      this.updateInvitationInputVisibility();
    });
    this.userForm.controls['authProvider'].valueChanges.subscribe(() => {
      this.updateInvitationInputVisibility();
    });

    this.data
      .collection('Users')
      .function('GetAuthProviders')
      .query<AuthProvider[]>(null, {
        filter: { type: AuthProviderType.Local },
      })
      .subscribe((providers) => {
        this.userForm.controls.authProvider.setValue(providers[0]);
      });
  }

  ok() {
    this.userForm.markAllAsTouched();

    if (this.userForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data: any = {
      name: this.userForm.value.name,
      email: this.userForm.value.email,
      authProviderId: this.userForm.value.authProvider?.id ?? null,
    };

    this.customFieldService.assignValues(
      data,
      this.userForm.value,
      CustomFieldEntityType.User,
    );

    this.data
      .collection('Users')
      .insert(data)
      .subscribe({
        next: (response) => {
          const authProvider = this.userForm.value.authProvider as AuthProvider;
          const observables: Observable<any>[] = [of(null)];

          if (this.userForm.value.permissionSet) {
            const permissionSetData = {
              permissionSets: [
                {
                  userId: response.id,
                  permissionSetId: this.userForm.value.permissionSet.id,
                  id: Guid.generate(),
                },
              ],
            };
            observables.push(
              this.data
                .collection('Users')
                .entity(response.id)
                .action('UpdatePermissionSets')
                .execute(permissionSetData),
            );
          }

          if (
            this.userForm.value.permissionSet &&
            this.userForm.value.invitation &&
            authProvider.type === AuthProviderType.Local
          ) {
            observables.push(
              this.data
                .collection('Users')
                .entity(response.id)
                .action('RestorePassword')
                .execute(),
            );
          }

          concat(...observables)
            .pipe(takeLast(1))
            .subscribe({
              next: () => {
                this.afterCreation(response.id);
              },
              error: (error: Exception) => this.afterError(error),
            });
        },
        error: (error: Exception) => this.afterError(error),
      });
  }

  afterError(error: Exception) {
    this.isSaving = false;
    this.message.errorDetailed(error);
  }

  afterCreation(userId: string) {
    this.notification.successLocal('settings.users.creation.messages.created');
    this.state.go('settings.user', { entityId: userId });
    this.isSaving = false;
    this.activeModal.close();
  }

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  private updateInvitationInputVisibility() {
    const authProvider = this.userForm.controls['authProvider']
      .value as AuthProvider;

    this._isInvitationInputVisible =
      this.userForm.controls['permissionSet'].value &&
      authProvider.type === AuthProviderType.Local;
  }
}
