import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Inject,
  Optional,
  DestroyRef,
  inject,
} from '@angular/core';
import { ListService } from 'src/app/shared/services/list.service';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NavigationItem } from 'src/app/shared/models/navigation/navigation';
import { EntityListService } from './entity-list.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { FILTER, MULTI_SELECT_LIST } from 'src/app/shared/tokens';
import { HistoryService } from 'src/app/core/history.service';
import { UIRouterGlobals } from '@uirouter/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, Grid2Module],
})
export class EntityListComponent implements OnInit, OnDestroy {
  /** Determines if the grid is nested. */
  @Input() nested = false;
  @Input() hasViewSettings = true;
  @Input() showCommands = true;

  public gridOptions: Grid2Options = {
    css: 'root',
    sorting: true,
    view: null,
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'delete',
        handlerFn: () => {
          if (this.actions.action('delete')?.isShown) {
            this.actions.action('delete').execute();
          }
        },
        keyCode: 'Delete',
      },
      {
        name: 'enter',
        handlerFn: () => {
          if (this.actions.action('card')?.isShown) {
            this.actions.action('card').execute();
          }
        },
        keyCode: ['Enter', 'NumpadEnter'],
      },
      {
        name: 'insert',
        handlerFn: () => {
          if (this.actions.action('create')?.isShown) {
            this.actions.action('create').execute();
          }
        },
        keyCode: 'Insert',
      },
    ],
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    @Optional() @Inject(FILTER) public entityFilter: EntityFilter,
    public gridService: GridService,
    public service: EntityListService,
    public panelService: ActionPanelService,
    @Optional() @Inject(MULTI_SELECT_LIST) private multiSelectList: boolean,
    private actions: ActionPanelService,
    private listService: ListService,
    private history: HistoryService,
    private navigation: NavigationService,
    private titleService: Title,
    private translate: TranslateService,
    private routerGlobals: UIRouterGlobals,
  ) {}

  public ngOnInit(): void {
    this.gridOptions.view = this.listService.getGridView();
    if (this.service.rowCommands) {
      this.gridOptions.rowCommands = this.service.rowCommands;
    }

    if (this.nested) {
      this.gridOptions.css = 'wp-nested-table';
    }

    if (!this.nested) {
      this.panelService.reload$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.service.reload());
    }

    if (this.multiSelectList) {
      this.gridOptions.multiSelectColumn = true;
      this.gridOptions.selectionType = SelectionType.rows;
    }

    this.service.reload();
    this.service.enableInfinityScroll();

    this.navigation.navigationItem$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((item: NavigationItem) => {
        const translatedTitle: string = this.translate.instant(item.header);
        this.titleService.setTitle(translatedTitle);

        let title = '';

        if (Array.isArray(this.routerGlobals.params['route'])) {
          this.routerGlobals.params['route'].reverse().forEach((el) => {
            if (el.title) {
              title += el.title + ' ';
            }
          });
        }

        this.history.addHistory(
          [title || translatedTitle],
          this.navigation.getAppName(),
        );
      });

    if (this.multiSelectList) {
      this.gridService.selectedGroups$
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((groups: UntypedFormGroup[]) => {
          if (this.actions.action('delete')) {
            this.actions.action('delete').isShown =
              groups.length &&
              this.gridService.selectedGroupsValue.some(
                (value) => !value.state?.isEntityProtected,
              );
          }
        });
    }
  }

  public ngOnDestroy(): void {
    this.service.dispose();
  }

  /**
   * Sets the user view for the list service and updates the grid view.
   * Reloads the service after setting the user view.
   */
  public setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.service.reload();
      },
      () => null,
    );
  }
}
