import {
  Component,
  OnInit,
  Input,
  Injector,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { AppService } from 'src/app/core/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutosaveStateService } from 'src/app/shared/services/autosave-state.service';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { StateService } from '@uirouter/core';

import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ResourceRequestService } from 'src/app/resource-requests/shared/resource-request/resource-request.service';
import { Subject } from 'rxjs';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { takeUntil } from 'rxjs/operators';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { BookingAssistantComponent } from 'src/app/booking/booking-assistant/booking-assistant.component';
import { ResourceRequestCalendarService } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  selector: 'wp-resource-request-card',
  templateUrl: './resource-request-card.component.html',
  styleUrls: ['./resource-request-card.component.scss'],
  providers: [
    SavingQueueService,
    FreezeTableService,
    ScheduleNavigationService,
    ResourceRequestService,
    ResourceRequestCalendarService,
    { provide: ENTITY_COLLECTION, useValue: 'ResourceRequests' },
    LifecycleService,
  ],
})
export class ResourceRequestCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public state: CardState;
  public tabs: TabLink[] = [];

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public navigation: NavigationService,
    public autosaveStateService: AutosaveStateService,
    public app: AppService,
    public stateService: StateService,
    public service: ResourceRequestService,
    private resourceRequestCalendarService: ResourceRequestCalendarService,
    private modalService: NgbModal,
    private actionService: ActionPanelService,
    private savingQueueService: SavingQueueService,
    private injector: Injector,
    private infoPopupService: InfoPopupService,
    private lifecycleService: LifecycleService,
    private el: ElementRef<HTMLElement>,
  ) {
    lifecycleService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reload();
    });
  }

  public ngOnInit(): void {
    this.initActions();
    this.service.load(this.entityId);

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.load(this.entityId);
        this.lifecycleService.reloadLifecycle();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public reload() {
    this.savingQueueService.save().then(() => {
      this.actionService.actions.forEach((a) => (a.isVisible = false));
      this.actionService.propagate();
      this.service.load(this.entityId);
      this.lifecycleService.reloadLifecycle();
      this.actionService.resetLifecycle();
      this.resourceRequestCalendarService.init();
      this.resourceRequestCalendarService.loadFrame(null);
    });
  }

  public book(): void {
    this.savingQueueService.save();

    const ref = this.modalService.open(BookingAssistantComponent, {
      fullscreen: true,
      modalDialogClass: 'modal-overflow',
      injector: this.injector,
    });

    (ref.componentInstance as BookingAssistantComponent).entityId =
      this.entityId;

    ref.result.then(
      () => {
        this.reload();
      },
      () => {
        this.reload();
      },
    );
  }

  /** Shows info about user. */
  public openOwnerInfo(): void {
    this.infoPopupService.open({
      target: this.el.nativeElement.querySelector('#owner'),
      data: {
        component: UserInfoComponent,
        params: {
          userId: this.service.request.createdBy.id,
        },
        injector: this.injector,
      },
    });
  }

  /** Shows info about project. */
  public openProjectInfo(): void {
    this.infoPopupService.open({
      target: this.el.nativeElement.querySelector('#project'),
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId: this.service.request.project.id,
        },
        injector: this.injector,
      },
    });
  }

  /** Initiates main actions. */
  private initActions() {
    this.actionService.setHasAutosave(true);

    this.actionService.set([
      {
        title: 'resources.requests.actions.assistant.label',
        hint: 'resources.requests.actions.assistant.hint',
        name: 'book',
        iconClass: 'bi bi-people',
        isBusy: false,
        isVisible: false,
        handler: () => this.book(),
      },
    ]);

    this.actionService.setAdditional([
      {
        title: 'shared.actions.delete',
        hint: 'resources.requests.hint.delete',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.delete(),
      },
    ]);

    this.service.request$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request: ResourceRequest) => {
        this.actionService.action('delete').isShown =
          request.editAllowed && !request.state.isEntityProtected;
        this.actionService.action('book').isShown = true;

        if (request.teamMember?.resource.resourceType !== ResourceType.user) {
          this.tabs = [
            {
              state: 'resourceRequest.main',
              header: 'resources.requests.card.tab.main',
            },
            {
              state: 'resourceRequest.advanced',
              header: 'resources.requests.card.tab.advanced',
            },
          ];
        } else {
          this.tabs.length = 0;
        }
      });
  }

  private delete() {
    this.service.delete();
  }
}
