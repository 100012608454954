<td [attr.colspan]="totals.length" [style.height]="height + 'px'">
  <table class="wp-nested-table" [style.width]="bookingRenderingService.getDataTableWidth() + 'px'">
    <tbody>
      <tr [attr.data-test-resource-id]="resourceId">
        @for (total of totals; track total) {
          <td [class.non-working]="total.nonWorking"></td>
        }
      </tr>
    </tbody>
  </table>

  <div
    (mousedown)="startCreate($event)"
    class="group-board"
    [attr.data-test-resource-id]="resourceId"
  >
    @for (bookingEntry of bookings; track bookingEntry) {
      <wp-booking-entry [booking]="bookingEntry" />
    }
  </div>
</td>
