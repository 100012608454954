import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { UIRouterModule } from '@uirouter/angular';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import {
  ENTITY_COLLECTION,
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { IssueListComponent } from 'src/app/issues/list/issue-list.component';
import { IssueCardComponent } from 'src/app/issues/card/issue-card.component';
import { IssueFilterComponent } from 'src/app/issues/list/filter/issue-filter.component';
import { ISSUE_LIST } from 'src/app/issues/models/issue.list';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';

@NgModule({
  declarations: [
    IssueListComponent,
    IssueCardComponent,
    IssueCreationComponent,
    IssueFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CommentsModule,
    BaseSharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'issues',
          url: '/issues/{view:viewName}?{navigation}',
          params: {
            navigation: 'my.issues',
          },
          component: IssueListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: ISSUE_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            { provide: ENTITY_COLLECTION, useValue: 'Issues' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'issue',
                queryData: {
                  select: ['id', 'stateId', 'name'],
                  expand: { state: { select: 'isEntityProtected' } },
                },
              } as MassOperationParameters,
            },
          ],
        },
        {
          name: 'issue',
          params: {
            navigation: 'my.issues',
          },
          url: '/issues/{entityId:guid}?{navigation}',
          component: IssueCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
})
export class IssuesModule {}
