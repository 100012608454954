/* eslint-disable @typescript-eslint/naming-convention */
import { Type } from '@angular/core';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { Observable } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

/** Grid column type. */
export enum GridColumnType {
  Navigation,
  ResourceNavigation,
  String,
  MultilineString,
  Entity,
  Boolean,
  Date,
  DateTime,
  Decimal,
  Integer,
  Percent,
  Work,
  Currency,
  Label,
  User,
  State,

  Command,

  StringControl,
  DateControl,
  NumberControl,
  SelectControl,

  UserControl,
  BooleanControl,
  CurrencyControl,
  Component,
}

/** Navigation column type. */
export enum NavigationType {
  'Resource',
  'User',
}

/** Grid column. */
export interface GridColumn {
  /** Column name for its identification. */
  name: string;

  /** Column type. Determines of using control for visualization. */
  type: GridColumnType;

  /** Column content type. Determines of header and total cell format. Uses column type if contentType is not defined.  */
  contentType?: GridColumnType;

  /** Enables force cell updating after change its formGroup abstract control. */
  forceCellUpdating?: boolean;

  /** Column header (localization code). */
  header: string;

  /** Column icon CSS class. */
  icon?: string;

  /** Column hint (localization code). */
  hint: string;

  /** List of available summary functions.
   * Used when setting up a custom view.
   */
  availableTotals?: TotalType[];

  /** Fixed width flag. Used to calculate the width field based on the view.
   * If false, then in view-columns and custom-view-columns the width is set as numbers (1, 2), which sets the column width in %%
   * (%% = number / sum of numbers across all columns).
   * If true, then the width will be set to px.
   */
  fixedWidth?: boolean;

  /** CSS column width (25%, 220px).
   * It makes no sense to set it in a declarative description, because will be recalculated from the view settings.
   * */
  width?: string;

  /** Using (selected) total function. */
  total?: TotalType;

  /** Required feature. */
  requiredFeature?: Feature | null;

  /** Default value for column cells. Uses with removing cell data. */
  getDefaultValue?: () => any;
}

export interface GridNavigationColumn extends GridColumn {
  state: string;
  stateParams?: StateParams;
  navigationType?: NavigationType;
}

/** List of custom parameters for state. */
export interface StateParams {
  fromRow: Record<string, string>;
}

/** Cells in the form of links executing the grid service command '.execute'.  */
export interface GridCommandColumn extends GridColumn {
  command: string;
}

export interface GridComponentColumn extends GridColumn {
  component: Type<GridComponentCell>;
  readonlyComponent?: Type<GridComponentCell>;
  allowNull?: boolean;
}

export interface GridDateControlColumn extends GridColumn {
  allowNull?: boolean;
}

export interface GridSelectControlColumn extends GridColumn {
  values?: any;
  placeholder?: string;
  collection?: string;
  query?: any;
  allowNull?: boolean;
}

export interface GridStringControlColumn extends GridColumn {
  placeholder?: string;
  propagationMode?: PropagationMode;
}

export interface GridUserControlColumn extends GridColumn {
  placeholder?: string;

  /** OData request for control. */
  query?: any;
}

export interface GridNumberControlColumn extends GridColumn {
  controlType: 'integer' | 'decimal' | 'work' | 'currency' | 'percent';
  min?: number;
  max?: number;
  allowNull?: boolean;
  precision?: number;
  propagationMode?: PropagationMode;
}

export interface GridCurrencyColumn extends GridColumn {
  currencyCodeProperty?: string;
  currencyCode?: string;
}

export interface GridCurrencyControlColumn extends GridColumn {
  min?: number;
  max?: number;
  allowNull?: boolean;
  currencyCode?: string;
  isCurrencyEditable?: boolean;
  currencyCodeProperty?: string;
}

export interface GridEntityColumn extends GridColumn {
  values?: Observable<NamedEntity[]> | NamedEntity[];
}
