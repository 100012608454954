import { Injectable, Type } from '@angular/core';
import { Dictionary } from 'lodash';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { IssueFilterComponent } from 'src/app/issues/list/filter/issue-filter.component';

@Injectable()
export class IssueFilterService extends FilterService {
  public override component: Type<FilterDetails> = IssueFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = true;
  public override placeholder =
    'components.issueListComponent.filters.textInput';

  protected override getDefaultValues = (): any => ({
    created: null,
    type: null,
    assigned: null,
    states: null,
  });

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    const text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      result.push(
        this.getTextFilter(['name', 'description'], this.getClearText(text)),
      );
    }

    if (this.values.created) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.created,
      );

      result.push({
        created: { ge: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        created: { le: { type: 'raw', value: dates.periodFinish } },
      });
    }

    if (this.values.type) {
      result.push({
        typeId: { type: 'guid', value: this.values.type.id },
      });
    }

    if (this.values.assigned) {
      result.push({
        assignedId: { type: 'guid', value: this.values.assigned.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
