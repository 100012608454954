import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { GridCellHostDirective } from '../../grid/grid-cell/grid-cell-host.directive';
import { UntypedFormControl } from '@angular/forms';
import { NumberBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/number-box-wrapper.component';
import { SelectBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/select-box-wrapper.component';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import { AppService } from 'src/app/core/app.service';
import { Wrapper } from '../../grid/grid-cell/wrapper.interface';
import { DateBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/date-box-wrapper.component';
import { TextBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/text-box-wrapper.component';
import { CustomFieldConfiguration } from 'src/app/shared/models/entities/settings/custom-field-configuration.model';
import { CustomFieldMode } from 'src/app/shared/components/features/custom-fields/custom-fields.component';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'wp-custom-field',
  template: '<ng-template wp-grid-cell-host></ng-template>',
})
export class CustomFieldComponent implements OnInit, OnDestroy {
  @ViewChild(GridCellHostDirective, { static: true })
  host: GridCellHostDirective;
  @Input() control: UntypedFormControl;
  @Input() field: CustomFieldConfiguration;
  @Input() mode: CustomFieldMode = 'default';

  private customFieldEntityType = CustomFieldEntityType;
  private propagationMode = PropagationMode;

  componentRef: any;
  viewContainerRef: ViewContainerRef;
  get placeholder(): string {
    return this.field.localizationStrings.find(
      (l) => l.culture === this.app.session.language,
    ).placeholder;
  }

  constructor(private app: AppService) {}

  ngOnInit() {
    this.viewContainerRef = this.host.hostContainer;

    switch (this.field.type) {
      case CustomFieldType.date:
        this.renderDateControl();
        break;
      case CustomFieldType.decimal:
        this.renderNumberControl('decimal');
        break;
      case CustomFieldType.integer:
        this.renderNumberControl('integer');
        break;
      case CustomFieldType.lookup:
        this.renderSelectControl();
        break;
      case CustomFieldType.string:
        this.renderStringControl();
        break;
    }

    if (this.field.isOnlyForApi && this.mode === 'default') {
      this.componentRef.instance.readonly = true;
    }
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (this.viewContainerRef) {
      this.viewContainerRef.remove();
      this.viewContainerRef = null;
    }
  }

  private renderNumberControl(type: string) {
    this.componentRef = this.viewContainerRef.createComponent(
      NumberBoxWrapperComponent,
    );
    const instance = <NumberBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.type = type;
    if (this.field.entityType === this.customFieldEntityType.ProjectTask) {
      instance.propagationMode = this.propagationMode.onExitFromEditing;
    }
  }

  private renderSelectControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      SelectBoxWrapperComponent,
    );
    const instance = <SelectBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.query = {
      filter: [
        { customFieldId: { eq: { type: 'guid', value: this.field.id } } },
      ],
    };
    instance.placeholder = this.placeholder;
    instance.collection = 'LookupValues';
  }

  private renderStringControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      TextBoxWrapperComponent,
    );
    const instance = <TextBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.placeholder = this.placeholder;
    if (this.field.entityType === this.customFieldEntityType.ProjectTask) {
      instance.propagationMode = this.propagationMode.onExitFromEditing;
    }
  }

  private renderDateControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      DateBoxWrapperComponent,
    );
    const instance = <Wrapper>this.componentRef.instance;
    instance.control = this.control;
  }
}
