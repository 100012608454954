import { Injectable } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Transition } from 'src/app/shared/models/entities/state.model';
import { LifecycleCardService } from './lifecycle-card.service';
import { TransitionModalComponent } from './state-modal/transition-modal/transition-modal.component';
import { tap } from 'rxjs/operators';

/** Сервис для работы с переходами состояний ЖЦ */
@Injectable()
export class TransitionsService {
  private addTransitionSubject = new Subject<any | void>();
  public addTransition$ = this.addTransitionSubject.asObservable();

  private changesSubject = new Subject<any | void>();
  public changes$ = this.changesSubject.asObservable();

  constructor(
    public app: AppService,
    private data: DataService,
    private modal: NgbModal,
    private fb: UntypedFormBuilder,
    private lifecycleService: LifecycleCardService,
  ) {}

  /** Обновить переходы состояния жизненного цикла. */
  public updateTransitions(stateId: string, transitionsString: string) {
    return this.data
      .collection('States')
      .entity(stateId)
      .action('UpdateTransitions')
      .execute({ schema: transitionsString })
      .pipe(
        tap(() => {
          this.changesSubject.next(null);
        }),
      );
  }

  /** Возвращает группу перехода */
  public getTransitionGroup(transition: Transition) {
    /* Патчим каждый в свою группу */
    const transitionGroup = this.getEmptyTransitionFormGroup();
    transitionGroup.patchValue(transition);
    transitionGroup.controls.nextStateName.setValue(
      this.getNextStateName(transition.nextStateId),
    );
    /* Получаем строку с названиями всех performers */
    const performersString: string = this.getPerformerString(transition);
    transitionGroup.controls.performersString.setValue(performersString);
    if (transition.performers) {
      /* Перебираем всех performers у данного transition */
      transition.performers.map((performer) => {
        /* Патчим каждого в свою группу */
        const performerGroup = this.getPerformerFormGroup();
        performerGroup.patchValue(performer);
        /* Добавляем группу с performer в массив performers */
        const performersArray = transitionGroup.controls
          .performers as UntypedFormArray;
        performersArray.push(performerGroup);
      });
    }
    if (transition.transitionForm) {
      const transitionForm = this.getEmptyTransitionFormFormGroup();
      transitionForm.patchValue(transition.transitionForm);
      /** Заполняем требуемые поля, если они есть */
      if (transition.transitionForm.requestedProperties) {
        /* Перебираем все запрашиваемые свойства */
        transition.transitionForm.requestedProperties.map((property) => {
          /* Патчим каждого в свою группу */
          const propertyGroup = this.getRequestedPropertyFormGroup();
          propertyGroup.patchValue(property);
          /* Добавляем группу с requestedProperty в массив requestedProperties */
          const propertiesArray = transitionForm.controls
            .requestedProperties as UntypedFormArray;
          propertiesArray.push(propertyGroup);
        });
        transitionGroup.controls.transitionForm = transitionForm;
      }
    }
    return transitionGroup;
  }

  /** Возвращает пустую группу перехода */
  private getEmptyTransitionFormGroup(): FormGroup {
    return this.fb.group({
      id: '',
      name: '',
      iconClass: null,
      labelStrings: null,
      nextStateId: '',
      nextStateName: '',
      performersString: '',
      performers: this.fb.array([]),
      hasTransitionForm: false,
      transitionForm: this.getEmptyTransitionFormFormGroup(),
    });
  }

  /** Возвращает пустую FormGroup для свойства transitionForm перехода  */
  public getEmptyTransitionFormFormGroup(): FormGroup {
    return this.fb.group({
      requestComment: false,
      commentIsRequired: false,
      requestedProperties: this.fb.array([]),
    });
  }

  /** Возвращает имя состояния */
  private getNextStateName(stateId: string): string {
    let stateName = '';
    this.lifecycleService.lifecycleStates.map((state) => {
      if (stateId === state.id) {
        stateName = state.name;
      }
    });
    return stateName;
  }

  /** Возвращает группу для исполнителя перехода. */
  private getPerformerFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: null,
      name: null,
      type: null,
    });
  }

  /** Возвращает группу для параметра перехода. */
  public getRequestedPropertyFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: Guid.generate(),
      name: null,
      nameControl: [null, Validators.required],
      isRequired: false,
    });
  }

  /** Возвращает строку с перечислением исполнителей состояния. */
  private getPerformerString(transition: Transition) {
    return transition?.performers?.reduce(
      (prev, performer, index) =>
        index === 0 ? performer?.name : prev + `, ${performer?.name}`,
      '',
    );
  }

  /** Создать/отредактировать переход */
  public editTransition(
    lifecycleId: string,
    stateId: string,
    transitions: any,
    transitionFormGroup?: UntypedFormGroup,
  ) {
    const modalRef = this.modal.open(TransitionModalComponent);
    const instance = modalRef.componentInstance as TransitionModalComponent;

    instance.lifecycleId = lifecycleId;
    instance.stateId = stateId;
    instance.transitions = transitions;

    instance.transitionFormGroup = transitionFormGroup
      ? transitionFormGroup
      : null;

    modalRef.result.then(
      (newGroup) => {
        newGroup.performers = newGroup.performers.map((performerLine) => ({
          id: performerLine.performer.id,
          name: performerLine.performer.name,
          type: performerLine.performer.type,
        }));
        if (transitionFormGroup) {
          this.patchTransitionFormGroup(transitionFormGroup, newGroup);
        } else {
          this.addTransitionSubject.next(newGroup);
        }
      },
      () => null,
    );
  }

  /** Обновляет группу перехода */
  public patchTransitionFormGroup(
    transitionGroup: UntypedFormGroup,
    newGroup: Transition,
  ) {
    transitionGroup.enable();
    const performersArray = transitionGroup.controls
      .performers as UntypedFormArray;
    performersArray.clear();
    transitionGroup.patchValue(newGroup);
    /* Получаем строку с названиями всех performers */
    const performersString: string = this.getPerformerString(newGroup);
    transitionGroup.controls.performersString.setValue(performersString);
    if (newGroup.performers) {
      /* Перебираем всех performers у данного transition */
      newGroup.performers.map((performer) => {
        /* Патчим каждого в свою группу */
        const performerGroup = this.getPerformerFormGroup();
        performerGroup.patchValue(performer);
        /* Добавляем группу с performer в массив performers */
        performersArray.push(performerGroup);
      });
    }
    /** Обновляем transition form */
    const transitionForm = transitionGroup.controls
      .transitionForm as UntypedFormGroup;
    const requestedPropertiesArray = transitionForm.controls
      .requestedProperties as UntypedFormArray;
    requestedPropertiesArray.clear();
    if (newGroup.transitionForm?.requestedProperties) {
      /* Перебираем все requestedProperties у данного transition */
      newGroup.transitionForm.requestedProperties.map((property) => {
        /* Патчим каждую в свою группу */
        const requestedPropertyGroup = this.getRequestedPropertyFormGroup();
        requestedPropertyGroup.patchValue(property);
        /* Добавляем группу с performer в массив performers */
        requestedPropertiesArray.push(requestedPropertyGroup);
      });
    }
    transitionGroup.disable();
  }

  /** Подготавливает DTO для исполнителей переходов. */
  private prepareTransitionsPerformers(transitionData) {
    transitionData.performers.map((performer) => {
      switch (performer.type) {
        case 'user':
          performer.userId = performer.id;
          break;
        case 'role':
          performer.role = performer.id;
          break;
        case 'permissionSet':
          performer.permissionSetId = performer.id;
          break;
        case 'group':
          performer.groupId = performer.id;
          break;
      }
    });

    return transitionData;
  }

  /** Подготавливает DTO для форм переходов. */
  private prepareTransitionsForm(transitionData) {
    if (transitionData.transitionForm) {
      if (transitionData.transitionForm.requestedProperties) {
        transitionData.transitionForm.requestedProperties.map((property) => {
          if (property.nameControl) {
            property.name = property.nameControl.id;
            delete property.id;
            delete property.nameControl;
          }
        });
      }
    }
    return transitionData;
  }

  /** Возвращает DTO для переходов. */
  public prepareTransitionsDTO(transitionsData): Transition {
    transitionsData.map((transition) => {
      /** Подготовка performers */
      transition = this.prepareTransitionsPerformers(transition);
      /**Подготовка transitionsForm */
      transition = this.prepareTransitionsForm(transition);
    });
    return transitionsData;
  }
}
