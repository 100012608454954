import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';

import { DragAndDropDirective } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.directive';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { SharedModule } from 'src/app/shared/shared.module';

import { BOARD_CONFIG } from 'src/app/boards/models/board-config.interface';
import { BoardComponent } from 'src/app/boards/components/board/board.component';
import { BoardFilterComponent } from 'src/app/boards/components/board/filter/board-filter.component';
import { BoardColumnHeaderComponent } from 'src/app/boards/components/board/column-header/board-column-header.component';
import { BoardColumnHeaderFormComponent } from 'src/app/boards/components/board/column-header-form/board-column-header-form.component';
import { BoardTrackComponent } from 'src/app/boards/components/board/track/board-track.component';
import { BoardMiniCardComponent } from 'src/app/boards/components/board/mini-card/board-mini-card.component';
import { BoardMiniCardBuilderComponent } from 'src/app/boards/components/board/mini-card-builder/board-mini-card-builder.component';
import { BoardMiniCardBuilderModalComponent } from 'src/app/boards/components/board/mini-card-builder-modal/board-mini-card-builder-modal.component';

@NgModule({
  declarations: [
    BoardComponent,
    BoardFilterComponent,
    BoardColumnHeaderComponent,
    BoardColumnHeaderFormComponent,
    BoardTrackComponent,
    BoardMiniCardComponent,
    BoardMiniCardBuilderComponent,
    BoardMiniCardBuilderModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DragAndDropDirective,
    UIRouterModule.forChild({
      states: [
        {
          name: 'board',
          url: '/board?{entityId:guid}&{navigation}&{route}',
          component: BoardComponent,
          resolve: [
            // TODO: not correct?
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: 'tasksBoard' },
            {
              provide: BOARD_CONFIG,
              deps: [
                Transition,
                DataService,
                BlockUIService,
                NotificationService,
              ],
              useFactory: StateHelper.resolveBoardConfig,
            },
          ],
        },
      ],
    }),
  ],
})
export class BoardsModule {}
