export class Constants {
  public static formNameMaxLength = 255;
  public static formCodeMaxLength = 50;
  public static formTextMaxLength = 1024;
  public static beginEventMouseGap = 1;
  public static filterInputLengthLimit = 255;
  public static textInputDebounce = 750;
  public static textInputClientDebounce = 200;
  public static typeaheadListLength = 50;
  public static scrollThrottleTime = 100;
  public static mousemoveThrottleTime = 40;

  /** Determines max attachment file size for comments, requests, etc. (in  bytes)*/
  public static maxAttachmentSize = 25 * 1024 * 1024;

  public static guidRegex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
}
