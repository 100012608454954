import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, merge } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Issue } from 'src/app/issues/models/issue.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Injectable()
export class IssueCardService {
  public issue: Issue;
  public state$ = new BehaviorSubject<CardState>(null);
  public issue$ = new BehaviorSubject<Issue>(null);
  public issueForm: FormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formTextMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    type: null,
    assigned: null,
    resolutionComment: '',
    modified: null,
    modifiedBy: null,
  });

  private query = {
    select: [
      'id',
      'name',
      'created',
      'editAllowed',
      'typeId',
      'assignedId',
      'resolutionComment',
      'modified',
      'isActive',
      'description',
    ],
    expand: [
      { createdBy: { select: 'id, name' } },
      { assigned: { select: ['id', 'name'] } },
      { type: { select: ['id', 'name'] } },
      { resolution: { select: 'id, name' } },
      { modifiedBy: { select: ['id', 'name'] } },
    ],
  };
  private destroyRef = inject(DestroyRef);

  public get formValue(): any {
    return this.issueForm.getRawValue();
  }

  constructor(
    @Inject('entityId') public issueId: string,
    private fb: FormBuilder,
    private actionPanelService: ActionPanelService,
    private lifecycleService: LifecycleService,
    private dataService: DataService,
    private navigationService: NavigationService,
    private notificationService: NotificationService,
    private savingQueueService: SavingQueueService,
    private customFieldService: CustomFieldService,
  ) {
    merge(
      this.actionPanelService.reload$,
      this.lifecycleService.reload$,
      this.savingQueueService.error$,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.customFieldService.enrichFormGroup(
      this.issueForm,
      CustomFieldEntityType.Issue,
      true,
    );

    firstValueFrom(this.issue$).then(() =>
      this.issueForm.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.save()),
    );
  }

  /** Loads issue. */
  public load(): void {
    this.state$.next(CardState.Loading);
    this.issueForm.markAsPristine();
    this.issueForm.markAsUntouched();

    this.actionPanelService.resetLifecycle();
    this.customFieldService.enrichQuery(
      this.query,
      CustomFieldEntityType.Issue,
    );

    this.dataService
      .collection('Issues')
      .entity(this.issueId)
      .get<Issue>(this.query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (issue: Issue) => {
          this.issueForm.patchValue(issue, { emitEvent: false });
          issue.editAllowed
            ? this.issueForm.enable({ emitEvent: false })
            : this.issueForm.disable({ emitEvent: false });
          this.issueForm.get('type').disable({ emitEvent: false });

          this.navigationService.addRouteSegment({
            id: issue.id,
            title: issue.name,
          });

          this.issue = issue;
          this.issue$.next(issue);
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }
        },
      });
  }

  /** Saves issue. */
  public save(): void {
    if (this.issueForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    const issue: Partial<Issue> = {
      description: this.formValue.description ?? '',
      assignedId: this.formValue.assigned?.id ?? null,
    };
    this.customFieldService.assignValues(
      issue,
      this.formValue,
      CustomFieldEntityType.Issue,
    );

    this.savingQueueService.addToQueue(
      this.issueId,
      this.dataService.collection('Issues').entity(this.issueId).patch(issue),
    );
  }

  /** Reloads page. */
  public reload(): void {
    this.savingQueueService.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }
}
