<tmt-form-header [name]="form.getRawValue().name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found>

<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form mt-3">
  <div class="d-flex gap-5">
    <div class="form-group">
      <label class="control-label">{{
        'settings.customFields.card.props.entityType.label' | translate
      }}</label>
      <p class="form-control-static">{{ entityType }}</p>
    </div>

    <div class="form-group">
      <label class="control-label">{{
        'settings.customFields.card.props.type.label' | translate
      }}</label>
      <p class="form-control-static">{{ type }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xs-6">
      <label class="group">{{ 'settings.customFields.card.props.groupMain' | translate }}</label>
      <div class="form-group" tmtIndicator>
        <label>{{ 'shared.props.name' | translate }}</label>
        <wp-text-box
          formControlName="name"
          placeholder="{{ 'shared.props.name' | translate }}"
        ></wp-text-box>
      </div>

      <div class="form-group">
        <label>{{ 'shared.props.description' | translate }}</label>
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{ 'shared.props.description' | translate }}"
        ></wp-multiline-text-box>
      </div>

      <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
        <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
        <label class="form-check-label" for="isActive">
          {{ 'shared.props.isActive.label' | translate }}
        </label>
      </div>
    </div>
    <div class="col-lg-5 col-xs-6">
      <label class="group">{{ 'settings.navigation.system.settings.header' | translate }}</label>
      <div
        class="form-check"
        title="{{ 'settings.customFields.card.props.isShownInEntityListFilters' | translate }}"
      >
        <input
          formControlName="isShownInEntityListFilters"
          type="checkbox"
          class="form-check-input"
          id="isShownInEntityListFilters"
        />
        <label class="form-check-label" for="isShownInEntityListFilters">
          {{ 'settings.customFields.card.props.isShownInEntityListFilters' | translate }}
        </label>
      </div>
      <div
        class="form-check"
        title="{{ 'settings.customFields.card.props.isShownInEntityLists' | translate }}"
      >
        <input
          formControlName="isShownInEntityLists"
          type="checkbox"
          class="form-check-input"
          id="isShownInEntityLists"
        />
        <label class="form-check-label" for="isShownInEntityLists">
          {{ 'settings.customFields.card.props.isShownInEntityLists' | translate }}
        </label>
      </div>
      <div
        class="form-check"
        title="{{ 'settings.customFields.card.props.isShownInEntityForms' | translate }}"
      >
        <input
          formControlName="isShownInEntityForms"
          type="checkbox"
          class="form-check-input"
          id="isShownInEntityForms"
        />
        <label class="form-check-label" for="isShownInEntityForms">
          {{ 'settings.customFields.card.props.isShownInEntityForms' | translate }}
        </label>
      </div>
      <div
        class="form-check"
        title="{{ 'settings.customFields.card.props.isShownInReports' | translate }}"
      >
        <input
          formControlName="isShownInReports"
          type="checkbox"
          class="form-check-input"
          id="isShownInReports"
        />
        <label class="form-check-label" for="isShownInReports">
          {{ 'settings.customFields.card.props.isShownInReports' | translate }}
        </label>
      </div>
      <div
        class="form-check"
        title="{{ 'settings.customFields.card.props.isOnlyForApi' | translate }}"
      >
        <input
          formControlName="isOnlyForApi"
          type="checkbox"
          class="form-check-input"
          id="isOnlyForApi"
        />
        <label class="form-check-label" for="isOnlyForApi">
          {{ 'settings.customFields.card.props.isOnlyForApi' | translate }}
        </label>
      </div>
    </div>
  </div>

  <wp-field-localization
    class="d-block mt-4"
    [localStrings]="localStrings"
    [languages]="languages"
    [readonly]="readonly"
  >
  </wp-field-localization>
  <wp-field-configuration
    class="d-block mt-4"
    [formGroup]="configuration"
    [formColumns]="formColumns"
    [readonly]="readonly"
    [fieldType]="field?.type"
    [fieldEntityType]="field?.entityType"
  >
  </wp-field-configuration>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
