import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { StateService } from '@uirouter/core';
import { NotificationService } from 'src/app/core/notification.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectVersionsService } from 'src/app/projects/project-versions/project-versions.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { TranslateService } from '@ngx-translate/core';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { AppService } from 'src/app/core/app.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

/**
 * Represents the Project versions entity list content.
 * */
@Component({
  selector: 'wp-project-version-list',
  templateUrl: 'project-version-list.component.html',
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
    ProjectVersionsService,
    ProjectVersionCardService,
    LifecycleListService,
  ],
})
export class ProjectVersionListComponent implements OnInit, OnDestroy {
  private collection = this.data.collection('ProjectVersions');
  private projectsCollection = this.data.collection('Projects');

  /** The Project versions main Project. */
  private project: Project = null;

  /** The selected Project version. */
  private selectedVersion: ProjectVersion = null;

  /** Determines whether the list is editable or not. */
  private readonly = true;
  /** Determines whether the merge action is allowed or not. */
  private mergeAllowed = false;

  private currencyCode: string;
  private baseCurrencyCode: string;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  /** The Get Project version subscription cancel subject. */
  private getProjectVersionDestroyed$ = new Subject<void>();

  public formName$ = new Subject<string>();

  constructor(
    @Inject('projectId') public projectId,
    public versionCardService: ProjectVersionCardService,
    private app: AppService,
    private versionService: ProjectVersionsService,
    private service: EntityListService,
    private gridService: GridService,
    private state: StateService,
    private data: DataService,
    private notification: NotificationService,
    private actions: ActionPanelService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.service.contextFilter = [
      {
        mainProjectId: { type: 'guid', value: this.projectId },
      },
    ];
    this.baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    this.loadProject();
    this.versionService.load(this.projectId);
    this.initActionPanel();

    this.gridService.selectedGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rows: any[]) => {
        if (rows?.length === 1) {
          this.updateActionVisibility(this.gridService.selectedGroupValue);
        } else {
          this.updateActionVisibility(null);
        }
      });
  }

  ngOnDestroy(): void {
    this.getProjectVersionDestroyed$.next();
    this.destroyed$.next();
    this.versionCardService.initProjectVersion();
  }

  /**
   * Sets the main menu actions.
   * */
  private initActionPanel() {
    // Main menu.
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'projects.projectVersions.list.actions.create',
        handler: () =>
          this.versionService.createVersion(this.selectedVersion, true),
      }),
      {
        title: 'projects.projectVersions.list.actions.merge',
        hint: 'projects.projectVersions.list.actions.merge',
        name: 'merge',
        isDropDown: false,
        iconClass: 'bi bi-sign-merge-left bi-15',
        isBusy: false,
        isVisible: false,
        handler: () =>
          this.versionService.openMergeVersionsDialog(
            this.projectId,
            this.currencyCode,
          ),
      },
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('projectVersion', {
            routeMode: RouteMode.continue,
            entityId: this.gridService.selectedGroupValue.id,
            projectId: this.projectId,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
      {
        title: 'projects.projectVersions.list.actions.setMaster',
        hint: 'projects.projectVersions.list.actions.setMaster',
        name: 'setMasterBaseline',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: this.setMasterBaseline,
      },
    ]);
  }

  /**
   * Loads the Project entity.
   * */
  private loadProject() {
    this.currencyCode = this.baseCurrencyCode;
    if (this.projectId) {
      const query = {
        select: [
          'id',
          'name',
          'editAllowed',
          'versionViewAllowed',
          'versionEditAllowed',
          'versionMergeAllowed',
        ],
        expand: [{ currency: { select: ['name', 'id', 'alpha3Code'] } }],
      };
      this.projectsCollection
        .entity(this.projectId)
        .get<Project>(query)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (project) => {
            this.project = project;
            this.currencyCode =
              project.currency?.alpha3Code ?? this.baseCurrencyCode;

            this.formName$.next(
              this.translate.instant('projects.projectVersions.header', {
                projectName: project.name,
              }),
            );

            this.versionCardService.updateWorkProjectVersion(project);
            this.readonly = !project.versionEditAllowed;
            this.mergeAllowed = project.versionMergeAllowed;

            this.actions.action('create').isShown = !this.readonly;
            this.actions.action('merge').isShown =
              !this.readonly && this.mergeAllowed;
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
          },
        });
    }
  }

  /**
   * Updates the main menu actions visibility state.
   * */
  private updateActionVisibility(row: any) {
    if (row) {
      this.getProjectVersionDestroyed$.next();
      this.selectedVersion = null;
      this.versionService
        .getVersion(row.id)
        .pipe(takeUntil(this.getProjectVersionDestroyed$))
        .subscribe({
          next: (version) => {
            this.selectedVersion = version;
            this.actions.action('card').isShown = true;
            this.actions.action('delete').isShown =
              !this.readonly && version.editAllowed;
            this.actions.action('setMasterBaseline').isShown =
              !this.readonly &&
              version.setMasterFlagAllowed &&
              version.masterBaseline === false;
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.gridService.detectChanges();
          },
        });
    } else {
      this.actions.action('card').isShown = false;
      this.actions.action('delete').isShown = false;
      this.actions.action('setMasterBaseline').isShown = false;
    }
  }

  /**
   * Sets the masterBaseline flag to true for the selected Project version.
   * */
  private setMasterBaseline = () => {
    this.collection
      .entity(this.gridService.selectedGroupValue.id)
      .action('SetVersionMaster')
      .execute({
        masterBaseline: true,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projectVersions.list.messages.setMaster',
          );
          this.service.reload();
        },
        error: (error: any) => {
          this.notification.error(error.message);
        },
      });
  };
}
