import {
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { NavigationService } from 'src/app/core/navigation.service';
import { RateMatrixService } from 'src/app/settings-app/rate-matrix/card/rate-matrix.service';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import _ from 'lodash';
import { Analytics } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-rate-matrix-card',
  templateUrl: './rate-matrix-card.component.html',
  providers: [
    RateMatrixService,
    { provide: ENTITY_COLLECTION, useValue: 'RateMatrices' },
    LifecycleService,
  ],
})
export class RateMatrixCardComponent implements OnInit {
  @Input() private entityId: string;

  public tabs: TabLink[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    public stateService: StateService,
    public rateMatrixService: RateMatrixService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private actionPanelService: ActionPanelService,
    private lifecycleService: LifecycleService,
  ) {}

  public ngOnInit(): void {
    this.tabs.push({
      header: 'settings.rateMatrices.card.tabs.main',
      state: 'settings.rateMatrix.main',
    });

    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (RateMatrix.draftStateId === value.currentState.id) {
          this.actionPanelService.action('delete').isShown = true;
        } else {
          this.actionPanelService.action('delete').isShown = false;
        }
      });

    this.rateMatrixService
      .getRateMatrices(this.entityId, {
        select: ['id', 'name', 'editAllowed', 'rateMatrixStructure', 'stateId'],
        expand: [{ lines: { count: 'true', top: 0 } }],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (rateMatrix: RateMatrix) => {
          this.rateMatrixService.updateName(rateMatrix.name);
          this.rateMatrixService.readonly = !rateMatrix.editAllowed;
          this.rateMatrixService.rateMatrixStructure =
            rateMatrix.rateMatrixStructure.map(
              (key) => _.camelCase(key) as Analytics,
            );
          this.rateMatrixService.rateMatrix = rateMatrix;
          this.rateMatrixService.isLinesExisting =
            !!rateMatrix['lines@odata.count'];

          this.tabs.push({
            header: 'settings.rateMatrices.card.tabs.rates',
            state: 'settings.rateMatrix.matrixRate',
          });

          this.setAdditionalButtons();

          if (
            this.tabs.length > 0 &&
            !this.tabs.find((t) => t.state === this.stateService.current.name)
          ) {
            this.stateService.go(this.tabs[0].state, this.stateService.params);
          }

          this.rateMatrixService.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: rateMatrix.id,
            title: rateMatrix.name,
          });
        },
        error: (error: Exception) => {
          this.rateMatrixService.state$.next(CardState.Ready);
          this.notificationService.error(error.message);
        },
      });
  }

  private setAdditionalButtons(): void {
    this.actionPanelService.setAdditional([
      {
        title: 'shared.actions.copy',
        hint: 'shared.actions.copy',
        name: 'copy',
        isBusy: false,
        isVisible: true,
        handler: () => this.rateMatrixService.openCopyModal(),
      },
      {
        title: 'shared.actions.delete',
        hint: 'shared.actions.delete',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.rateMatrixService.delete(),
      },
    ]);
  }
}
