<tmt-form-header
  [name]="certificateEntity?.name"
  [isEditable]="certificateEntity?.editAllowed"
  [saveFn]="saveName"
></tmt-form-header>
<wp-loading-indicator [state]="state$ | async"></wp-loading-indicator>
<tmt-not-found [state]="state$ | async" [urlMustContain]="entityId"></tmt-not-found>

<ng-container *ngIf="(state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator></wp-state-indicator>
      </div>

      <div class="subheader__prop">
        <tmt-workflow-indicator></tmt-workflow-indicator>
      </div>
    </div>
  </div>

  <form [formGroup]="form" class="form certificate-container">
    <div class="row">
      <div class="form-group w-50" tmtIndicator>
        <label>
          {{ 'team.certificates.card.mainProps.owner' | translate }}
        </label>
        <wp-user-box
          formControlName="owner"
          collection="Users"
          placeholder="{{ 'team.certificates.card.mainProps.owner' | translate }}"
          [query]="usersQuery"
          [allowNull]="false"
        ></wp-user-box>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="form-group date-form-group me-4" tmtIndicator>
        <label>
          {{ 'team.certificates.card.mainProps.effectiveDate' | translate }}
        </label>
        <wp-date-box
          class="prop-field"
          formControlName="effectiveDate"
          [allowNull]="false"
        ></wp-date-box>
      </div>
      <div class="form-group date-form-group">
        <label>
          {{ 'team.certificates.card.mainProps.expiryDate' | translate }}
        </label>
        <wp-date-box class="prop-field" formControlName="expiryDate"></wp-date-box>
      </div>
    </div>
    <wp-custom-fields
      class="d-block custom-fields-container"
      [formGroup]="form"
      entityType="Certificate"
      [isVisibleHr]="false"
    ></wp-custom-fields>
  </form>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-3">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.Certificates"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="CommentedEntityCollectionType.Certificates"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <tmt-entity-workflow-tasks
        *ngbNavContent
        [entityId]="entityId"
        class="mt-2 d-block"
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history
        [entityId]="entityId"
        *ngbNavContent
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
</ng-container>
