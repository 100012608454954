<div *ngIf="!service.readonly" class="container mx-0">
  <div *ngFor="let transition of transitions.value; index as index" class="trim">
    <span (click)="editTransition(index)" [ngClass]="{ link: !service.readonly }">
      {{ transition.name }}
    </span>
    <i class="bi bi-arrow-right"></i>
    <span [title]="transition.nextStateName"> {{ transition.nextStateName }}: </span>

    <span [title]="transition.performersString">
      {{ transition.performersString }}
    </span>
    <br />
  </div>
</div>
