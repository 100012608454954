import { Component, DestroyRef, Inject, OnInit, inject } from '@angular/core';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {
  GridColumnType,
  GridCommandColumn,
  GridComponentColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { NotificationService } from 'src/app/core/notification.service';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { LifecycleToolbarComponent } from './lifecycle-toolbar/lifecycle-toolbar.component';
import { Exception } from 'src/app/shared/models/exception';
import { State, Transition } from 'src/app/shared/models/entities/state.model';
import { LifecycleCardService } from './lifecycle-card.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { TransitionsCellComponent } from './transitions-cell/transitions-cell.component';
import { TransitionsService } from './transitions.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Lifecycle } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { MermaidSchemaService } from '../../../shared-features/mermaid-schema/mermaid-schema.service';
import { TransitionFormService } from './state-modal/transition-modal/transition-form/transition-form.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'wp-lifecycle',
  templateUrl: './lifecycle-card.component.html',
  providers: [GridService, MermaidSchemaService],
})
export class LifecycleCardComponent implements OnInit {
  public isLoading: boolean;
  public readonly: boolean;

  public form: FormGroup = this.fb.group({
    id: '',
    name: '',
    entityType: '',
    states: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    editing: 'always',
    toolbar: LifecycleToolbarComponent,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => !this.readonly,
        handlerFn: () => {
          this.editSelectedState();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: () => {
          this.deleteSelectedState();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.service.editState(this.entityId);
        },
      },
      {
        name: 'edit',
        handlerFn: () => {
          this.editSelectedState();
        },
      },
      {
        name: 'delete',
        handlerFn: () => {
          this.deleteSelectedState();
        },
      },
      {
        name: 'moveUp',
        handlerFn: () => {
          this.decreaseStateIndex();
        },
      },
      {
        name: 'moveDown',
        handlerFn: () => {
          this.increaseStateIndex();
        },
      },
    ],
    view: {
      name: 'states',
      columns: [
        <GridCommandColumn>{
          name: 'name',
          command: 'edit',
          width: '15%',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.Command,
        },
        {
          name: 'code',
          width: '150px',
          header: 'shared.columns.code',
          hint: 'shared.columns.code',
          type: GridColumnType.String,
        },
        {
          name: 'style',
          width: '120px',
          header: 'settings.lifecycles.card.props.state.style',
          hint: 'settings.lifecycles.card.props.state.style',
          type: GridColumnType.State,
        },
        <GridComponentColumn>{
          name: 'transitions',
          type: GridColumnType.Component,
          component: TransitionsCellComponent,
          header: 'settings.lifecycles.card.columns.transitions.header',
          hint: 'settings.lifecycles.card.columns.transitions.hint',
        },
      ],
    },
  };

  private lifecycleData: Lifecycle;

  private loadingSubscription;

  private destroyRef = inject(DestroyRef);

  public get states(): UntypedFormArray {
    return this.form.controls.states as UntypedFormArray;
  }

  constructor(
    @Inject('entityId') public entityId: string,
    private notification: NotificationService,
    public service: LifecycleCardService,
    private fb: UntypedFormBuilder,
    private gridService: GridService,
    private messageService: MessageService,
    private blockUI: BlockUIService,
    private transitionsService: TransitionsService,
    private navigationService: NavigationService,
    private mermaidSchemaService: MermaidSchemaService,
    private transitionFormService: TransitionFormService,
    private actionService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.load();
    const formChangingDebounceTime = 300;
    this.form.valueChanges
      .pipe(
        debounceTime(formChangingDebounceTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const states = this.form.getRawValue().states.map((state) => ({
          ...state,
          style: state.style.style,
          isInitial: this.lifecycleData.states.find(
            (lfState) => state.id === lfState.id,
          ).isInitial,
        }));
        this.mermaidSchemaService.updateSchema({ states });
      });

    this.service.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  private getStateFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      index: null,
      name: '',
      code: '',
      style: null,
      transitions: this.fb.array([]),
    });
  }

  private reload(): void {
    this.service.reload();
  }

  private load(): void {
    this.isLoading = true;

    const states = this.form.controls.states as FormArray;
    states.clear();
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.service
      .getLifecycle(this.entityId)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((lifecycle: Lifecycle) => {
          this.lifecycleData = lifecycle;
          this.form.patchValue(lifecycle);

          this.navigationService.addRouteSegment({
            id: this.entityId,
            title: lifecycle.name,
          });

          /* Объект для сборки запросов для получения переходов состояний */
          const transitionsRequests = {};

          /* Перебираем состояния */
          (lifecycle.states as State[]).forEach((state) => {
            const group = this.getStateFormGroup();
            group.patchValue(state);

            group.controls.style.setValue({
              name: state.name,
              code: state.code,
              style: state.style,
            });

            states.push(group);

            /* Добавляем запрос для получения состояний текущего стейта */
            transitionsRequests[state.id] = this.service.getTransitions(
              state.id,
            );
          });

          this.readonly = !lifecycle.editAllowed;

          /* Запрашиваем переходы состояний */
          return forkJoin(transitionsRequests);
        }),
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (allTransitions) => {
          /* Перебираем стейты */
          states.controls.map((state: FormGroup) => {
            /* Берем переходы для текущего стейта */
            const stateTransitions = allTransitions[
              state.value.id
            ] as Array<Transition>;
            if (stateTransitions !== null) {
              stateTransitions?.forEach((transition) => {
                const transitionGroup =
                  this.transitionsService.getTransitionGroup(transition);
                const transitionsFormArray = state.controls[
                  'transitions'
                ] as UntypedFormArray;
                transitionsFormArray.push(transitionGroup);
              });
              state.disable();
            }
          });
          this.updateGridSort();

          this.transitionFormService.setAllowedTransitionFormProperties(
            this.entityId,
            this.lifecycleData.entityType,
          );

          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  private editSelectedState(): void {
    if (!this.gridService.selectedRow) {
      return;
    }
    this.service.editState(this.entityId, this.gridService.selectedRow.id);
  }

  private deleteSelectedState(): void {
    if (!this.gridService.selectedRow) {
      return;
    }
    this.messageService
      .confirmLocal('settings.lifecycles.card.props.state.deleteConfirmation')
      .then(
        () => {
          this.service
            .deleteState(this.gridService.selectedRow.id)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: () => {
                this.load();
              },
              error: (error: Exception) => {
                this.isLoading = false;
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  private decreaseStateIndex(): void {
    const stateId = this.gridService.selectedRow.id;
    const stateIndex = this.gridService.selectedRow.index;
    if (stateIndex === 0) {
      return;
    }
    const increaseStateId = this.states.value.find(
      (state) => state.index === stateIndex - 1,
    )?.id;

    this.blockUI.start();
    this.service
      .updateStateIndex(stateId, stateIndex - 1)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((res) => {
          if (increaseStateId) {
            return this.service.updateStateIndex(increaseStateId, stateIndex);
          } else {
            return of(res);
          }
        }),
      )
      .subscribe({
        next: () => {
          this.states.controls
            .find((control) => control.value.id === stateId)
            .patchValue({ index: stateIndex - 1 });
          if (increaseStateId) {
            this.states.controls
              .find((control) => control.value.id === increaseStateId)
              .patchValue({ index: stateIndex });
          }
          this.updateGridSort();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  private increaseStateIndex(): void {
    const stateId = this.gridService.selectedRow.id;
    const stateIndex = this.gridService.selectedRow.index;
    if (stateIndex === this.states.value.length - 1) {
      return;
    }
    const decreaseStateId = this.states.value.find(
      (state) => state.index === stateIndex + 1,
    )?.id;

    this.blockUI.start();
    this.service
      .updateStateIndex(stateId, stateIndex + 1)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((res) => {
          if (decreaseStateId && stateIndex !== 0) {
            return this.service.updateStateIndex(decreaseStateId, stateIndex);
          } else {
            return of(res);
          }
        }),
      )
      .subscribe({
        next: () => {
          this.states.controls
            .find((control) => control.value.id === stateId)
            .patchValue({ index: stateIndex + 1 });
          if (decreaseStateId) {
            this.states.controls
              .find((control) => control.value.id === decreaseStateId)
              .patchValue({ index: stateIndex });
          }
          this.updateGridSort();
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  private updateGridSort(): void {
    this.states.controls.sort((a, b) =>
      a.value.index < b.value.index ? -1 : 0,
    );
    this.gridService.detectChanges();
  }
}
