import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  VIEW_NAME,
  LIST,
  FILTER,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
} from 'src/app/shared/tokens';
import { ResourceRequestListComponent } from './list/resource-request-list.component';
import { RESOURCE_REQUEST_LIST } from 'src/app/shared/lists/resource-request.list';
import { ResourceRequestCardComponent } from './card/resource-request-card.component';

import { RequestFilterComponent } from './list/request-filter/request-filter.component';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { ResourceRequestModule } from 'src/app/resource-requests/shared/resource-request/resource-request.module';
import { ResourceRequestCardMainComponent } from 'src/app/resource-requests/card/resource-request-card-main/resource-request-card-main.component';
import { ResourceRequestFormAdvancedComponent } from 'src/app/resource-requests/shared/form-advanced/resource-request-form-advanced.component';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    ResourceRequestListComponent,
    ResourceRequestCardComponent,
    ResourceRequestCardMainComponent,
    RequestFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ResourceRequestModule,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'resourceRequests',
          url: '/resource-requests/{view:viewName}?{navigation}&{route}&{filter:json}',
          component: ResourceRequestListComponent,
          params: {
            context: null,
            entity: null,
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            routeHeader: 'resources.navigation.requests.header',
          },
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: RESOURCE_REQUEST_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'resourceRequest',
                queryData: {
                  select: ['id'],
                  expand: {
                    select: ['id', 'stateId', 'name'],
                    expand: { state: { select: 'isEntityProtected' } },
                  },
                },
              } as MassOperationParameters,
            },
            {
              token: FILTER,
              deps: [Transition],
              resolveFn: StateHelper.resolveFilter,
            },
          ],
        },

        {
          name: 'resourceRequest',
          params: {
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            navigation: null,
          },
          url: '/resource-requests/{entityId:guid}?{navigation}&{route}',
          dynamic: true,
          component: ResourceRequestCardComponent,
          redirectTo: (trans) => ({
            state: 'resourceRequest.main',
            params: trans.params(),
          }),
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
            {
              token: 'assistantRequestId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'resourceRequest.main',
          url: '/main',
          component: ResourceRequestCardMainComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'resourceRequest.advanced',
          url: '/advanced',
          component: ResourceRequestFormAdvancedComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ResourceRequestsModule {}
