import { EnumEntity } from 'src/app/shared/models/enums/enum-entity';

export class ProjectPlanningMode extends EnumEntity {
  public static get manual(): ProjectPlanningMode {
    return {
      id: '4d1a525f-3abc-4871-a64a-349c1dd3cabf',
      code: 'Manual',
      name: 'enums.projectPlanningMode.manual',
    };
  }

  public static get resourceBased(): ProjectPlanningMode {
    return {
      id: '584dddc1-94df-43b2-b3f3-372c02fcb016',
      code: 'ResourceBased',
      name: 'enums.projectPlanningMode.resourceBased',
    };
  }

  public static get automatic(): ProjectPlanningMode {
    return {
      id: 'e87e0e6b-c034-45ac-8b74-bd0256f3f535',
      code: 'Automatic',
      name: 'enums.projectPlanningMode.automatic',
    };
  }
}

export const projectPlanningModes: ProjectPlanningMode[] = [
  ProjectPlanningMode.manual,
  ProjectPlanningMode.resourceBased,
  ProjectPlanningMode.automatic,
];
