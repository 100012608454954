import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, filter } from 'rxjs';
import _ from 'lodash';
import {
  TransitionService,
  Transition,
  StateService,
  UIRouterGlobals,
} from '@uirouter/core';
import {
  Navigation,
  NavigationGroup,
  NavigationItem,
  NavigationItemCustom,
  NavigationView,
} from '../shared/models/navigation/navigation';
import { TranslateService } from '@ngx-translate/core';
import { ActionPanelService } from './action-panel.service';
import { DataService } from './data.service';
import { AppService } from './app.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Dictionary } from '../shared/models/dictionary';
import { LogService } from './log.service';
import { AppName } from '../shared/globals/app-name';
import buildQuery from 'odata-query';
import { Guid } from '../shared/helpers/guid';
import { clone, cloneDeep } from 'lodash';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { MY_APP_NAVIGATION } from 'src/app/shared/navigations/my-app.navigation';
import { TEAM_APP_NAVIGATION } from 'src/app/shared/navigations/team-app.navigation';
import { PROJECTS_APP_NAVIGATION } from 'src/app/shared/navigations/projects-app.navigation';
import { BILLING_APP_NAVIGATION } from 'src/app/shared/navigations/billing-app.navigation';
import { ANALYTICS_APP_NAVIGATION } from 'src/app/shared/navigations/analytics-app.navigation';
import { SETTINGS_APP_NAVIGATION } from 'src/app/shared/navigations/settings-app.navigation';
import { FINANCE_APP_NAVIGATION } from 'src/app/shared/navigations/finance-app.navigation';
import { RESOURCE_APP_NAVIGATION } from 'src/app/shared/navigations/resources-app.navigation';
import { CLIENTS_APP_NAVIGATION } from 'src/app/shared/navigations/clients-app.navigation';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationSubject = new BehaviorSubject<Navigation>(null);
  public navigation$ = this.navigationSubject
    .asObservable()
    .pipe(filter((n) => !!n));

  private routeSubject = new Subject<RouteItem[]>();
  public route$ = this.routeSubject.asObservable();
  public route: RouteItem[] = [];

  private previousStateSubject = new BehaviorSubject<{
    name: string;
    params: any;
  }>(null);
  public previousState$ = this.previousStateSubject.asObservable();

  private navigationItemSubject = new BehaviorSubject<NavigationItem>(null);
  public navigationItem$ = this.navigationItemSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  private indicatorSubject = new BehaviorSubject<{
    name: string;
    value: number;
  }>(null);
  public indicator$ = this.indicatorSubject
    .asObservable()
    .pipe(filter((x) => !!x));

  public currentNavigation: Navigation;
  public selectedNavigationItem: NavigationItem | null = null;
  public indicatorValues = new Map();

  /**
   * Names of navigation items that must be destroyed when router state changes.
   * It used to delete of temporary (context) items.
   */
  private tempItems: { name: string; keepNavInState: string }[] = [];
  private idleTime = 2 * 60; // sec.
  private indicatorsCheckingTime = 1000 * 60 * 6; // ms.
  private indicatorsTimer: ReturnType<typeof setInterval>;
  // TODO: make private & add getter
  public appToNavigationMap = new Map<AppName, Navigation>([
    [AppName.My, MY_APP_NAVIGATION],
    [AppName.Team, TEAM_APP_NAVIGATION],
    [AppName.Projects, PROJECTS_APP_NAVIGATION],
    [AppName.Clients, CLIENTS_APP_NAVIGATION],
    [AppName.Billing, BILLING_APP_NAVIGATION],
    [AppName.Analytics, ANALYTICS_APP_NAVIGATION],
    [AppName.Settings, SETTINGS_APP_NAVIGATION],
    [AppName.Finance, FINANCE_APP_NAVIGATION],
    [AppName.Resources, RESOURCE_APP_NAVIGATION],
  ]);
  private appToNavigationMapDefault = new Map<AppName, Navigation>();
  private stateByEntityType: {
    [entityType: string]: string;
  } = {
    dashboard: 'dashboard',
    board: 'board',
  };
  private readonly customizer = (target, source) => {
    if (Array.isArray(target)) {
      return target.map((item) => {
        const srcItem = source.find((s: any) => s.name === item.name);
        return srcItem ? _.mergeWith(item, srcItem, this.customizer) : item;
      });
    }
  };

  /** Gets route to store in state (optimized). */
  public get storedRoute(): any[] {
    // Skip the first two step, we know this from navigation.
    const route = this.route?.length > 2 ? cloneDeep(this.route.slice(2)) : [];

    // Remove route from all states.
    route.forEach((segment) => {
      delete segment.state.params['route'];
    });

    return route;
  }

  constructor(
    private log: LogService,
    private data: DataService,
    private app: AppService,
    transitionService: TransitionService,
    idle: Idle,
    private actions: ActionPanelService,
    private stateService: StateService,
    private translate: TranslateService,
    private routerGlobals: UIRouterGlobals,
  ) {
    idle.setIdle(this.idleTime);
    idle.setTimeout(0);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.watch();
    idle.onIdleStart.subscribe(() => {
      log.info("User's idle has been started.");
      this.updateIndicators();
      this.stopIndicatorUpdating();
    });
    idle.onIdleEnd.subscribe(() => {
      log.info("User's idle was stopped.");
      this.startIndicatorUpdating();
    });
    this.startIndicatorUpdating();
    transitionService.onSuccess({}, (trans) => this.onTransitionSuccess(trans));
    transitionService.onStart({}, (trans) => this.onTransitionStart(trans));
  }

  public load(navigation: Navigation) {
    this.currentNavigation = cloneDeep(navigation);

    // Enrich navigation stateParams by navigation.
    this.currentNavigation.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (!item.stateParams) {
          item.stateParams = {};
        }
        item.stateParams.navigation = item.name;
      });
    });

    this.navigationSubject.next(navigation);
    this.updateIndicators();
  }

  public getNavigationTitle(): string {
    return this.selectedNavigationItem.header;
  }

  /**
   * Reloads the current state.
   *
   * A method that force reloads the current state, or a partial state hierarchy.
   * All resolves are re-resolved, and components reinstantiated
   *
   * @param state The state.
   * */
  public reloadState(state?: string): void {
    this.stateService.reload(state);
  }

  /**
   * Return to the back/parent state.
   *
   * @param forceReload Indicates whether to reload when current state is equal to returned one or not.
   * */
  public goToSelectedNavItem(forceReload?: boolean): void {
    if (
      forceReload &&
      this.routerGlobals.current.name === this.selectedNavigationItem.state
    ) {
      this.reloadState();
      return;
    }

    this.stateService.go(
      this.selectedNavigationItem.state,
      this.selectedNavigationItem.stateParams,
    );
  }

  /** Provides custom navigation view. */
  public initNavigationViews(): void {
    this.updateNavigationGroupByCustom(this.app.navigationItems, 'add');
    this.cacheDefaultNavigation();

    if (!this.app.clientProfile.navigationViews?.length) {
      this.app.clientProfile.navigationViews = [];

      this.appToNavigationMap.forEach((navigation) => {
        this.app.clientProfile.navigationViews.push(
          this.buildNavigationView(navigation),
        );
      });

      this.app.saveProfile();
    }

    this.appToNavigationMap.forEach((navigation) => {
      const userNavigationView = this.getNavigationView(navigation.name);

      if (userNavigationView) {
        this.mergeNavigationViews(
          userNavigationView,
          this.buildNavigationView(navigation),
        );
      }

      _.mergeWith(
        navigation,
        userNavigationView ?? this.buildNavigationView(navigation),
        this.customizer,
      );
    });
  }

  /**
   * Saves navigation view to client profile.
   *
   * @param navigation Navigation settings.
   */
  public saveNavigationView(navigation: Navigation): void {
    this.appToNavigationMap.set(navigation.name as AppName, navigation);

    _.merge(
      this.getNavigationView(navigation.name),
      this.buildNavigationView(navigation),
    );

    this.app.saveProfile();

    this.currentNavigation = navigation;
    this.navigationSubject.next(navigation);
  }

  /**
   * Sends reset to default navigation view.
   *
   * @param navigation Navigation settings.
   */
  public resetNavigationView(navigation: Navigation): void {
    _.mergeWith(
      navigation,
      this.buildNavigationView(
        this.appToNavigationMapDefault.get(navigation.name as AppName),
      ),
      this.customizer,
    );

    this.navigationSubject.next(navigation);
  }

  /**
   * Updates navigation relative to custom navigation items.
   *
   * @param items custom navigation items.
   * @param type
   * `add` - adds item to group, if item already exists, then just updates header and hint.
   * `remove' - removes item from group.
   * @param emitEvent Indicates whether to emit current navigation.
   */
  public updateNavigationGroupByCustom(
    items: Partial<NavigationItemCustom>[],
    type: 'add' | 'remove',
    emitEvent?: boolean,
  ): void {
    for (const itemConfig of items) {
      const navigation =
        this.currentNavigation?.name === itemConfig.area
          ? this.currentNavigation
          : this.appToNavigationMap.get(itemConfig.area as AppName);

      if (!navigation) {
        continue;
      }

      const group = navigation.groups.find((g) => g.name === itemConfig.group);

      if (!group) {
        continue;
      }

      const name = `${itemConfig.area}.${itemConfig.entityId}`;
      const index = group.items.findIndex((i) => i.name === name);

      switch (type) {
        case 'add': {
          if (index === -1) {
            group.items.push({
              name,
              header: itemConfig.header,
              hint: itemConfig.description,
              state: this.stateByEntityType[_.lowerCase(itemConfig.entityType)],
              stateParams: {
                entityId: itemConfig.entityId,
                navigation: name,
              },
            });
          } else {
            group.items[index].header = itemConfig.header;
            group.items[index].hint = itemConfig.description;
          }

          break;
        }
        case 'remove': {
          if (index !== -1) {
            group.items.splice(index, 1);
          }

          break;
        }
        default:
          continue;
      }
    }

    if (emitEvent) {
      this.navigationSubject.next(this.currentNavigation);
    }
  }

  /** Loads custom navigation items and re-init navigation views. */
  public async reloadCustomNavigationItems(): Promise<void> {
    const removedItems = await this.app.loadNavigationItems();

    if (removedItems?.length) {
      this.updateNavigationGroupByCustom(removedItems, 'remove');
    }

    this.updateNavigationGroupByCustom(this.app.navigationItems, 'add', true);
  }

  public createAndSelectNavItem(
    groupName: string,
    keepNavInState: string,
    item: NavigationItem,
  ) {
    const group = this.currentNavigation.groups.find(
      (g) => g.name === groupName,
    );

    const existsItem = group.items.find((i) => i.name === item.name);

    if (!existsItem) {
      group.items.push(item);
    } else {
      existsItem.header = item.header;
      existsItem.hint = item.hint;
      existsItem.state = item.state;
      existsItem.stateParams = item.stateParams;
    }

    this.navigationSubject.next(this.currentNavigation);
    this.tempItems.push({ name: item.name, keepNavInState });
    this.selectNavigationItemBy({ name: item.name });
  }

  private buildNavigationView(navigation: Navigation): NavigationView {
    return {
      name: navigation.name,
      groups: navigation.groups.map((group) => ({
        name: group.name,
        items: group.items.map((item) => ({
          name: item.name,
          isVisible: item.isVisible ?? true,
        })),
      })),
    };
  }

  private getNavigationView(name: string): NavigationView | null {
    return this.app.clientProfile.navigationViews.find(
      (view) => view.name === name,
    );
  }

  private cacheDefaultNavigation(): void {
    this.appToNavigationMap.forEach((navigation) => {
      this.appToNavigationMapDefault.set(
        navigation.name as AppName,
        _.cloneDeep(navigation),
      );
    });
  }

  private mergeNavigationViews(
    userNavigation: NavigationView,
    defaultNavigation: NavigationView,
  ): void {
    const defaultGroupsMap = _.keyBy(defaultNavigation.groups, 'name');
    const userGroupsMap = _.keyBy(userNavigation.groups, 'name');

    userNavigation.groups = userNavigation.groups
      .filter((group) => defaultGroupsMap[group.name])
      .concat(
        defaultNavigation.groups.filter((group) => !userGroupsMap[group.name]),
      )
      .map((group) => {
        const defaultGroup = defaultGroupsMap[group.name];
        const defaultItemsMap = _.keyBy(defaultGroup.items, 'name');
        const userItemsMap = _.keyBy(group.items, 'name');

        group.items = group.items
          .filter((item) => defaultItemsMap[item.name])
          .concat(
            defaultGroup.items.filter((item) => !userItemsMap[item.name]),
          );

        return group;
      });
  }

  private destroyTempItems(stateName: string) {
    this.tempItems.forEach((item) => {
      if (item.keepNavInState === stateName) {
        return;
      }
      const group = this.getNavigationGroupByItemName(item.name);
      if (!group) {
        return;
      }
      const index = group.items.findIndex((i) => i.name === item.name);
      group.items.splice(index, 1);
      this.navigationSubject.next(this.currentNavigation);
    });
  }

  private getNavigationGroupByItemName(name: string): NavigationGroup {
    let result = null;
    this.currentNavigation?.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (item.name === name) {
          result = group;
        }
      });
    });
    return result;
  }

  private getNavigationItemBy = (criteria: {
    name?: string;
    state?: string;
  }) => {
    let result = null;
    this.currentNavigation?.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (
          (criteria.name && item.name === criteria.name) ||
          (criteria.state && item.state === criteria.state)
        ) {
          result = item;
        }
      });
    });
    return result;
  };

  private selectNavigationItemBy(criteria: { name?: string; state?: string }) {
    const item = this.getNavigationItemBy(criteria);

    if (!item || this.selectedNavigationItem?.name === item.name) {
      return;
    }

    this.selectedNavigationItem = item;
    this.navigationItemSubject.next(item);

    return true;
  }

  public getRouteBasis(): RouteItem[] {
    const breadCrumbs: RouteItem[] = [];

    if (this.selectedNavigationItem) {
      breadCrumbs.push({
        title: this.translate.instant(
          this.getNavigationGroupByItemName(this.selectedNavigationItem.name)
            .header,
        ),
        id: Guid.generate(),
      });

      breadCrumbs.push({
        title: this.translate.instant(this.selectedNavigationItem.header),
        state: {
          name: this.selectedNavigationItem.state,
          params: this.selectedNavigationItem.stateParams,
        },
        id: Guid.generate(),
      });
    }
    return breadCrumbs;
  }

  /** Reinitialize route on navigation item select. */
  public resetRoute() {
    this.route = this.getRouteBasis();
    this.propagateRoute();
  }

  public propagateRoute() {
    this.routeSubject.next(this.route);
    const previousState = this.getPreviousState();
    this.previousStateSubject.next(previousState);
  }

  public addRouteSegment(item: RouteItem) {
    if (!item.id) {
      item.id = Guid.generate();
    }

    // Ignore sequenced dupes.
    if (this.route[this.route.length - 1]?.id === item.id) {
      return;
    }

    if (!item.state) {
      const newParams = cloneDeep(this.routerGlobals.params);
      delete newParams.routeMode;
      item.state = {
        name: this.routerGlobals.current.name,
        params: newParams,
      };
    }

    if (item.localTitle) {
      item.title = this.translate.instant(item.localTitle);
    }

    this.route.push(item);
    this.propagateRoute();
  }

  private onTransitionStart(trans: Transition) {
    const toState = trans.to();
    const params = trans.params();

    if (params?.routeHeader && params?.routeMode === RouteMode.continue) {
      const newParams = cloneDeep(params);
      delete newParams.routeMode;
      const state = {
        name: toState.name,
        params: newParams,
      };

      this.addRouteSegment({
        id: toState.name,
        localTitle: params?.routeHeader,
        state,
      });
    }

    // Add parameter 'route' if it doesn't already exist (and redirect).
    if (params?.routeMode === RouteMode.continue) {
      const newParams = clone(params);
      newParams['navigation'] = this.selectedNavigationItem.name;
      delete newParams.routeMode;

      if (this.storedRoute.length) {
        newParams['route'] = cloneDeep(this.storedRoute);
      }

      const target = trans.router.stateService.target(
        trans.to().name,
        newParams,
        { inherit: false },
      );

      return target;
    }

    // Add 'navigation' param if it doesn't already exist (and redirect).
    if (
      this.selectedNavigationItem &&
      !params?.navigation &&
      toState.params &&
      Object.keys(toState.params).indexOf('navigation') > -1
    ) {
      const newParams = clone(params);
      newParams['navigation'] = this.selectedNavigationItem.name;
      const target = trans.router.stateService.target(
        trans.to().name,
        newParams,
        { inherit: false },
      );
      return target;
    }

    // Load app navigation;
    const navigation = params.navigation;
    if (navigation) {
      const appName = this.getAppName(navigation);

      if (
        this.appToNavigationMap.has(appName) &&
        this.currentNavigation?.name !== appName
      ) {
        this.load(this.appToNavigationMap.get(appName));
      }
    }
  }

  private onTransitionSuccess(trans: Transition) {
    const toState = trans.to();

    this.log.trackPageView(toState.name);
    const params = trans.params();

    // Сбросить команды панели действий.
    if (params?.keepMainActions !== true) {
      this.actions.reset();
    }

    this.destroyTempItems(toState.name);

    // Move to On Start after migration.

    if (params?.navigation) {
      this.selectNavigationItemBy({ name: params.navigation });
    } else {
      this.selectNavigationItemBy({ state: toState.name });
    }
    this.navigationSubject.next(this.currentNavigation);

    if (params?.routeMode !== RouteMode.keep) {
      // Set the route from as basic + param.
      this.route = this.getRouteBasis() ?? [];

      if (params?.route?.length) {
        const restoredRoute = cloneDeep(params.route);

        restoredRoute.forEach((segment, index) => {
          if (segment?.state?.params) {
            segment.state.params['route'] = cloneDeep(
              restoredRoute.slice(0, index + 1),
            );

            segment.state.params['navigation'] =
              this.selectedNavigationItem.name;
          }
        });

        this.route = this.route.concat(restoredRoute);
      }

      this.propagateRoute();
    } else {
      // Update last segment.
      const newParams = cloneDeep(this.routerGlobals.params);
      delete newParams.routeMode;
      this.route[this.route.length - 1].state = {
        name: this.routerGlobals.current.name,
        params: newParams,
      };
    }
  }

  private getPreviousState(): { name: string; params: any } {
    if (this.route?.length < 3) {
      return null;
    }

    for (let index = this.route.length - 2; index >= 0; index--) {
      if (this.route[index].state) {
        return this.route[index].state;
      }
    }

    return null;
  }

  /**
   * Выполняет загрузку индикаторов для текущей навигации.
   */
  public updateIndicators() {
    if (!this.currentNavigation) {
      return;
    }

    this.log.debug('Updating indicators...');

    this.currentNavigation.groups.forEach((group) => {
      group.items.forEach((item) => {
        if (item.indicator?.list) {
          const view = item.indicator.list.views.find(
            (v) => v.name === item.indicator.viewName,
          );

          const transform: any = { filter: null };

          if (view.contextFilter) {
            transform.filter = JSON.parse(
              JSON.stringify(view.contextFilter).replaceAll(
                '#user',
                this.app.session.user.id,
              ),
            );
          }

          const query =
            (buildQuery({ transform }) as string).substring(8) +
            '/aggregate($count as count)';

          this.data
            .collection(item.indicator.list.dataCollection)
            .query<Dictionary<number>[]>(null, { $apply: query })
            .subscribe((data) => {
              const value: number = data.length === 0 ? 0 : data[0]['count'];

              this.log.debug(
                `Indicator for ${item.name} has been updated to ${value}.`,
              );
              this.indicatorSubject.next({ name: item.name, value });
              this.indicatorValues.set(item.name, value);
            });
        }
      });
    });
  }

  /** Gets current App (depends on navigation). */
  public getAppName(navigationName?: string) {
    if (!navigationName) {
      if (!this.currentNavigation) {
        // Backward compatibility
        return AppName.My;
      }
      navigationName = this.currentNavigation.name;
    }

    const firstSegment = navigationName.substring(
      0,
      navigationName.indexOf('.') > -1
        ? navigationName.indexOf('.')
        : navigationName.length,
    );
    return AppName[
      Object.keys(AppName).find((k) => AppName[k] === firstSegment)
    ];
  }

  private stopIndicatorUpdating() {
    clearInterval(this.indicatorsTimer);
  }

  private startIndicatorUpdating() {
    this.updateIndicators();
    this.indicatorsTimer = setInterval(() => {
      this.updateIndicators();
    }, this.indicatorsCheckingTime);
  }
}

export interface RouteItem {
  id?: string;
  title?: string;
  localTitle?: string;
  state?: {
    name: string;
    params: object;
  };
}

export interface EntityFilter {
  /** Title of entity (aka document) */
  name: string;
  /** Filter query */
  filter: {
    [key: string]: {
      type: string;
      value: any;
    };
  }[];
}
