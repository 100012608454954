import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import _ from 'lodash';

@Component({
  selector: 'wp-custom-field-creation',
  templateUrl: './custom-field-creation.component.html',
})
export class CustomFieldCreationComponent {
  isSaving = false;
  form: UntypedFormGroup;

  public types: NamedEntity[] = [];
  public entityTypes: NamedEntity[] = [];

  constructor(
    fb: UntypedFormBuilder,
    translate: TranslateService,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {
    this.form = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      entityType: [null, [Validators.required]],
      type: [null, [Validators.required]],
    });

    // Заполнить список типов дополнительных полей.
    for (const key of Object.keys(CustomFieldType)) {
      this.types.push({
        name: translate.instant('enums.customFieldType.' + key),
        id: _.upperFirst(key),
      });
    }
    this.types.sort(naturalSort('name'));

    // Заполнить список типов сущностей.
    for (const key of Object.keys(CustomFieldEntityType)) {
      this.entityTypes.push({
        name: translate.instant('enums.customFieldEntityType.' + key),
        id: key,
      });
    }
    this.entityTypes.sort(naturalSort('name'));
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    if (
      this.form.value.entityType.id === CustomFieldEntityType.TimeSheetLine &&
      this.form.value.type.id !== CustomFieldType.string
    ) {
      this.notification.warningLocal(
        'settings.customFields.creation.messages.typesForLine',
      );
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.form.value.name,
      type: this.form.value.type.id,
      entityType: this.form.value.entityType.id,
    };

    this.data
      .collection('CustomFields')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.customFields.creation.messages.created',
          );
          this.state.go('settings.customField', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
