import { Component, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ListService } from 'src/app/shared/services/list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LineModalComponent } from './line-modal/line-modal.component';
import { ExpenseRequestCardService } from '../expenses-request-card.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { ExpensesRequestDetailsToolbarComponent } from 'src/app/expenses-requests/card/details/toolbar/expenses-request-details-toolbar.component';
import { takeUntil } from 'rxjs/operators';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { AttachmentsManagerService } from 'src/app/shared-features/comments/core/attachments-manager.service';
import { CommentsDataService } from 'src/app/shared-features/comments/core/comments-data.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

/**
 * Represents Expense request Card lines content.
 * */
@Component({
  selector: 'tmt-expenses-request-details',
  templateUrl: './expenses-request-details.component.html',
  providers: [AttachmentsManagerService, CommentsDataService],
})
export class ExpensesRequestDetailsComponent implements OnInit {
  @Input() entityId: string;
  @Input() lines: UntypedFormArray;
  @Input() readonly: boolean;

  /** Grid properties. */
  public gridOptions: Grid2Options = {
    resizableColumns: true,
    toolbar: ExpensesRequestDetailsToolbarComponent,
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonly,
        handlerFn: () => this.createLine(),
      },
      {
        name: 'edit',
        allowedFn: (id: string) => !!id,
        handlerFn: (group: UntypedFormGroup) => this.openLineModal(group),
      },
      {
        name: 'setUserView',
        allowedFn: () => true,
        handlerFn: () => this.setUserView(),
      },
      {
        name: 'openAttachment',
        allowedFn: () => true,
        handlerFn: (params: any) =>
          this.cardService.openAttachment(params.lineId, params.fileName),
      },
      {
        name: 'uploadAttachment',
        allowedFn: () => !this.readonly,
        handlerFn: (param: { formGroup: UntypedFormGroup; file: FileList }) => {
          this.autosave.save().then(
            () => {
              this.cardService
                .uploadAttachment(param.formGroup.value.id, param.file)
                .then(
                  (attachment: any) => {
                    param.formGroup.controls.attachment.setValue(attachment);
                    this.gridService.detectChanges();
                  },
                  () => null,
                );
            },
            () => null,
          );
        },
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.openLineModal(formGroup),
      },
      {
        name: 'copy',
        label: 'shared.actions.copy',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) => this.copyLine(formGroup),
      },
      {
        name: 'removeAttachment',
        label: 'shared.actions.removeAttachment',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly && formGroup.value.attachment,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.removeAttachment(formGroup),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.lines.removeAt(index);
          this.lines.parent.markAsDirty();
          this.afterActionComplete();
          this.gridService.detectChanges();
        },
      },
    ],
    view: this.listService.getGridView(),
  };

  constructor(
    private injector: Injector,
    private autosave: SavingQueueService,
    private cardService: ExpenseRequestCardService,
    private listService: ListService,
    private notification: NotificationService,
    private gridService: GridService,
    private modalService: NgbModal,
    private customFieldService: CustomFieldService,
  ) {}

  ngOnInit(): void {
    this.cardService.reimbursementCurrencyChanged$
      .pipe(takeUntil(this.cardService.entityReloaded$))
      .subscribe((currency) => {
        this.lines.controls.forEach((control) => {
          const group = control as UntypedFormGroup;
          group.controls.amountRC.setValue({
            value: 0,
            currencyCode: currency.alpha3Code,
          });
          this.cardService.refreshReimbursementXR(group, false, currency.id);
        });
        this.lines.controls.forEach((group) => {
          this.cardService.refreshProjectXR(group as UntypedFormGroup);
        });
      });
  }

  /**
   * Removes Expense request line attachment.
   * */
  private removeAttachment(formGroup: UntypedFormGroup) {
    this.cardService.removeAttachment(formGroup.value.id).then(() => {
      formGroup.controls.attachment.setValue(null);
      this.gridService.detectChanges();
    });
  }

  /**
   * Updates User view settings.
   * Reloads Expense request card.
   * */
  public setUserView() {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.cardService.reload();
      },
      () => null,
    );
  }

  /**
   * Creates New Expense request line.
   * */
  private createLine() {
    this.cardService
      .getMainTaskObs()
      .pipe(takeUntil(this.cardService.entityReloaded$))
      .subscribe({
        next: (task: NamedEntity) => {
          const group = this.cardService.getLineGroup();
          group.controls.projectTask.setValue(task);

          this.customFieldService.enrichFormGroupWithDefaultValues(
            group,
            CustomFieldEntityType.ExpenseRequestLine,
          );
          this.lines.push(group);
          this.gridService.selectGroup(group);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Opens Expense request line modal window to View/Edit.
   *
   * @param lineGroup Expense request line Form group.
   * */
  private openLineModal(lineGroup: UntypedFormGroup) {
    this.autosave.save().then(() => {
      const ref = this.modalService.open(LineModalComponent, {
        injector: this.injector,
      });
      const instance = ref.componentInstance as LineModalComponent;
      instance.readonly = this.readonly;
      instance.line = this.cardService.lineCardBuilderFn(
        lineGroup.getRawValue(),
      );

      ref.result.then(
        (updatedFormGroup) => {
          if (!this.readonly) {
            lineGroup.patchValue(updatedFormGroup.getRawValue());
            this.gridService.detectChanges();
            this.afterActionComplete();
          }
        },
        (attachment: any) => {
          if (!this.readonly) {
            lineGroup.controls.attachment.setValue(attachment);
            this.gridService.detectChanges();
          }
        },
      );
    });
  }

  /**
   * Copies Expense request line.
   *
   * @param lineGroup Expense request line Form group to copy.
   * */
  private copyLine(lineGroup: UntypedFormGroup) {
    this.autosave.save().then(() => {
      const line = this.cardService.lineCardBuilderFn(lineGroup.getRawValue());
      const group = this.cardService.getLineGroup(line);

      group.controls.id.setValue(Guid.generate());
      group.controls.attachment.setValue(null);

      this.lines.push(group);
      this.gridService.selectGroup(group);
      this.afterActionComplete();
    });
  }

  private afterActionComplete() {
    this.cardService.calculateTotals();
  }
}
