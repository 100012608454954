<tmt-form-header [name]="project?.name" [isEditable]="project?.editAllowed" [saveFn]="saveName">
</tmt-form-header>

<tmt-not-found [state]="service.state$ | async" [urlMustContain]="entityId"></tmt-not-found>
<wp-loading-indicator [state]="service.state$ | async"></wp-loading-indicator>

<ng-container *ngIf="(service.state$ | async) === 'Ready'">
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator></wp-state-indicator>
      </div>

      <div *ngIf="project?.manager" class="subheader__prop">
        <div class="subheader__label">
          {{ 'projects.projects.settings.props.manager.label' | translate }}:
        </div>

        <div id="manager" class="subheader__value">
          <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
            {{ project?.manager.name }}
          </button>
        </div>
      </div>

      <div *ngIf="project?.organization" class="subheader__prop">
        <div class="subheader__label">
          {{ 'projects.projects.settings.props.client.label' | translate }}:
        </div>
        <div class="subheader__value">
          <a uiSref="client" [uiParams]="{ entityId: project.organization.id }">{{
            project.organization.name
          }}</a>
        </div>
      </div>

      <div
        *ngIf="project?.externalUrl"
        style="float: left; margin-right: 25px; color: var(--bs-text-minor)"
      >
        <a
          title="{{ 'projects.projects.settings.props.externalUrl' | translate }}"
          href="{{ project.externalUrl }}"
          target="_blank"
          ><span class="bi bi-box-arrow-up-right text-gray"></span
        ></a>
      </div>

      <div class="subheader__prop">
        <tmt-workflow-indicator></tmt-workflow-indicator>
      </div>
    </div>

    <div class="subheader__control">
      <div class="btn-group" style="float: right" role="group">
        <div *ngIf="project.versionViewAllowed" class="project-version-controls">
          <wp-project-versions-box
            [createVersionButtonDisabled]="
              !project.versionEditAllowed ||
              !projectVersionCtrl.value ||
              (versionService.projectVersionUpdating$ | async)
            "
            [formControl]="projectVersionCtrl"
            [values]="projectVersions"
            [allowNull]="false"
            [readonly]="versionService.projectVersionUpdating$ | async"
            [placeholder]="'projects.projects.card.info.projectVersion' | translate"
            [workProjectVersion]="this.versionCardService.workProjectVersion"
          ></wp-project-versions-box>
        </div>
      </div>
    </div>
  </div>
  <div style="position: relative">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a
          class="nav-link"
          [ngClass]="{ active: (uiRouterGlobals.current?.name ?? '').indexOf(tab.state) > -1 }"
          [uiSref]="tab.state"
          [uiParams]="{ routeMode: 'keep' }"
        >
          {{ tab.header | translate }}
        </a>
      </li>
    </ul>

    <div ngbDropdown class="d-inline-block settings-menu">
      <button type="button" class="btn btn-link" ngbDropdownToggle>
        {{ 'projects.projects.card.settingsMenu.label' | translate }}
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem (click)="openSettings()">
          {{ 'projects.projects.card.settingsMenu.settings' | translate }}
        </button>
        <button *ngIf="project?.coManagersViewAllowed" ngbDropdownItem (click)="openCoManagers()">
          {{ 'projects.projects.card.settingsMenu.coManagers' | translate }}
        </button>
      </div>
    </div>

    <ui-view></ui-view>
  </div>
</ng-container>
