import { cloneDeep, sortBy } from 'lodash';
import { Injectable } from '@angular/core';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { TransitionService } from '@uirouter/angular';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import { DataService } from 'src/app/core/data.service';
import { ProjectTaskSettings } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/project-task-settings.model';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectPlanningMode } from 'src/app/shared/models/entities/projects/project-planning-mode.enum';

/** Сервис реализует команды управления задачами проекта. */
@Injectable()
export class ProjectTasksCommandsService {
  constructor(
    private transitionService: TransitionService,
    private localConfigService: LocalConfigService,
    private customFieldService: CustomFieldService,
    private projectCardService: ProjectCardService,
  ) {
    const settings = this.localConfigService.getConfig(ProjectTaskSettings);
    this.transitionService.onSuccess({}, (trans) => {
      const state = trans.targetState().identifier();

      if (state.toString().includes('project.tasks')) {
        settings.toggler = trans.targetState().name();
        this.localConfigService.setConfig(ProjectTaskSettings, settings);
      }
    });
  }

  /** Повысить уровень задачи. */
  public increaseLevel(tasks: ProjectTask[], taskId: string) {
    const task = tasks.find((t) => t.id === taskId);

    if (!task.leadTaskId || task.indent <= 1) {
      return;
    }

    const taskLead = tasks.find((t) => t.id === task.leadTaskId);

    // Пересчет номеров текущей ветки (-1 все что ниже).
    let upTasks = tasks.filter((t) => t.leadTaskId === task.leadTaskId);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < upTasks.length; i++) {
      if (upTasks[i].number > task.number) {
        tasks.find((t) => t.id === upTasks[i].id).number--;
      }
    }

    // Пересчет новой ветки текущей ветки (+1 все что ниже).
    const downTasks = tasks.filter((t) => t.leadTaskId === taskLead.leadTaskId);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < downTasks.length; i++) {
      if (downTasks[i].number > taskLead.number) {
        tasks.find((t) => t.id === downTasks[i].id).number++;
      }
    }

    task.leadTaskId = taskLead.leadTaskId;
    task.number = taskLead.number + 1;
    task.indent--;

    const taskForIndentIncrease = tasks.filter((t) => t.leadTaskId === task.id);
    this.changeLevel(taskForIndentIncrease, -1, tasks);

    // Перерасчет структурных номеров
    upTasks = tasks.filter((t) => t.leadTaskId === taskLead.leadTaskId);

    this.updateStructNumbers(upTasks, tasks);
  }

  /** Понизить уровень задачи. */
  public decreaseLevel(tasks: ProjectTask[], taskId: string) {
    const task = tasks.find((t) => t.id === taskId);

    if (!task.leadTaskId || task.number <= 0 || task.indent <= 0) {
      return;
    }

    const taskAbove = tasks.find(
      (t) => t.number === task.number - 1 && t.leadTaskId === task.leadTaskId,
    );
    const maxNumber = this.maxNumberInLevel(taskAbove.id, tasks);
    task.indent++;
    task.leadTaskId = taskAbove.id;
    task.number = maxNumber + 1;

    let upTasks = tasks.filter((t) => t.leadTaskId === task.id);
    this.changeLevel(upTasks, 1, tasks);

    // Пересчет номеров предыдущей ветки.
    upTasks = tasks.filter((t) => t.leadTaskId === taskAbove.leadTaskId);
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < upTasks.length; i++) {
      if (upTasks[i].number > taskAbove.number) {
        tasks.find((t) => t.id === upTasks[i].id).number--;
      }
    }

    // Перерасчет структурных номеров.
    upTasks = tasks.filter((t) => t.leadTaskId === taskAbove.leadTaskId);
    this.updateStructNumbers(upTasks, tasks);
  }

  /** Переместить задачу вверх в списке. */
  public up(tasks: ProjectTask[], taskId: string) {
    const task = tasks.find((t) => t.id === taskId);

    if (!task.leadTaskId || task.number <= 0) {
      return;
    }

    const taskAbove = tasks.find(
      (t) => t.number === task.number - 1 && t.leadTaskId === task.leadTaskId,
    );
    task.number--;
    taskAbove.number++;

    // Перерасчет структурных номеров
    const upTasks = tasks.filter((t) => !t.leadTaskId);
    this.updateStructNumbers(upTasks, tasks);
  }

  /** Переместить задачу вниз в списке. */
  public down(tasks: ProjectTask[], taskId: string): ProjectTask {
    const task = tasks.find((t) => t.id === taskId);

    const maxNumber = this.maxNumberInLevel(task.leadTaskId, tasks);

    if (!task.leadTaskId || maxNumber < 1 || task.number === maxNumber) {
      return;
    }

    const taskBelow = tasks.find(
      (t) => t.number === task.number + 1 && t.leadTaskId === task.leadTaskId,
    );
    task.number++;
    taskBelow.number--;

    // Перерасчет структурных номеров.
    const upTasks = tasks.filter((t) => !t.leadTaskId);
    this.updateStructNumbers(upTasks, tasks);

    return taskBelow;
  }

  /** Возвращает максимальный номер задачи в конкретной ветке. */
  public maxNumberInLevel(leadTaskId: string, tasks: ProjectTask[]) {
    const tasksInLevel = tasks.filter((t) => t.leadTaskId === leadTaskId);
    let max = -1;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < tasksInLevel.length; i++) {
      if (tasksInLevel[i].number > max) {
        max = tasksInLevel[i].number;
      }
    }
    return max;
  }

  /** Обновление структурных номеров в ветке. */
  public updateStructNumbers(tasks: ProjectTask[], allTask: ProjectTask[]) {
    const orderedTasks = sortBy(tasks, ['number']);
    let i = 0;
    orderedTasks.forEach((task) => {
      i++;
      let number: string;
      if (task.leadTaskId) {
        const leadTask = allTask.find((t) => t.id === task.leadTaskId);

        if (leadTask.structNumber) {
          number = leadTask.structNumber + '.' + i;
        } else {
          number = i.toString();
        }
      } else {
        number = '';
      }

      const sTask = allTask.find((t) => t.id === task.id);
      sTask.structNumber = number;

      // Ищем подчиненные задачи и отправляем их на обновление.
      const childTasks = allTask.filter((t) => t.leadTaskId === task.id);
      this.updateStructNumbers(childTasks, allTask);
    });
  }

  public changeLevel(
    tasks: ProjectTask[],
    delta: number,
    allTask: ProjectTask[],
  ) {
    tasks.forEach((task) => {
      const sTask = allTask.find((t) => t.id === task.id);
      sTask.indent = sTask.indent + delta;

      // Ищем подчиненные задачи и отправляем их на обновление.
      const childTasks = allTask.filter((t) => t.leadTaskId === task.id);
      this.changeLevel(childTasks, delta, allTask);
    });
  }

  /** Проверка доступности команды. */
  public isCommandAllowed(
    command: string,
    taskId: string,
    tasks: ProjectTask[],
  ): boolean {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const getMaxIndent = (task: ProjectTask) => {
      let max = task.indent;
      const children = tasks.filter((t) => t.leadTaskId === task.id);

      if (children.length === 0) {
        return max;
      }

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < children.length; i++) {
        const elMax = getMaxIndent(children[i]);
        if (max < elMax) {
          max = elMax;
        }
      }

      return max;
    };

    const task: ProjectTask = taskId
      ? tasks.find((t) => t.id === taskId)
      : null;

    switch (command) {
      case 'delete':
        return !!(task && task.leadTaskId);

      case 'up':
        return task && task.leadTaskId && task.number > 0;

      case 'down':
        return (
          task &&
          task.leadTaskId &&
          task.number < this.maxNumberInLevel(task.leadTaskId, tasks)
        );

      case 'increaseLevel':
        return (
          task &&
          task.leadTaskId &&
          task.indent > 1 &&
          !(task['plannedHoursSum'] > 0)
        );

      case 'decreaseLevel':
        return (
          task &&
          task.leadTaskId &&
          task.indent > 0 &&
          task.number > 0 &&
          getMaxIndent(task) < 4 &&
          !(task['plannedHoursSum'] > 0)
        );

      case 'createChild':
        return task && task.indent < 4;
    }
    return false;
  }

  /** Создание новой задачи. */
  public create(
    currentTaskId: string,
    createChild: boolean,
    tasks: ProjectTask[],
  ): ProjectTask {
    const taskId = Guid.generate();

    const projectTaskAssignments = [];
    if (
      this.projectCardService.project.planningMode.id !==
      ProjectPlanningMode.automatic.id
    ) {
      projectTaskAssignments.push({
        id: Guid.generate(),
        isAllTeamRole: true,
        projectTaskId: taskId,
      });
    }

    const task: ProjectTask = {
      id: taskId,
      crossId: taskId,
      number: 0,
      structNumber: '',
      isExpanded: null,
      leadTaskId: null,
      indent: 0,
      name: '',
      description: '',
      startDate: null,
      endDate: null,
      duration: null,
      allowTimeEntry: true,
      actualCost: null,
      actualHours: null,
      isActive: false,
      assignmentsWereChanged: true,
      projectTaskAssignments,
      dependencies: [],
      type: null,
      effortDriven: false,
    };

    if (createChild) {
      const currentTask = tasks.find((t) => t.id === currentTaskId);
      const maxNumber = this.maxNumberInLevel(currentTaskId, tasks);
      task.indent = currentTask.indent + 1;
      task.number = maxNumber + 1;
      task.leadTaskId = currentTaskId;
      task.startDate = currentTask.startDate;
      task.endDate = currentTask.startDate;
    } else {
      const mainTask = tasks.find((t) => !t.leadTaskId);
      if (!currentTaskId || currentTaskId === mainTask.id) {
        const maxNumber = this.maxNumberInLevel(mainTask.id, tasks);
        task.indent = 1;
        task.number = maxNumber + 1;
        task.leadTaskId = mainTask.id;
        task.startDate = mainTask.startDate;
        task.endDate = mainTask.startDate;
      } else {
        const currentTask = tasks.find((t) => t.id === currentTaskId);
        task.number = currentTask.number + 1;

        // Задачи в ветке, в которую вставляем новую задачу.
        let upTasks = tasks.filter(
          (t) => t.leadTaskId === currentTask.leadTaskId,
        );
        upTasks = sortBy(upTasks, ['number']);

        upTasks.forEach((upTask) => {
          if (
            upTask.number >= currentTask.number &&
            upTask.id !== currentTask.id
          ) {
            upTask.number++;
          }
        });

        const leadTask = tasks.find((t) => t.id === currentTask.leadTaskId);
        task.leadTaskId = currentTask.leadTaskId;
        task.indent = currentTask.indent;
        task.startDate = leadTask.startDate;
        task.endDate = leadTask.startDate;
      }
    }

    const customFields = this.customFieldService.getList(
      CustomFieldEntityType.ProjectTask,
    );
    customFields.forEach((f) => {
      if (f.defaultValue) {
        switch (f.type) {
          case CustomFieldType.decimal:
            task[`${f.dataField}`] = parseFloat(f.defaultValue);
            task[`${f.dataField}Sum`] = parseFloat(f.defaultValue);
            break;
          case CustomFieldType.integer:
            task[`${f.dataField}`] = parseInt(f.defaultValue);
            task[`${f.dataField}Sum`] = parseInt(f.defaultValue);
            break;
          case CustomFieldType.lookup:
            task[`${f.dataField}`] = f.lookupDefaultValue;
            break;
          default:
            task[`${f.dataField}`] = f.defaultValue;
        }
      }
    });
    tasks.push(task);

    // Перерасчет структурных номеров.
    const updatingTasks = tasks.filter((t) => t.leadTaskId === task.leadTaskId);
    this.updateStructNumbers(updatingTasks, tasks);

    return task;
  }

  public copy(originalTaskId: string, tasks: ProjectTask[]): ProjectTask {
    const originalTask = tasks.find((t) => t.id === originalTaskId);
    const task = cloneDeep(originalTask);

    task.isExpanded = true;
    task.plannedHours = null;
    task.actualHours = null;
    task.plannedCost = null;

    if (task.projectTaskAssignments) {
      task.projectTaskAssignments.forEach((assignment) => {
        assignment.id = Guid.generate();
      });
    }

    task.id = Guid.generate();
    task.number = originalTask.number + 1;

    // Задачи в ветке, в которую вставляем новую задачу.
    let upTasks = tasks.filter((t) => t.leadTaskId === originalTask.leadTaskId);
    upTasks = sortBy(upTasks, ['number']);

    upTasks.forEach((upTask: ProjectTask) => {
      if (upTask.number >= task.number && upTask.id !== task.id) {
        upTask.number++;
      }
    });

    tasks.push(task);

    // Перерасчет структурных номеров.
    const updatingTasks = tasks.filter((t) => t.leadTaskId === task.leadTaskId);
    this.updateStructNumbers(updatingTasks, tasks);

    return task;
  }
}
