import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateProjectVersionModalComponent } from './create-project-version-modal/create-project-version-modal.component';
import { ProjectVersionsMergeModalModule } from './project-versions-merge-modal/project-versions-merge-modal.module';
import { ProjectVersionListComponent } from './list/project-version-list.component';
import { Transition, UIRouterModule } from '@uirouter/angular';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { PROJECT_VERSION_LIST } from 'src/app/shared/lists/project-version.list';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { ProjectVersionCardComponent } from './card/project-version-card.component';
import { ProjectVersionCardSharedModule } from './shared/project-version-card-shared.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { DataService } from 'src/app/core/data.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

@NgModule({
  declarations: [
    ProjectVersionListComponent,
    CreateProjectVersionModalComponent,
    ProjectVersionCardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'projectsVersions',
          url: '/project-versions/?{route}&{navigation}&{projectId:guid}',
          component: ProjectVersionListComponent,
          params: {
            routeHeader: 'projects.projectVersions.routeSegment',
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
            projectId: null,
          },
          resolve: [
            {
              token: 'projectId',
              deps: [Transition],
              resolveFn: StateHelper.resolveProjectId,
            },
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: PROJECT_VERSION_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'projectVersion',
                queryData: {
                  select: ['id', 'stateId', 'name'],
                },
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'projectVersion',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
            navigation: null,
          },
          url: '/project-versions/{entityId:guid}?{route}&{navigation}&{projectId:guid}',
          component: ProjectVersionCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
            {
              provide: 'projectId',
              deps: [Transition, DataService, BlockUIService],
              useFactory: StateHelper.resolveProjectIdForVersionForm,
            },
          ],
        },
      ],
    }),
    ProjectVersionCardSharedModule,
    ProjectVersionsMergeModalModule,
    BaseSharedModule,
    CommentsModule,
  ],
})
export class ProjectVersionsModule {}
