import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  inject,
  DestroyRef,
  TemplateRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { debounceTime, filter, first, tap } from 'rxjs/operators';
import { isFunction } from 'lodash';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

/** Контрол выбора пользователя. */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'wp-user-box',
  templateUrl: './user-box.component.html',
  styleUrls: ['./user-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
})
export class UserBoxComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor
{
  @ViewChild('expandingArea') private expandingArea: TemplateRef<HTMLElement>;

  public resourceType = ResourceType;
  public loadLimit = 50;
  private collection = 'Users';

  /** Заменить пустого текста. */
  @Input() placeholder: string;

  /** Признак режима только-чтение. */
  @Input() readonly: boolean;

  /** Допустить очистку значения. */
  @Input() allowNull = true;

  /** Допустить выбор (с сервера) неактивных сущностей. */
  @Input() allowInactive = false;

  /** Запрос (OData Query) к источнику (дополнительная фильтрация или запрос данных). */
  @Input() query: any = null;

  /** Select input text and open expanding area after rendering. */
  @Input() autofocus?: boolean;

  @Input() users?: NamedEntity[];

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  @ViewChild('input') inputEl: ElementRef;

  private _value: any = null;
  set value(obj: any) {
    this._value = obj;
    this.textControl.setValue(obj ? obj.name : '', { emitEvent: false });
    this.textControl.markAsPristine();
  }
  get value(): any {
    return this._value;
  }

  rows: any[] = [];
  listOpened = false;
  isLoading = false;
  loadedPartly = false;
  selectedRow: any;
  textControl = new UntypedFormControl('');

  /** Property for opens list without inputs text selecting by keyboard key init event. */
  private openListWithInputSelect = true;
  private popupId: string;
  private readonly destroyRef = inject(DestroyRef);

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  constructor(
    private scrollToService: ScrollToService,
    private element: ElementRef<HTMLElement>,
    private data: DataService,
    private ref: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
  ) {}

  public ngOnInit(): void {
    this.textControl.valueChanges
      .pipe(
        tap((v) => {
          if (!v?.length && this.allowNull) {
            this.changeValue(null);
          }
        }),
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!this.listOpened) {
          this.openList();
        }
        this.refreshRows();
      });

    this.infoPopupService.event$
      .pipe(
        filter((e) => e.name === 'destroy' && e.popup?.id === this.popupId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.listOpened = false;
        this.selectedRow = null;
      });
  }

  public ngAfterViewInit(): void {
    if (this.inputEl && this.autofocus) {
      this.openList();
    }

    this.applyInitialValue();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['users']) {
      this.refreshRows();
    }
  }

  /** Apply initial value after rendering. */
  private applyInitialValue() {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.inputEl) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.textControl.setValue(this.initialValue);
        this.inputEl.nativeElement.value = this.initialValue;
        this.openListWithInputSelect = false;
        this.inputEl.nativeElement.focus();
        this.inputEl.nativeElement.dispatchEvent(event);
      } else if (this.initialValue === null) {
        this.textControl.setValue('');
        this.inputEl.nativeElement.value = '';
        this.inputEl.nativeElement.focus();
        this.inputEl.nativeElement.dispatchEvent(event);
      }
    }
    this.initialValue = undefined;
  }

  private changeValue(value: any) {
    if (
      !(
        this._value === value ||
        (this._value && value && this._value.id === value.id)
      )
    ) {
      this.propagateChange(value);
    }
    this.value = value;
  }

  writeValue(value: any): void {
    this.value = value;
    this.ref.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.ref.detectChanges();
  }

  onBlur() {
    this.propagateTouch();
  }

  loadStoredRows(): Observable<any[]> {
    if (this.users) {
      return of(
        this.textControl.value.trim() && this.textControl.dirty
          ? this.users.filter((u) =>
              u.name
                .toLowerCase()
                .includes(this.textControl.value.toLowerCase().trim()),
            )
          : this.users,
      );
    }

    this.isLoading = true;
    this.ref.detectChanges();

    const query = this.query
      ? isFunction(this.query)
        ? this.query()
        : this.query
      : null;

    return new Observable<any[]>((subscribe) => {
      const dataParams: any = {
        top: this.loadLimit,
        select: ['id', 'name'],
        filter: <any>[],
        orderBy: 'name',
      };

      if (!this.allowInactive) {
        dataParams.filter.push({ isActive: true });
      }

      if (query) {
        if (query.filter) {
          dataParams.filter = dataParams.filter.concat(query.filter);
        }
      }
      if (this.textControl.value.trim() && this.textControl.dirty) {
        dataParams.filter.push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'tolower(name)': {
            contains: (this.textControl.value as string).trim().toLowerCase(),
          },
        });
      }

      this.data
        .collection(this.collection)
        .query<object[]>(dataParams)
        .subscribe((data) => {
          this.isLoading = false;
          this.loadedPartly =
            this.loadLimit &&
            data.length === this.loadLimit &&
            data.length !== 0;
          subscribe.next(data);
        });
    });
  }

  refreshRows() {
    this.loadStoredRows()
      .pipe(first())
      .subscribe((data: any[]) => {
        this.rows = data;
        if (this.value) {
          this.selectRow(this.rows.find((row) => row.id === this.value.id));
        }
        this.infoPopupService.update(this.popupId);
        firstValueFrom(
          this.infoPopupService.event$.pipe(filter((e) => e.name === 'update')),
        ).then(() => {
          this.scrollToSelectRow();
        });
        this.ref.detectChanges();
      });
  }

  selectRow(row: any) {
    this.selectedRow = row;
  }

  clickRow(row: any) {
    this.changeValue(row);
    this.closeList();
  }

  onKeyDown(event: KeyboardEvent) {
    const escCode = 27;
    const enterCode = 13;
    const downCode = 40;
    const upCode = 38;

    if (event.keyCode === escCode) {
      this.cancel();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === enterCode) {
      // Очистили текст, нажали Enter (но нет активной записи) = убрали выбор.
      if (!this.textControl.value && !this.selectedRow) {
        if (this.allowNull) {
          this.changeValue(null);
        }
        this.cancel();
        return;
      }

      if (this.selectedRow) {
        this.clickRow(this.selectedRow);
      }
    }

    if (event.keyCode === downCode) {
      this.selectNext();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === upCode) {
      this.selectPrevious();
      event.preventDefault();
      event.stopPropagation();
    }

    this.ref.detectChanges();
  }

  // Команда выделения следующего элемента.
  selectNext() {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index < this.rows.length - 1) {
        this.selectedRow = this.rows[index + 1];
        this.scrollToSelectRow();
      }
    }
  }

  // Команда выделения предыдущего элемента
  selectPrevious() {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index > 0) {
        this.selectedRow = this.rows[index - 1];
        this.scrollToSelectRow();
      }
    }
  }

  scrollToSelectRow() {
    if (this.selectedRow && this.listOpened) {
      this.scrollToService.scrollTo(this.selectedRow.id, 'selecting-list');
    }
  }

  onInputClick() {
    if (!this.listOpened) {
      this.openList();
    }
  }

  clear() {
    this.changeValue(null);
    this.closeList();
  }

  closeList() {
    this.listOpened = false;
    this.selectedRow = null;
    this.infoPopupService.close(this.popupId);
    this.ref.detectChanges();
  }

  openList() {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.selectedRow = null;
    if (this.openListWithInputSelect) {
      this.inputEl.nativeElement.select();
    } else {
      this.inputEl.nativeElement.focus();
      this.openListWithInputSelect = true;
    }
    this.propagateTouch();
    this.listOpened = true;

    this.popupId = this.infoPopupService.open({
      target: this.element.nativeElement,
      data: {
        templateRef: this.expandingArea,
      },
      containerStyles: null,
      placement: 'bottom',
      isHideArrow: true,
      popperModifiers: this.infoPopupService.controlPopperModifiers,
    });

    this.refreshRows();
  }

  cancel() {
    this.closeList();
    this.textControl.markAsPristine();

    if (this.textControl.value && this.value) {
      this.textControl.setValue(this.value.name, { emitEvent: false });
    } else {
      if (this.allowNull) {
        this.changeValue(null);
      } else {
        // Если запрещено "не выбрать", но выбора еще не было - то ничего не делаем.
        if (this.value) {
          this.textControl.setValue(this.value.name, { emitEvent: false });
        }
      }
    }
  }
}
