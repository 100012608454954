import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import {
  StateBuilderService,
  WorkflowEntityState,
} from 'src/app/core/state-builder.service';
import { NavigationService } from 'src/app/core/navigation.service';

import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

import { BoardService } from 'src/app/boards/services/board.service';
import { BoardCardView } from 'src/app/boards/models/board.interface';

@Component({
  selector: 'tmt-board-mini-card',
  templateUrl: './board-mini-card.component.html',
  styleUrl: './board-mini-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardMiniCardComponent implements OnInit {
  @Input() public card: BoardCardView;
  @Input() public index: number;

  public stateForEntity: WorkflowEntityState;

  private destroyRef = inject(DestroyRef);

  constructor(
    public stateBuilderService: StateBuilderService,
    public boardService: BoardService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (this.card.entity?.type) {
      this.stateForEntity = this.stateBuilderService.getStateForWorkflowEntity(
        this.card.entity.id,
        this.card.entity.type,
        this.card.entity.id,
      );
    } else {
      this.stateForEntity = {
        state: this.stateBuilderService.entityTypeToStateName.get(
          this.boardService.config.entityType,
        ),
        params: {
          entityId: this.card.entity.id,
          routeMode: RouteMode.continue,
          navigation: this.navigationService.selectedNavigationItem?.name,
        },
      };
    }

    this.boardService.event$
      .pipe(
        filter(
          (event) =>
            event.target === 'card' &&
            (!event?.id || event?.id === this.card.id),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.card.actions.find(
          (v) => v.name === 'changeState',
        ).subActions.length = 0;
        this.cdr.markForCheck();
      });
  }

  /**
   * Runs custom function or redirects to another state.
   *
   * @param event MouseEvent.
   */
  public clickHandler(event: MouseEvent): void {
    if ((event.target as HTMLElement).tagName === 'A') {
      return;
    }

    if (this.card.onClick) {
      this.card.onClick();
    }
  }

  /**
   * Guesses type of property.
   *
   * @param property MetaEntityBaseProperty.
   * @returns type for template.
   */
  public guessTemplateType(property: MetaEntityBaseProperty): string {
    const propertyValue = this.card.entity[property.name];

    if (propertyValue === null) {
      return 'empty';
    }

    if (typeof propertyValue === 'string') {
      return 'string';
    }

    if (propertyValue?.id && propertyValue?.name) {
      return 'lookupValue';
    }

    return 'unknown';
  }
}
