import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, filter, startWith, takeUntil } from 'rxjs/operators';

import {
  CommentsService,
  FileWithId,
} from 'src/app/shared-features/comments/core/comments.service';
import { Comment } from 'src/app/shared-features/comments/model/comment.model';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';
import { AttachmentsManagerService } from 'src/app/shared-features/comments/core/attachments-manager.service';
import { Attachment } from 'src/app/shared-features/comments/model/attachment.model';

@Component({
  selector: 'tmt-comments-input',
  templateUrl: './comments-input.component.html',
  styleUrls: ['./comments-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsInputComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @Input() public comment: Comment | null;

  public isFocused = false;
  public isSaveButtonVisible: boolean;
  public commentTextControl: FormControl;
  public attachments: Attachment[] = [];
  public readonly maxSymbolLength = 2 ** 11;

  private readonly inputDelay = 100;
  private readonly destroyed$ = new Subject<void>();
  private inputFilesListener: () => void;

  constructor(
    public commentsService: CommentsService,
    public saveService: CommentsSaveService,
    public attachmentsManagerService: AttachmentsManagerService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    this.commentTextControl = this.comment
      ? new FormControl(this.comment.text ?? '')
      : this.commentsService.commentTextControl;

    this.attachmentsManagerService.attachmentDeleted$
      .pipe(
        filter((attachment) => attachment.commentId === this.comment?.id),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.isSaveButtonVisible =
      this.commentTextControl.getRawValue().length > 0 ||
      this.commentsService.commentAttachments.length > 0;
  }

  public ngAfterViewInit(): void {
    this.initInputSubscribers();

    if (this.comment) {
      this.attachments = this.comment.attachments;
      this.commentTextControl.setValue(this.comment.text);
      this.cdr.detectChanges();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public trackFileById(index: number, file: FileWithId): number | null {
    return file ? file.id : null;
  }

  /** Create or update comment (deletes comment if text's value and attachments are null */
  public save(): void {
    if (!this.comment) {
      this.saveService.addToQueue(this.commentsService.entityId);
      this.commentsService.createComment();
      return;
    }

    if (
      !this.commentTextControl.getRawValue() &&
      !this.comment.attachments.length
    ) {
      this.commentsService.deleteComment(this.comment.id, true);
      this.saveService.removeFromQueue(this.comment.id);
      return;
    }

    this.commentsService.editComment(
      this.comment.id,
      this.commentTextControl.getRawValue(),
    );

    this.commentsService.setActiveComment(null);
  }

  /**
   * Adds attachments to comment. If comment is exist, save attachment immediately.
   *
   * @param files FileList.
   *
   * */
  public async addAttachments(files: FileList): Promise<void> {
    if (!this.attachmentsManagerService.isValidFormat({ listFiles: files })) {
      return;
    }

    if (this.comment) {
      this.saveService.addToQueue(this.comment.id);
      await this.commentsService.updateCommentAttachments(this.comment, files);
      this.saveService.removeFromQueue(this.comment.id);
    } else {
      this.commentsService.addAttachments(files);
    }

    this.cdr.markForCheck();

    if (_.isFunction(this.inputFilesListener)) {
      this.inputFilesListener();
    }
  }

  /** File upload handler. */
  public attachFiles(): void {
    const input: HTMLInputElement = this.renderer.createElement('input');
    this.renderer.setAttribute(input, 'type', 'file');
    this.renderer.setAttribute(input, 'multiple', 'true');

    this.inputFilesListener = this.renderer.listen(
      input,
      'change',
      (event: Event) => {
        this.addAttachments((event.target as HTMLInputElement).files);
      },
    );

    input.click();
  }

  /** Changes to view state on cancel. */
  public undo(): void {
    this.commentsService.setActiveComment(null);
    this.saveService.removeFromQueue(this.comment.id);
  }

  /** `focused()` handler. */
  public onFocused(event: boolean): void {
    this.isFocused = event;
  }

  private initInputSubscribers(): void {
    if (this.comment) {
      this.commentTextControl.valueChanges
        .pipe(debounceTime(this.inputDelay), takeUntil(this.destroyed$))
        .subscribe((value) => {
          this.isSaveButtonVisible = value.length > 0;
          this.saveService.addToQueue(this.comment.id);
        });
    } else {
      combineLatest([
        this.commentTextControl.valueChanges.pipe(
          startWith(this.commentTextControl.getRawValue()),
        ),
        this.commentsService.commentAttachments$.pipe(
          startWith(this.commentsService.commentAttachments),
        ),
      ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([text, attachments]) => {
          this.isSaveButtonVisible = text.length > 0 || attachments.length > 0;

          if (text.length > 0 || attachments.length > 0) {
            this.saveService.addToQueue(this.commentsService.entityId);
            return;
          }

          this.saveService.removeFromQueue(this.commentsService.entityId);
        });
    }
  }
}
