<div
  wpFreezeTable
  class="disable-selecting"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
>
  <div name="toolbar">
    <div class="toolbar-container pt-0">
      <div class="group" *ngIf="!readonly">
        <button
          *ngIf="navigationService.getAppName() !== 'my'"
          type="button"
          class="btn btn-default"
          title="{{ 'timesheets.card.actions.addLine' | translate }}"
          (click)="addLine()"
          data-test="addLine"
        >
          <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{
            'timesheets.card.actions.addLine' | translate
          }}
        </button>

        <div class="btn-group" *ngIf="navigationService.getAppName() === 'my'">
          <button type="button" class="btn btn-default" (click)="addLine()" data-test="addLine">
            <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
              'timesheets.card.actions.addLine' | translate
            }}
          </button>
          <div class="btn-group" ngbDropdown role="group">
            <div>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button
                  type="button"
                  ngbDropdownItem
                  (click)="command.handlerFn()"
                  *ngFor="let command of addingCommands"
                  [attr.data-test]="command.name"
                >
                  {{ command.label | translate }}
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-default dropdown-toggle-split"
              ngbDropdownToggle
            ></button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="hasSelectedLines() && !readonly">
        <div class="group icons">
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.removeLines' | translate }}"
            (click)="removeLines()"
            data-test="removeLines"
          >
            <span class="bi bi-trash" aria-hidden="true"></span>
          </button>
        </div>
        <div class="group icons">
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.moveLinesUp' | translate }}"
            (click)="moveLinesUp()"
            data-test="moveLinesUp"
          >
            <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
          </button>
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.moveLinesDown' | translate }}"
            (click)="moveLinesDown()"
            data-test="moveLinesDown"
          >
            <i class="bi bi-arrow-bar-down" aria-hidden="true"></i>
          </button>
        </div>
      </ng-container>

      <div class="group">
        <button
          *ngIf="hasSelectedLines()"
          type="button"
          class="btn btn-default"
          title="{{ 'timesheets.card.actions.copyLinesToClipboard' | translate }}"
          (click)="copyLinesToStorage()"
          data-test="copy"
        >
          {{ 'shared.actions.copy' | translate }}
        </button>

        <button
          *ngIf="(hasLinesInStorage$ | async) && !readonly"
          type="button"
          class="btn btn-default icon"
          title="{{ 'timesheets.card.actions.pasteLinesToClipboard' | translate }}"
          (click)="pasteLinesFromStorage()"
          data-test="paste"
        >
          <i class="bi bi-clipboard" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <colgroup>
          <col />
          <col
            *ngIf="template?.showClient"
            [ngStyle]="{ width: columnsWidths.taskColumnWidth + 'px' }"
          />
          <col
            *ngIf="!template?.showClient"
            [ngStyle]="{ width: columnsWidths.taskColumnWidth + 'px' }"
          />
          <col
            *ngIf="template?.showActivity"
            [ngStyle]="{ width: columnsWidths.activityColumnWidth + 'px' }"
          />
          <col
            *ngIf="template?.showRole"
            [ngStyle]="{ width: columnsWidths.roleRateColumnWidth + 'px' }"
          />
          @if (template?.showProjectCostCenter) {
            <col [ngStyle]="{ width: columnsWidths.projectCostCenterColumnWidth + 'px' }" />
          }
          @if (template?.showTariff) {
            <col [ngStyle]="{ width: columnsWidths.projectTariffColumnWidth + 'px' }" />
          }
          <col
            *ngFor="let field of lineCustomFields"
            [ngStyle]="{ width: columnsWidths.customColumnWidth + 'px' }"
          />
        </colgroup>
        <thead>
          <tr>
            <th title="{{ 'timesheets.card.actions.selectAllLines' | translate }}" class="checkbox">
              <div class="wp-checkbox">
                <label>
                  <input type="checkbox" [formControl]="selectAllControl" />
                  <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                </label>
              </div>
            </th>
            <th
              *ngIf="template?.showClient"
              class="trim"
              title="{{ 'timesheets.card.columns.task.hint' | translate }}"
            >
              {{ 'timesheets.card.columns.task.header' | translate }}
            </th>
            <th
              *ngIf="!template?.showClient"
              class="trim"
              title="{{ 'timesheets.card.columns.taskWithoutClient.hint' | translate }}"
            >
              {{ 'timesheets.card.columns.taskWithoutClient.header' | translate }}
            </th>
            <th
              *ngIf="template?.showActivity"
              title="{{ 'timesheets.card.columns.activity.hint' | translate }}"
            >
              {{ 'timesheets.card.columns.activity.header' | translate }}
            </th>
            <th
              *ngIf="template?.showRole"
              title="{{ 'timesheets.card.columns.role.hint' | translate }}"
            >
              {{ 'timesheets.card.columns.role.header' | translate }}
            </th>
            @if (template?.showProjectCostCenter) {
              <th title="{{ 'timesheets.card.columns.projectCostCenter.hint' | translate }}">
                {{ 'timesheets.card.columns.projectCostCenter.header' | translate }}
              </th>
            }
            @if (template?.showTariff) {
              <th title="{{ 'timesheets.card.columns.projectTariff.hint' | translate }}">
                {{ 'timesheets.card.columns.projectTariff.header' | translate }}
              </th>
            }
            <th *ngFor="let field of lineCustomFields" title="{{ field.label }}" class="trim">
              {{ field.label }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <colgroup>
          <col />
          <col
            *ngIf="template?.showClient"
            [ngStyle]="{ width: columnsWidths.taskColumnWidth + 'px' }"
          />
          <col
            *ngIf="!template?.showClient"
            [ngStyle]="{ width: columnsWidths.taskColumnWidth + 'px' }"
          />
          <col
            *ngIf="template?.showActivity"
            [ngStyle]="{ width: columnsWidths.activityColumnWidth + 'px' }"
          />
          <col
            *ngIf="template?.showRole"
            [ngStyle]="{ width: columnsWidths.roleRateColumnWidth + 'px' }"
          />
          @if (template?.showProjectCostCenter) {
            <col [ngStyle]="{ width: columnsWidths.projectCostCenterColumnWidth + 'px' }" />
          }
          @if (template?.showTariff) {
            <col [ngStyle]="{ width: columnsWidths.projectTariffColumnWidth + 'px' }" />
          }
          <col
            *ngFor="let field of lineCustomFields"
            [ngStyle]="{ width: columnsWidths.customColumnWidth + 'px' }"
          />
        </colgroup>

        <tbody>
          <tr *ngIf="dataLines.controls.length === 0">
            <td [attr.colspan]="fixTableColumnCount" class="empty-information">
              <p *ngIf="!readonly">
                {{ 'timesheets.card.empty.empty' | translate | translateCut: 0 }}<br />
                <button class="btn btn-link" (click)="addLine()">
                  <span class="fw-semibold">{{
                    'timesheets.card.empty.empty' | translate | translateCut: 1
                  }}</span>
                </button>
                {{ 'timesheets.card.empty.empty' | translate | translateCut: 2 }}
                <button class="btn btn-link" (click)="copyLines()">
                  <span class="fw-semibold">{{
                    'timesheets.card.empty.empty' | translate | translateCut: 3
                  }}</span>
                </button>
                {{ 'timesheets.card.empty.empty' | translate | translateCut: 4 }}
              </p>
              <p *ngIf="readonly">
                {{ 'timesheets.card.empty.emptyInReadonly' | translate }}
              </p>
            </td>
          </tr>

          <tr *ngFor="let formGroup of dataLines.controls; index as index" [formGroup]="formGroup">
            <td class="checkbox">
              <div class="wp-checkbox">
                <label>
                  <input type="checkbox" [formControl]="selectControls.controls[index]" />
                  <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                </label>
              </div>
            </td>
            <td
              class="control"
              wpTimesheetTask
              formControlName="task"
              [showClient]="template?.showClient"
              [timesheetId]="service.timesheet?.id"
            ></td>
            <td
              class="control"
              *ngIf="template?.showActivity"
              wpTimesheetActivity
              formControlName="activity"
            ></td>
            <td
              class="control"
              *ngIf="service.timesheet?.template.showRole"
              wpTimesheetRole
              formControlName="role"
              [timesheetId]="service.timesheet?.id"
              [task]="formGroup.get('task').value"
            ></td>
            @if (service.timesheet?.template.showProjectCostCenter) {
              <td class="control">
                <tmt-timesheet-cost-center
                  formControlName="projectCostCenter"
                  [projectId]="formGroup.get('task').value?.project?.id"
                />
              </td>
            }
            @if (service.timesheet?.template.showTariff) {
              <td class="control">
                <tmt-timesheet-line-tariff
                  formControlName="projectTariff"
                  [projectId]="formGroup.get('task').value?.project?.id"
                />
              </td>
            }
            <td
              class="control"
              *ngFor="let field of lineCustomFields"
              wpTimesheetCustomField
              [field]="field"
              [formControlName]="field.clientDataField"
            ></td>
          </tr>

          <tr *ngIf="timeOffRequests?.length > 0">
            <th [attr.colspan]="fixTableColumnCount" class="time-off-header">
              {{ 'timesheets.card.timeOfRequestsSection' | translate }}
            </th>
          </tr>

          <tr *ngFor="let request of timeOffRequests; trackBy: trackId">
            <td
              [attr.colspan]="fixTableColumnCount"
              class="trim"
              style="text-align: left; padding-left: 8px; vertical-align: middle"
            >
              <a
                uiSref="timeOffRequest"
                [uiParams]="{
                  entityId: request.id,
                  routeMode: routeMode.continue,
                  navigation: navigationService.selectedNavigationItem?.name,
                  route: navigationService.storedRoute
                }"
                >{{ request.timeOffType.name }}</a
              >
              <tmt-state-badge class="ms-2" [state]="request.state"></tmt-state-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <tfoot>
          <tr>
            <td [attr.colspan]="fixTableColumnCount" style="vertical-align: middle"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div
    name="right"
    [ngStyle]="{ 'margin-left': fixTableWidth - 1 + 'px' }"
    style="width: fit-content"
  >
    <div name="scroll-table-header">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <thead>
          <tr>
            <th
              class="day"
              *ngFor="let day of days"
              [ngClass]="{
                'nonworking-day': day.isException,
                'current-day': day.isToday
              }"
              title="{{ day.hint }}"
            >
              {{ day.header }}
            </th>
            <th class="total">
              {{ 'timesheets.total' | translate }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <colgroup>
          <col *ngFor="let day of days" style="width: 47px" />
          <col />
        </colgroup>
        <tbody>
          <tr *ngIf="dataLines.controls.length == 0">
            <td [attr.colspan]="days.length + 1" style="height: 150px"></td>
          </tr>

          <tr
            *ngFor="let formGroup of dataLines.controls; index as lineIndex"
            [formGroup]="formGroup"
          >
            <ng-container formArrayName="allocations">
              <td
                *ngFor="
                  let allocation of $any(formGroup).controls.allocations.controls;
                  index as dayIndex
                "
                class="entry"
                [ngClass]="{ 'nonworking-day': days[dayIndex].isException }"
                wpTimesheetCell
                [formControlName]="dayIndex"
                [lineIndex]="lineIndex"
                [dayIndex]="dayIndex"
                [dayScheduleDuration]="days[dayIndex].schedule"
                [lineId]="formGroup.value.id"
                [attr.data-test-date]="days[dayIndex].date"
              ></td>
            </ng-container>
            <td class="total">
              {{ formGroup.value.totalHours | wpTimeDuration: totalSchedule : false }}
            </td>
          </tr>

          <tr *ngIf="timeOffRequests?.length > 0" style="height: 45px">
            <td [attr.colspan]="days.length + 1" class="time-off-header"></td>
          </tr>

          <tr *ngFor="let request of timeOffRequests; trackBy: trackId">
            <td
              class="readonly day-off"
              *ngFor="let day of days"
              [ngClass]="{ 'nonworking-day': day.isException }"
            >
              {{ getDayOffDuration(request, day) | wpTimeDuration: day.schedule : false }}
            </td>
            <td class="readonly day-off total">
              {{ geTimeOffRequestDuration(request) | wpTimeDuration: totalSchedule : false }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <tfoot>
          <tr>
            <td class="entry" *ngFor="let day of days">
              {{ day.totalHours | wpTimeDuration: day.schedule : false }}
            </td>
            <td class="total">
              {{ totalHours | wpTimeDuration: totalSchedule : false }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<wp-allocation-info></wp-allocation-info>
