import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { WorkflowTaskFilterComponent } from 'src/app/workflow-tasks/list/filter/workflow-task-filter.component';

@Injectable()
export class WorkflowTaskFilterService extends FilterService {
  public override component: Type<FilterDetails> = WorkflowTaskFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;
  public override hasAllowInactive = true;
  public override placeholder = 'expenses.list.filter.placeholder';

  public override getODataFilter(): any {
    const result: any[] = [];

    const text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      result.push(
        this.getTextFilter(['name', 'description'], this.getClearText(text)),
      );
    }

    if (this.values.created) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.created,
      );

      result.push({
        created: { ge: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        created: { le: { type: 'raw', value: dates.periodFinish } },
      });
    }

    if (this.values.performed) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.performed,
      );

      result.push({
        performed: { ge: { type: 'raw', value: dates.periodStart } },
      });

      result.push({
        performed: { le: { type: 'raw', value: dates.periodFinish } },
      });
    }

    if (this.values.assigned) {
      result.push({
        assignedId: { type: 'guid', value: this.values.assigned.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      created: null,
      performed: null,
      assigned: null,
      states: null,
    };
  }
}
