import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import {
  Client,
  OrganizationAddress,
} from 'src/app/shared/models/entities/projects/client.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { LogService } from 'src/app/core/log.service';

@Component({
  selector: 'wp-client-settings',
  templateUrl: './client-settings.component.html',
})
export class ClientSettingsComponent implements OnInit {
  @Input() clientId: string;
  public isSaving = false;
  public creatingMode: boolean;
  public readonly: boolean;
  public isLoading: boolean;

  public get client(): Client {
    return this.form.getRawValue() as Client;
  }

  public form = this.fb.group({
    id: [''],
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    manager: [null, [Validators.required]],
    billingEqualPost: [false],
    billingAddress: this.fb.group({
      address: null,
      city: null,
      postIndex: null,
      state: null,
    }),
    postAddress: this.fb.group({
      address: null,
      city: null,
      postIndex: null,
      state: null,
    }),
    contact: [''],
    contactEmail: [''],
    phone: [''],
    site: [''],
  });

  public okButtonText: string;

  constructor(
    private log: LogService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private app: AppService,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(
      this.form,
      CustomFieldEntityType.Organization,
    );
  }

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.log.debug('Saving client entity...');

    const okFunc = () => {
      this.isSaving = false;

      if (this.creatingMode) {
        this.notification.successLocal(
          'projects.clients.settings.messages.created',
        );
        this.state.go('client', { entityId: this.client.id });
      } else {
        this.notification.successLocal(
          'projects.clients.settings.messages.saved',
        );
      }

      this.activeModal.close(this.client);
    };

    const errorFunc = (error: Exception) => {
      this.notification.error(error.message);
      this.isSaving = false;
    };

    const client = this.client;

    const data = {
      id: client.id,
      name: client.name,
      code: client.code,
      isActive: client.isActive,
      managerId: client.manager.id,
      phone: client.phone,
      site: client.site,
      contact: client.contact,
      contactEmail: client.contactEmail,
      description: client.description,
      postAddress: client.postAddress,
      billingAddress: client.billingAddress,
      billingEqualPost: client.billingEqualPost,
    };

    this.customFieldService.assignValues(
      data,
      client,
      CustomFieldEntityType.Organization,
    );

    if (this.creatingMode) {
      this.data
        .collection('Organizations')
        .insert(data)
        .subscribe({ next: okFunc, error: errorFunc });
    } else {
      this.data
        .collection('Organizations')
        .entity(this.clientId)
        .update(data)
        .subscribe({ next: okFunc, error: errorFunc });
    }
  };

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /** Загрузка сведений о проекте. */
  public load() {
    this.isLoading = true;

    this.log.debug('Loading project...');
    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'phone',
        'site',
        'code',
        'contact',
        'contactEmail',
        'description',
        'postAddress',
        'billingAddress',
        'billingEqualPost',
        'editAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.customFieldService.enrichQuery(
      query,
      CustomFieldEntityType.Organization,
    );

    this.data
      .collection('Organizations')
      .entity(this.clientId)
      .get<Client>(query)
      .subscribe({
        next: (client) => {
          this.form.patchValue(client);
          this.readonly = !client.editAllowed;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly ? this.form.disable() : this.form.enable();
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  ngOnInit() {
    if (this.clientId) {
      this.load();

      this.creatingMode = false;
      this.okButtonText = this.translate.instant('shared.actions.save');
    } else {
      // Режим создания проекта.
      const client: Client = {
        id: Guid.generate(),
        manager: this.app.session.user,
        code: '',
        name: '',
        description: '',
        billingAddress: new OrganizationAddress(),
        postAddress: new OrganizationAddress(),
        contact: '',
        contactEmail: '',
        phone: '',
        site: '',
        billingEqualPost: false,
      };

      this.form.patchValue(client);
      this.customFieldService.enrichFormGroupWithDefaultValues(
        this.form,
        CustomFieldEntityType.Organization,
      );
      this.creatingMode = true;
      this.okButtonText = this.translate.instant('shared.actions.create');
    }

    this.form.valueChanges.subscribe(() => {
      if (this.readonly) {
        return;
      }

      if (this.form.get('billingEqualPost').value as boolean) {
        this.form.get('billingAddress').disable({ emitEvent: false });

        this.form
          .get('billingAddress')
          .patchValue(this.form.get('postAddress').value, { emitEvent: false });
      } else {
        this.form.get('billingAddress').enable({ emitEvent: false });
      }
    });
  }
}
