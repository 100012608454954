import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Exception } from 'src/app/shared/models/exception';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { LifecycleCardService } from '../lifecycle-card.service';
import { TransitionModalComponent } from '../state-modal/transition-modal/transition-modal.component';
import { TransitionsService } from '../transitions.service';

@Component({
  selector: 'wp-transitions-cell',
  templateUrl: './transitions-cell.component.html',
  styleUrls: ['./transitions-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionsCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;

  private destroyed$ = new Subject<void>();

  public get transitions(): UntypedFormArray {
    return this.formGroup.controls.transitions as UntypedFormArray;
  }

  public get stateId(): string {
    return this.formGroup.value.id as string;
  }

  constructor(
    @Inject('entityId') public lifecycleId: string,
    public service: GridService,
    public lifecycleService: LifecycleCardService,
    public cdr: ChangeDetectorRef,
    public transitionsService: TransitionsService,
    private modal: NgbModal,
    private blockUI: BlockUIService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.transitions.valueChanges.subscribe((transition) => {
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  /** Отредактировать переход */
  editTransition(index: number) {
    const transitionFormGroup = this.transitions.controls[
      index
    ] as UntypedFormGroup;
    if (this.service.readonly) {
      return;
    }

    const modalRef = this.modal.open(TransitionModalComponent);
    const instance = modalRef.componentInstance as TransitionModalComponent;

    instance.lifecycleId = this.lifecycleId;
    instance.stateId = this.stateId;
    instance.transitions = this.transitions.value;

    instance.transitionFormGroup = transitionFormGroup;

    modalRef.result.then(
      (newGroup) => {
        newGroup.performers = newGroup.performers.map((performerLine) => ({
          id: performerLine.performer.id,
          name: performerLine.performer.name,
          type: performerLine.performer.type,
        }));

        this.transitionsService.patchTransitionFormGroup(
          transitionFormGroup,
          newGroup,
        );

        const transitionsData = JSON.stringify(
          this.transitionsService.prepareTransitionsDTO(
            this.formGroup.controls.transitions.value,
          ),
        );

        this.blockUI.start();
        this.transitionsService
          .updateTransitions(this.stateId, transitionsData)
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: () => {
              this.blockUI.stop();
              this.notification.successLocal(
                'settings.lifecycles.card.props.transition.edited',
              );
            },
            error: (error: Exception) => {
              this.blockUI.stop();
              this.notification.error(error.message);
            },
          });
      },
      () => null,
    );
  }
}
