<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>
          {{ 'finance.entries.list.filter.properties.period.label' | translate }}
        </label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{
            'finance.entries.list.filter.properties.period.placeholder' | translate
          }}"
        ></wp-date-period-box>
      </div>

      <div class="form-group">
        <label>
          {{ 'finance.entries.list.filter.properties.account.label' | translate }}
        </label>
        <wp-select-box
          formControlName="account"
          collection="FinancialAccounts"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{
            'finance.entries.list.filter.properties.account.placeholder' | translate
          }}"
        ></wp-select-box>
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label>
          {{ 'finance.entries.list.filter.properties.client.label' | translate }}
        </label>
        <wp-select-box
          formControlName="client"
          [allowInactive]="service.allowInactive$ | async"
          collection="Organizations"
          placeholder="{{
            'finance.entries.list.filter.properties.client.placeholder' | translate
          }}"
        ></wp-select-box>
      </div>

      <div class="form-group">
        <label>
          {{ 'finance.entries.list.filter.properties.project.label' | translate }}
        </label>
        <wp-select-box
          formControlName="project"
          [allowInactive]="service.allowInactive$ | async"
          collection="Projects"
          placeholder="{{
            'finance.entries.list.filter.properties.project.placeholder' | translate
          }}"
        ></wp-select-box>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label>
          {{ 'finance.entries.list.filter.properties.legalEntity.label' | translate }}
        </label>
        <wp-select-box
          formControlName="legalEntity"
          [allowInactive]="service.allowInactive$ | async"
          collection="LegalEntities"
          placeholder="{{
            'finance.entries.list.filter.properties.legalEntity.placeholder' | translate
          }}"
        ></wp-select-box>
      </div>
    </div>
  </div>
</form>
