import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  stateStyles,
  Transition,
} from 'src/app/shared/models/entities/state.model';
import { Exception } from 'src/app/shared/models/exception';
import {
  GridColumnType,
  GridStringControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { LifecycleCardService } from '../lifecycle-card.service';
import { TransitionsService } from '../transitions.service';
import { TransitionsToolbarComponent } from './transitions-toolbar/transitions-toolbar.component';

/** Диалог редактирования/добавления состояния ЖЦ. */
@Component({
  selector: 'wp-state-modal',
  templateUrl: './state-modal.component.html',
  styleUrls: ['./state-modal.component.scss'],
  providers: [GridService],
})
export class StateModalComponent implements OnInit, OnDestroy {
  @Input() lifecycleId: string;
  @Input() stateId: string | null;

  public stateStyles = stateStyles;
  private destroyed$ = new Subject<void>();
  public header = '';

  public isSaving: boolean;
  public isLoading: boolean;

  public form = this.fb.group({
    id: [Guid.generate(), Validators.required],
    index: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    style: ['primary', Validators.required],
    isSystem: false,
    isInitial: false,
    isActive: true,
    isEntityProtected: false,
    isEntityDisabled: false,
    transitions: this.fb.array([]),
  });

  public get transitions(): UntypedFormArray {
    return this.form.controls.transitions as UntypedFormArray;
  }

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    toolbar: TransitionsToolbarComponent,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: () => {
          this.editTransition();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: () => {
          this.deleteTransition();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.transitionsService.editTransition(
            this.lifecycleId,
            this.stateId,
            this.form.controls.transitions.value,
          );
        },
      },
      {
        name: 'edit',
        handlerFn: () => {
          this.editTransition();
        },
      },
      {
        name: 'delete',
        handlerFn: () => {
          this.deleteTransition();
        },
      },
    ],
    view: {
      name: 'transitions',
      columns: [
        {
          name: 'name',
          forceCellUpdating: true,
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
        },
        {
          name: 'nextStateName',
          forceCellUpdating: true,
          header: 'settings.lifecycles.card.columns.nextState.header',
          hint: 'settings.lifecycles.card.columns.nextState.hint',
          type: GridColumnType.String,
        },
        {
          name: 'performersString',
          forceCellUpdating: true,
          header: 'settings.lifecycles.card.columns.performers.header',
          hint: 'settings.lifecycles.card.columns.performers.hint',
          type: GridColumnType.String,
        },
      ],
    },
  };

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    public service: LifecycleCardService,
    private gridService: GridService,
    private transitionsService: TransitionsService,
  ) {}

  public ok() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    /* Включаем все контролы, чтобы получить их значения */
    this.form.enable();
    const transitionsData = JSON.stringify(
      this.transitionsService.prepareTransitionsDTO(
        this.form.controls.transitions.value,
      ),
    );
    const data = {
      ...this.form.value,
      lifecycleId: this.lifecycleId,
      transitionsData,
    };
    delete data.transitions;
    this.disablePropsIsSystem();

    this.isSaving = true;
    if (this.stateId) {
      this.service
        .updateState(data)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.activeModal.close();
          },
          error: (error: Exception) => {
            this.isSaving = false;
            this.notification.error(error.message);
          },
        });
    } else {
      /* Индекс для нового состояния (оптимально просто взять длину массива, т.к. она равна макс. индекс + 1) */
      const index = this.service.lifecycleStates.length;
      this.service
        .createState({ ...data, index })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.activeModal.close();
          },
          error: (error: Exception) => {
            this.isSaving = false;
            this.notification.error(error.message);
          },
        });
    }
  }

  private load() {
    this.isLoading = true;

    this.service
      .getState(this.stateId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (state) => {
          this.setHeader(state.name);
          this.form.patchValue(state);
          /* Перебираем прилетевшие transitions */
          if (state.transitions) {
            state.transitions.map((transition) => {
              this.addNewTransitionGroup(transition);
            });
          }
          this.isLoading = false;
          this.disablePropsIsSystem();
          /** Отключаем галку 'начальное', если состояние уже является таковым */
          if (this.form.controls.isInitial.value) {
            this.form.controls.isInitial.disable();
          }
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  addNewTransitionGroup(transition) {
    const transitionGroup =
      this.transitionsService.getTransitionGroup(transition);
    /* Добавляем итоговую группу с transition в массив transitions */
    const transitions = this.form.controls.transitions as UntypedFormArray;
    transitions.push(transitionGroup);
    this.gridService.detectChanges();
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  private setHeader(header?: string) {
    this.header = header
      ? header
      : 'settings.lifecycles.card.props.state.createHeader';
  }

  setStyle(style: string) {
    this.form.controls.style.setValue(style);
  }

  /* Отключаем редактирование свойств, кроме переходов, если состояние системное */
  disablePropsIsSystem() {
    this.form.controls.isSystem.disable();
    if (this.form.controls.isSystem.value) {
      this.form.controls.name.disable();
      this.form.controls.code.disable();
    }
  }

  ngOnInit() {
    if (this.stateId) {
      this.load();
    } else {
      this.disablePropsIsSystem();
      this.setHeader();
    }
    this.transitionsService.addTransition$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newGroup: UntypedFormGroup) => {
        this.addNewTransitionGroup(newGroup);
        this.gridService.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  editTransition() {
    if (this.gridService.selectedGroup.value as Transition) {
      this.transitionsService.editTransition(
        this.lifecycleId,
        this.stateId,
        this.form.controls.transitions.value,
        this.gridService.selectedGroup,
      );
    }
  }

  deleteTransition() {
    let index = null;
    this.form.controls.transitions.value.map((transition) => {
      if (transition.id === this.gridService.selectedRow.id) {
        index = this.form.controls.transitions.value.indexOf(transition);
      }
    });
    if (index || index === 0) {
      this.transitions.removeAt(index);
    }
  }
}
