import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import {
  GetTransitionFormPropertyValue,
  TransitionFormDescription,
  TransitionFormPropertyType,
} from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { Exception } from 'src/app/shared/models/exception';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tmt-workflow-action-comment-form',
  templateUrl: './workflow-action-comment-form.component.html',
})
export class WorkflowActionCommentFormComponent implements OnInit {
  @Input() collection: string;
  @Input() entityId: string;
  @Input() actionId: string;
  @Input() taskId: string;
  @Input() actionName: string;

  public entityName2Collection = { ResourcePool: 'ResourcePools' };
  public isSaving = false;

  public actionForm: FormGroup = this.formBuilder.group({});
  public actionProperties: GetTransitionFormPropertyValue[];

  public isLoading = false;

  private collectionValuesSubject = new BehaviorSubject<any>(undefined);
  public collectionValues$ = this.collectionValuesSubject.asObservable();

  public get requestComment() {
    return this._requestComment;
  }

  private _requestComment = false;

  constructor(
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private data: DataService,
    private lifecycleService: LifecycleService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    const params = { actionId: this.actionId };
    this.isLoading = true;
    this.data
      .collection(this.collection)
      .entity(this.entityId)
      .function('GetWorkflowActionFormDescription')
      .query(params)
      .subscribe({
        next: (actionFormDescription: TransitionFormDescription) => {
          this.actionProperties =
            actionFormDescription.transitionFormPropertyValues;
          this._requestComment = actionFormDescription.requestComment;
          if (this.requestComment) {
            this.actionForm.addControl(
              'comment',
              this.formBuilder.control(
                null,
                actionFormDescription.commentIsRequired
                  ? Validators.required
                  : null,
              ),
            );
          }

          this.makeForm();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notification.error(error.message);
        },
      });
  }

  public ok() {
    this.actionForm.markAllAsTouched();

    if (this.actionForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      actionId: this.actionId,
      actionFormValue: {
        comment: this.actionForm.value.comment,
        propertyValues: [],
      },
    };

    Object.keys(this.actionForm.value).forEach((key) => {
      const isNavigationProperty = this.actionProperties.some(
        (p) =>
          p.name === key && p.type === TransitionFormPropertyType.navigationId,
      );
      if (isNavigationProperty) {
        data.actionFormValue.propertyValues.push({
          name: key,
          value: this.actionForm.value[key]?.id,
        });
      } else if (key !== 'comment' && !isNavigationProperty) {
        data.actionFormValue.propertyValues.push({
          name: key,
          value: this.actionForm.value[key],
        });
      }
    });

    this.lifecycleService
      .sendPerformWorkflowTaskRequest(this.taskId, this.actionName, data)
      .then(
        () => {
          this.isSaving = false;
          this.activeModal.close(this.actionForm.value);
        },
        (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      );
  }
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Gets transition property name localization.
   * @param property Property name.
   */
  public getTransitionPropertyName(property: string): string {
    return this.translate.instant(
      `enums.transitionFormProperties.${this.collection}.${property}`,
    );
  }

  private makeForm() {
    const collectionObservables = {};
    const collectionValues = {};
    const hasNavigationProperties = this.actionProperties.some(
      (prop) => prop.type === TransitionFormPropertyType.navigationId,
    );

    if (!hasNavigationProperties) {
      this.setControlValues(null);
      return;
    }

    this.actionProperties
      .filter((prop) => prop.type === TransitionFormPropertyType.navigationId)
      .forEach((prop) => {
        const collection = this.entityName2Collection[prop.entityType];
        collectionObservables[prop.entityType] = this.data
          .collection(collection)
          .query({ filter: { isActive: true } });
      });

    forkJoin(collectionObservables).subscribe({
      next: (collections) => {
        Object.keys(collections).forEach((key) => {
          collectionValues[key] = collections[key];
        });

        this.setControlValues(collectionValues);
        this.collectionValuesSubject.next(collectionValues);
      },
      error: (error: Exception) => {
        this.isLoading = false;
        if (error.code === Exception.BtEntityNotFoundException.code) {
          return;
        }
        this.notification.error(error.message);
      },
    });
  }

  private setControlValues(collectionValues: any) {
    this.actionProperties.forEach((property) => {
      let value: any;
      if (
        property.type === TransitionFormPropertyType.navigationId &&
        collectionValues
      ) {
        const entity = collectionValues[property.entityType].find(
          (item) => item.id === property.value,
        );
        value = { name: entity.name, id: property.value };
      } else {
        value = property.value;
      }

      this.actionForm.addControl(
        property.name,
        this.formBuilder.control(
          value,
          property.isRequired ? Validators.required : null,
        ),
      );
    });
    this.isLoading = false;
  }

  protected readonly TransitionFormPropertyType = TransitionFormPropertyType;
}
