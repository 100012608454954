<div *ngIf="!(service.readonly$ | async)" class="toolbar-container">
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.create' | translate }}"
      (click)="service.execute('create')"
    >
      <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
      {{ 'shared.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.edit' | translate }}"
      (click)="service.execute('edit')"
      [disabled]="!service.selectedRow"
    >
      {{ 'shared.actions.edit' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared.actions.delete' | translate }}"
      (click)="service.execute('delete')"
      [disabled]="!service.selectedRow"
    >
      <i class="bi bi-trash3 bi-15" aria-hidden="true"></i>
    </button>
  </div>
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      title="{{ 'settings.lifecycles.card.actions.moveUp' | translate }}"
      (click)="service.execute('moveUp')"
      [disabled]="!service.selectedRow"
    >
      <i class="bi bi-chevron-up bi-15" aria-hidden="true"></i>
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'settings.lifecycles.card.actions.moveDown' | translate }}"
      (click)="service.execute('moveDown')"
      [disabled]="!service.selectedRow"
    >
      <i class="bi bi-chevron-down bi-15" aria-hidden="true"></i>
    </button>
  </div>
</div>
