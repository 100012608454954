export enum CustomFieldEntityType {
  Organization = 'Organization',
  Program = 'Program',
  Project = 'Project',
  User = 'User',
  TimeSheetLine = 'TimeSheetLine',
  ProjectTask = 'ProjectTask',
  TimeAllocation = 'TimeAllocation',
  ActOfAcceptance = 'ActOfAcceptance',
  ExpenseRequest = 'ExpenseRequest',
  ExpenseRequestLine = 'ExpenseRequestLine',
  ProjectVersion = 'ProjectVersion',
  TimeOffRequest = 'TimeOffRequest',
  Certificate = 'Certificate',
  LegalEntity = 'LegalEntity',
  ProjectCostCenter = 'ProjectCostCenter',
  Issue = 'Issue',
}
