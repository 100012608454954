import {
  Component,
  OnInit,
  Input,
  Injector,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { StateBuilderService } from 'src/app/core/state-builder.service';
import { WorkflowTaskCardServiceService } from 'src/app/workflow-tasks/card/workflow-task-card.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-workflow-task-card',
  templateUrl: './workflow-task-card.component.html',
  providers: [
    { provide: ENTITY_COLLECTION, useValue: 'WorkflowTasks' },
    LifecycleService,
    WorkflowTaskCardServiceService,
  ],
  styleUrls: ['./workflow-task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowTaskCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  @Input() public isShowEntity = true;

  protected readonly commentedEntityCollectionType =
    CommentedEntityCollectionType;

  constructor(
    public service: WorkflowTaskCardServiceService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    public stateBuilder: StateBuilderService,
  ) {}

  ngOnInit() {
    this.service.load();
  }

  ngOnDestroy(): void {
    this.service.dispose();
  }

  public openUserInfo() {
    const userId = this.service.task.assigned.id;
    const target = document.getElementById('assigned');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }
}
