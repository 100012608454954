import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridCurrencyColumn,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ActList: List = {
  version: 3,
  name: 'acts',
  customFieldEntityType: CustomFieldEntityType.ActOfAcceptance,
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'number',
          visibleByDefault: true,
          width: 90,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'date',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'dateOfAcceptance',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'amount',
          visibleByDefault: true,
          width: 130,
        },
        {
          column: 'amountBC',
          visibleByDefault: true,
          width: 130,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'project',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'organization',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'description',
          visibleByDefault: true,
          width: 1,
        },

        {
          column: 'created',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 140,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'created', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'actOfAcceptance',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    },
    {
      name: 'number',
      header: 'acts.list.columns.number.header',
      hint: 'acts.list.columns.number.hint',
      type: GridColumnType.String,
      fixedWidth: true,
    },
    {
      name: 'organization',
      header: 'acts.list.columns.organization',
      hint: 'acts.list.columns.organization',
      type: GridColumnType.String,
    },
    {
      name: 'project',
      header: 'acts.list.columns.project',
      hint: 'acts.list.columns.project',
      type: GridColumnType.String,
    },
    {
      name: 'date',
      header: 'acts.list.columns.date',
      hint: 'acts.list.columns.date',
      type: GridColumnType.Date,
      fixedWidth: true,
    },
    {
      name: 'dateOfAcceptance',
      header: 'acts.list.columns.dateOfAcceptance',
      hint: 'acts.list.columns.dateOfAcceptance',
      type: GridColumnType.Date,
      fixedWidth: true,
    },
    <GridCurrencyColumn>{
      name: 'amount',
      header: 'acts.list.columns.amount.header',
      hint: 'acts.list.columns.amount.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'currencyCode',
      fixedWidth: true,
    },
    {
      name: 'amountBC',
      header: 'acts.list.columns.amountBC.header',
      hint: 'acts.list.columns.amountBC.hint',
      type: GridColumnType.Currency,
      fixedWidth: true,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'description',
      header: 'shared.columns.description',
      hint: 'shared.columns.description',
      type: GridColumnType.String,
    },
    {
      name: 'state',
      header: 'acts.list.columns.state.header',
      hint: 'acts.list.columns.state.hint',
      fixedWidth: true,
      type: GridColumnType.State,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'currencyCode',
      field: 'project.currency.alpha3Code',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'number',
      field: 'number',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organization',
      field: 'project.organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'project',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'date',
      field: 'date',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'dateOfAcceptance',
      field: 'dateOfAcceptance',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amount',
      field: 'amount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amountBC',
      field: 'amountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'ActsOfAcceptance',
};
