<div class="custom-field-separator" *ngIf="fields.length > 0 && isVisibleHr">
  <hr />
</div>

<div class="row">
  <div [ngClass]="columnClasses">
    <div *ngFor="let field of getLeftFields()" class="form-group" tmtIndicator>
      <label>{{ getLabel(field) }}</label>
      <wp-custom-field
        #controlWrapper
        [field]="field"
        [control]="getControl(field)"
        [mode]="mode"
        [attr.data-test-id]="field.id"
      ></wp-custom-field>
    </div>
  </div>
  <div [ngClass]="columnClasses">
    <div *ngFor="let field of getRightFields()" class="form-group" tmtIndicator>
      <label>{{ getLabel(field) }}</label>
      <wp-custom-field
        #controlWrapper
        [field]="field"
        [control]="getControl(field)"
        [mode]="mode"
        [attr.data-test-id]="field.id"
      ></wp-custom-field>
    </div>
  </div>
</div>
