import { DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';

import {
  Observable,
  catchError,
  firstValueFrom,
  forkJoin,
  map,
  merge,
  of,
  tap,
} from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';

import { LifecycleInfo } from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { State } from 'src/app/shared/models/entities/state.model';
import { TransitionFormModalComponent } from 'src/app/shared/components/features/transition-form-modal/transition-form-modal.component';
import { MetaEntityPropertyKind } from 'src/app/shared/models/entities/settings/metamodel.model';
import { Exception } from 'src/app/shared/models/exception';
import { LocalStringHelper } from 'src/app/shared/models/enums/language.enum';

import {
  BoardCard,
  BoardCardEntity,
  BoardCardView,
  BoardColumn,
  BoardColumnView,
} from 'src/app/boards/models/board.interface';
import {
  BOARD_CONFIG,
  BoardConfig,
} from 'src/app/boards/models/board-config.interface';
import { Board } from 'src/app/settings-app/boards/model/board.model';
import { REQUIRED_PROPERTIES } from 'src/app/boards/models/board.config';

@Injectable()
export class BoardDataService {
  public states: State[] = [];
  public entityLifecycleInfo = new Map<string, LifecycleInfo>();

  private destroyRef = inject(DestroyRef);

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private appService: AppService,
    private stateService: StateService,
    private modal: NgbModal,
    private blockUI: BlockUIService,
    @Inject(BOARD_CONFIG) private config: BoardConfig | null,
  ) {}

  /**
   * Gets board configs. Also loads states and saves them.
   *
   * @returns board column views.
   */
  public getBoardConfig(): Observable<BoardColumnView[]> {
    return forkJoin([
      this.dataService
        .collection('Boards')
        .entity(this.config.id)
        .get<Board>({
          select: ['id', 'cardProperties', 'cardViewProperties'],
          expand: {
            columns: {
              select: ['*'],
            },
          },
        }),
      this.dataService
        .collection(this.config.collection)
        .function('GetStates')
        .query<State[]>(null, {
          select: ['id', 'code', 'name', 'index', 'style'],
          orderBy: 'index',
        }),
    ]).pipe(
      map(([board, states]) => {
        this.config.cardStructure = board.cardViewProperties;
        this.initCardStructure();
        this.states = states;
        let columnViews: BoardColumnView[];

        if (board.columns.length) {
          columnViews = board.columns.map((column) => ({
            ...column,
            state: states.find((state) => state.id === column.stateId),
            actions: [],
          }));
        }

        return _.sortBy(columnViews, 'index');
      }),
    );
  }

  /**
   * Gets cards list.
   *
   * @param filter filter for getting needed entities.
   * @returns board cards.
   */
  public getCards(
    filter: { and: Array<any> } | any,
  ): Observable<BoardCardView[]> {
    const query: any = {
      expand: {
        entity: this.getEntityQuery(),
        card: { select: ['*'] },
      },
      filter: {
        card: {
          boardId: { type: 'guid', value: this.config.id },
        },
      },
    };

    if (!_.isEmpty(filter) && !_.isEmpty(filter?.and)) {
      query.filter = {
        ...query.filter,
        entity: {
          ...filter,
        },
      };
    }

    return this.dataService
      .collection(this.config.cardCollection)
      .query<BoardCardEntity<any>[]>(query)
      .pipe(
        map((values) => _.sortBy(values, ['card.index'])),
        map((values) =>
          values.map(({ card, entity }) => ({
            id: card.id,
            columnId: card.columnId,
            entity,
          })),
        ),
        catchError((error) => {
          this.notificationService.error(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /**
   * Gets lifecycle information for entity.
   *
   * @param card Board card.
   * @returns lifecycle information.
   */
  public getLifecycleInfo(card: BoardCardView): Observable<LifecycleInfo> {
    return this.dataService
      .collection(this.config.collection)
      .entity(card.entity.id)
      .function('GetLifecycleInfo')
      .get<LifecycleInfo>()
      .pipe(
        tap((lifecycleInfo) =>
          this.entityLifecycleInfo.set(card.entity.id, lifecycleInfo),
        ),
      );
  }

  /**
   * Changes entity state.
   *
   * @param card Board card.
   * @param nextStateId New entity state id.
   * @returns `true` if state is changed, otherwise `false`.
   */
  public async setState(
    card: BoardCardView,
    nextStateId: string,
  ): Promise<boolean> {
    try {
      let lifecycleInfo = this.entityLifecycleInfo.get(card.entity.id);

      if (!lifecycleInfo) {
        this.blockUI.start();
        lifecycleInfo = await firstValueFrom(this.getLifecycleInfo(card));
        this.blockUI.stop();
      }

      if (lifecycleInfo.workflowIndicatorData) {
        await this.messageService.confirmLocal(
          this.translateService.instant(
            'shared.workflow.workflowCancelConfirmation',
          ),
        );
      }

      const transition = lifecycleInfo.transitions.find(
        (t) => t.nextStateId === nextStateId,
      );

      if (!transition) {
        this.notificationService.errorLocal('shared2.messages.transitionError');
        return false;
      }

      let data: any = {
        stateId: nextStateId,
        transitionFormValue: {
          propertyValues: [],
          comment: '',
        },
      };

      if (transition.hasTransitionForm) {
        const ref = this.modal.open(TransitionFormModalComponent);
        const instance = ref.componentInstance as TransitionFormModalComponent;
        instance.collection = this.config.collection;
        instance.entityId = card.entity.id;
        instance.stateId = nextStateId;
        instance.transitionId = transition.id;

        data = await firstValueFrom(merge(ref.closed, ref.dismissed));
      }

      if (data === 'cancel') {
        return false;
      }

      this.blockUI.start();
      await firstValueFrom(
        this.dataService
          .collection(this.config.collection)
          .entity(card.entity.id)
          .action('SetState')
          .execute(data),
      );
      this.blockUI.stop();

      this.entityLifecycleInfo.delete(card.entity.id);

      return true;
    } catch (error) {
      this.blockUI.stop();

      if (error.message) {
        this.notificationService.error(error.message);
      }
    }
  }

  /**
   * Saves columns collection to board.
   *
   * @param columnViews Board column view.
   * @returns `true` if save succeeded, otherwise `false`
   */
  public saveUpdatedColumns(
    columnViews: BoardColumnView[],
  ): Observable<boolean> {
    const columns: BoardColumn[] = columnViews.map((columnView) => ({
      id: columnView.id,
      stateId: columnView.stateId,
      style: columnView.style,
      index: columnView.index,
      header: columnView.header,
    }));

    return this.updateBoard({ columns });
  }

  /**
   * Updates board card.
   *
   * @param id board card id.
   * @param data board card properties.
   * @returns `true` if update succeeded, otherwise `false`.
   */
  public updateCard(id: string, data: Partial<BoardCard>): Observable<boolean> {
    return this.dataService
      .collection('BoardCards')
      .entity(id)
      .patch(data)
      .pipe(
        map(() => true),
        catchError((error) => {
          this.notificationService.error(error.message);
          return of(false);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /**
   * Updates board configuration.
   *
   * @param data board parameters.
   * @returns `true` if update succeeded, otherwise `false`.
   */
  public updateBoard(data: Partial<Board>): Observable<boolean> {
    return this.dataService
      .collection('Boards')
      .entity(this.config.id)
      .patch(data)
      .pipe(
        map(() => true),
        catchError((error) => {
          this.notificationService.error(error.message);
          return of(false);
        }),
        takeUntilDestroyed(this.destroyRef),
      );
  }

  /** Deletes board. */
  public removeBoard(): void {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.dataService
          .collection('Boards')
          .entity(this.config.id)
          .delete()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.notificationService.successLocal('shared.deleteCompleted');
              this.stateService.go('currentTimesheet', {
                navigation: 'my.currentTimesheet',
              });
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
            },
          });
      },
      () => null,
    );
  }

  private getEntityQuery(): Record<string, any> {
    const select: string[] = ['id', 'stateId'];
    const expand: Record<string, any> = {
      state: {
        select: ['id', 'code'],
      },
    };

    this.config.cardStructure.forEach((property) => {
      switch (property.kind) {
        case MetaEntityPropertyKind.primitive:
          select.push(property.name);
          break;
        case MetaEntityPropertyKind.navigation:
          expand[property.name] = {
            select: ['id', 'name'],
          };
          break;
        // case MetaEntityPropertyKind.complex:
        //   select.push(property.name);
        //   break;
      }
    });

    return {
      select,
      expand,
    };
  }

  private initCardStructure(): void {
    REQUIRED_PROPERTIES.filter(
      (r) => !this.config.cardStructure.some((p) => r.name === p.name),
    ).forEach((property) => {
      this.config.cardStructure.unshift({
        ...property,
        displayName: LocalStringHelper.getTranslate(
          property.displayNames,
          this.appService.session.language,
        ),
      });
    });

    this.config.cardStructure.forEach((property) => {
      property.name = _.camelCase(property.name);
      property.clrType = _.camelCase(property.clrType);
    });
  }
}
