import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { Program } from 'src/app/shared/models/entities/projects/program.model';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { LogService } from 'src/app/core/log.service';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-program-settings',
  templateUrl: './program-settings.component.html',
})
export class ProgramSettingsComponent implements OnInit {
  @Input() programId: string;

  public isSaving = false;
  public creatingMode: boolean;
  public isLoading: boolean;
  public readonly: boolean;
  public form = this.fb.group({
    id: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    startDate: [null],
    endDate: [null],
    manager: [null, Validators.required],
    skipManagerApprove: [false],
  });
  public okButtonText: string;

  private destroyRef = inject(DestroyRef);

  public get program(): Program {
    return this.form.value as Program;
  }

  constructor(
    private log: LogService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private app: AppService,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(
      this.form,
      CustomFieldEntityType.Program,
    );
  }

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.log.debug('Saving program entity...');

    const okFunc = () => {
      this.isSaving = false;

      if (this.creatingMode) {
        this.notification.successLocal(
          'projects.programs.settings.messages.created',
        );
        this.state.go('program', { entityId: this.form.value.id });
      } else {
        this.notification.successLocal(
          'projects.programs.settings.messages.saved',
        );
      }

      this.activeModal.close(this.form.value);
    };

    const errorFunc = (error: Exception) => {
      this.notification.error(error.message);
      this.isSaving = false;
    };

    const program = this.program;

    const data = {
      id: program.id,
      name: program.name,
      isActive: program.isActive,
      managerId: program.manager.id,
      description: program.description,
      code: program.code,
      endDate: program.endDate,
      startDate: program.startDate,
      skipManagerApprove: program.skipManagerApprove,
    };

    this.customFieldService.assignValues(
      data,
      program,
      CustomFieldEntityType.Program,
    );

    if (this.creatingMode) {
      this.data
        .collection('Programs')
        .insert(data)
        .subscribe({ next: okFunc, error: errorFunc });
    } else {
      this.data
        .collection('Programs')
        .entity(this.programId)
        .update(data)
        .subscribe({ next: okFunc, error: errorFunc });
    }
  };

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /** Загрузка сведений о проекте. */
  public load() {
    this.isLoading = true;

    this.log.debug('Loading program...');
    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'startDate',
        'endDate',
        'code',
        'description',
        'skipManagerApprove',
        'editAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.customFieldService.enrichQuery(query, CustomFieldEntityType.Program);

    this.data
      .collection('Programs')
      .entity(this.programId)
      .get<Program>(query)
      .subscribe({
        next: (program) => {
          this.form.patchValue(program);
          this.isLoading = false;

          this.readonly = !program.editAllowed;
          if (this.readonly) {
            this.form.disable();
          }
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });
  }

  ngOnInit() {
    if (this.programId) {
      this.load();
      this.creatingMode = false;
      this.okButtonText = this.translate.instant('shared.actions.save');
    } else {
      // Режим создания проекта.
      const program: Program = {
        id: Guid.generate(),
        manager: this.app.session.user,
        startDate: DateTime.now().toISODate(),
        endDate: DateTime.now().endOf('year').toISODate(),
        code: '',
        name: '',
        description: '',
        skipManagerApprove: false,
      };

      this.form.patchValue(program);
      this.customFieldService.enrichFormGroupWithDefaultValues(
        this.form,
        CustomFieldEntityType.Program,
      );
      this.creatingMode = true;
      this.okButtonText = this.translate.instant('shared.actions.create');
    }
    FormHelper.controlDatePair(
      this.form,
      'startDate',
      'endDate',
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
