import { List } from '../models/inner/list';
import {
  GridColumnType,
  GridStringControlColumn,
} from '../models/inner/grid-column.interface';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

export const PROJECT_COST_CENTER: List = {
  name: 'projectCostCenters',
  version: 5,
  customFieldEntityType: CustomFieldEntityType.ProjectCostCenter,
  editableCustomFields: true,
  views: [
    {
      name: 'default',
      resizableColumns: true,
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 250,
        },
        {
          column: 'legalEntity',
          visibleByDefault: true,
          width: 280,
        },
        {
          column: 'actualCost',
          visibleByDefault: true,
          width: 220,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: true,
          width: 200,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 90,
        },
        {
          column: 'created',
          width: 150,
          visibleByDefault: false,
        },
        {
          column: 'modified',
          width: 150,
          visibleByDefault: false,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 300,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 300,
        },
      ],
    },
  ],
  columns: [
    <GridStringControlColumn>{
      name: 'name',
      type: GridColumnType.StringControl,
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    {
      name: 'legalEntity',
      type: GridColumnType.SelectControl,
      header: 'projects.projects.card.costCenters.columns.legalEntity.header',
      hint: 'projects.projects.card.costCenters.columns.legalEntity.hint',
    },
    {
      name: 'actualCost',
      type: GridColumnType.Currency,
      header: 'projects.projects.card.costCenters.columns.actualCost.header',
      hint: 'projects.projects.card.costCenters.columns.actualCost.hint',
    },
    {
      name: 'actualExpenses',
      type: GridColumnType.Currency,
      header:
        'projects.projects.card.costCenters.columns.actualExpenses.header',
      hint: 'projects.projects.card.costCenters.columns.actualExpenses.hint',
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.BooleanControl,
      forceCellUpdating: true,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.SelectControl,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.SelectControl,
    },
    {
      name: 'isActive',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
    },
  ],
  dataColumns: [],
};
