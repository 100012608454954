import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { AccountingEntryComponent as AccountingEntryComponent } from './accounting-entry-filter.component';

@Injectable()
export class AccountingEntryFilterService extends FilterService {
  public override component: Type<FilterDetails> = AccountingEntryComponent;
  public override hasDetails = true;
  public override hasViewSelector = false;

  public override placeholder = 'finance.entries.list.filter.placeholder';

  protected override getDefaultValues = (): any => {
    const value = {
      period: null,
      project: null,
      client: null,
      account: null,
      legalEntity: null,
    };

    return value;
  };

  public override getODataFilter() {
    const result: any[] = [];

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [
          { 'tolower(description)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/quotes
          { "cast(number, 'Edm.String')": { contains: text } },
        ],
      });
    }

    if (this.values.account) {
      result.push({
        accountId: { type: 'guid', value: this.values.account.id },
      });
    }

    if (this.values.project) {
      result.push({
        projectId: { type: 'guid', value: this.values.project.id },
      });
    }

    if (this.values.client) {
      result.push({
        project: {
          organizationId: { type: 'guid', value: this.values.client.id },
        },
      });
    }

    if (this.values.period) {
      const dates = this.dateService.getDatePairFromPeriodForFilter(
        this.values.period,
      );

      result.push({
        date: { le: { type: 'raw', value: dates.periodFinish } },
      });

      result.push({
        date: { ge: { type: 'raw', value: dates.periodStart } },
      });
    }

    if (this.values.legalEntity) {
      result.push({
        legalEntityId: { type: 'guid', value: this.values.legalEntity.id },
      });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }
}
