<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'base.massApproval.header' | translate }}
    </h3>
    <button
      [disabled]="operationInProgress"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div>
      @if (
        (changeStateParams?.transitionForm?.requestComment ||
          changeStateParams?.transitionForm?.transitionFormPropertyValues?.length) &&
        !massOperationCompleted &&
        !operationInProgress
      ) {
        <div class="row mb-2">
          @for (control of transitionProperties; track control.name) {
            <div class="form-group" tmtIndicator>
              <label class="control-label">
                {{ lifecycle.getTransitionPropertyDisplayName(collection, control.name) }}
              </label>
              @switch (control.type) {
                @case ('Date') {
                  <wp-date-box formControlName="{{ control.name }}" />
                }
                @case ('String') {
                  <wp-text-box formControlName="{{ control.name }}" />
                }
                @case ('Integer') {
                  <wp-date-box formControlName="{{ control.name }}" />
                }
                @case ('Date') {
                  <wp-number-box formControlName="{{ control.name }}" type="integer" />
                }
              }
            </div>
          }

          @if (changeStateParams?.transitionForm?.requestComment) {
            <div class="form-group" tmtIndicator>
              <label class="control-label">
                {{ 'settings.lifecycles.card.transitionForm.comment' | translate }}
              </label>
              <wp-multiline-text-box
                formControlName="comment"
                placeholder="{{ 'settings.lifecycles.card.transitionForm.comment' | translate }}"
              />
            </div>
          }
        </div>
      }

      @if (!massOperationCompleted && !operationInProgress) {
        <div class="row">
          <div class="col-md-12">
            {{ 'base.massApproval.actionsCount' | translate: { count: actionsCount } }}
          </div>
        </div>
      }

      @if (massOperationCompleted) {
        <div class="row">
          <div class="col-md-12">
            {{
              'base.massApproval.successActionsCount' | translate: { count: successActionsCount }
            }}
          </div>
        </div>
      }

      @if (massOperationCompleted && errorGroups.length) {
        <div class="row mt-3">
          <div class="col-md-12 mb-1 text-danger">
            {{ 'base.massApproval.failActionsCount' | translate: { count: failActionsCount } }}
          </div>
          <ul class="mb-1 ms-3">
            @for (group of errorGroups; track $index) {
              <li>
                {{ group.title }}
                <ul>
                  @for (el of group.items; track el) {
                    <li>
                      <div class="trim" [style.max-width]="'500px'">
                        @if (el.error.details) {
                          <i
                            class="bi bi-question-circle"
                            [ngbTooltip]="this.detailsMessage(el.error)"
                            tooltipClass="tooltip-break-n"
                          >
                          </i>
                        }
                        <a
                          uiSref="{{ state }}"
                          [uiParams]="{
                            entityId: el.item.id,
                            routeMode: stateParams.routeMode,
                            navigation: stateParams.navigation
                          }"
                        >
                          {{ el.item[entityPropertyName]?.name ?? el.item.name ?? el.item.id }}
                        </a>
                      </div>
                    </li>
                  }
                </ul>
              </li>
            }
          </ul>
        </div>
      }
    </div>
    <wp-loading-indicator [loading]="operationInProgress" [progress]="progress" />
  </div>
  <div class="modal-footer">
    @if (!massOperationCompleted) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="apply()"
        wpButtonSpinner
        [isBusy]="operationInProgress"
      >
        {{ 'base.massApproval.action.label' | translate }}
      </button>
    }
    @if (massOperationCompleted) {
      <button type="button" class="btn btn-primary" (click)="ok()" wpButtonSpinner>
        {{ 'shared.actions.close' | translate }}
      </button>
    }
    @if (!massOperationCompleted) {
      <button
        type="button"
        class="btn btn-default"
        (click)="cancel()"
        [disabled]="operationInProgress"
      >
        {{ 'shared.actions.cancel' | translate }}
      </button>
    }
  </div>
</form>
