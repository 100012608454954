import { Component } from '@angular/core';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Toolbar } from 'src/app/shared/components/features/grid/toolbar';

@Component({
  selector: 'wp-transitions-toolbar',
  templateUrl: './transitions-toolbar.component.html',
})
export class TransitionsToolbarComponent extends Toolbar {
  constructor(public service: GridService) {
    super();
  }
}
