/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import Oidc, {
  UserManager,
  UserManagerSettings,
  User,
  WebStorageStateStore,
} from 'oidc-client';
import { Observable } from 'rxjs';
import { map, share, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Guid } from '../shared/helpers/guid';
import { AppConfigService } from './app-config.service';
import { DataService } from './data.service';
import { LogService } from './log.service';

/** Сервис аутентификации. */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private savedPathStorageName = 'authSavedPath';
  private syncStoreName = 'auth';
  private clientId = Guid.generate();

  /** Email замещаемого пользователя. */
  public substitutedEmail: string;

  private loginAsStoreName = 'loginAs';
  private authSessionStoreName = 'authSession';
  private manager: UserManager;
  accessTokenExpiringTime: number;

  public serviceWorkerRegistration: void;

  public token$ = new Observable<string>((subscriber) => {
    this.manager.getUser().then((user) => {
      if (!user || user.expired || !user.access_token) {
        this.signinSilent().then(
          (response) => subscriber.next(response.access_token),
          (error) => subscriber.error(error),
        );
      } else {
        subscriber.next(user.access_token);
      }
    });
  }).pipe(
    tap((_) =>
      this.log.debug('Access token has been received to subscribers. '),
    ),
    share(),
    take(1),
  );

  constructor(
    private log: LogService,
    private data: DataService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
  ) {
    this.substitutedEmail = this.cookieService.check(this.loginAsStoreName)
      ? this.cookieService.get(this.loginAsStoreName)
      : null;

    if (!environment.production) {
      Oidc.Log.logger = console;
    }
  }

  /** Инициализация сервиса. */
  public init() {
    this.localStorageService.observe(this.syncStoreName).subscribe((id) => {
      if (id !== this.clientId) {
        this.log.debug('Reloading...');
        location.reload();
      }
    });

    this.manager = new UserManager(this.getClientSettings());

    this.manager.events.addSilentRenewError((response) => {
      const message = `Silent renew auth is failed! ${response}`;
      this.log.error(message);
    });

    this.manager.events.addUserSignedOut(() => {
      console.log('User is signed out!');
      this.signinRedirect();
    });

    this.manager.events.addAccessTokenExpiring(() => {
      this.accessTokenExpiringTime = new Date().getTime();
      const message = 'Access token is expiring...';
      this.log.log(message);
    });

    this.manager.events.addUserLoaded(() => {
      let message = 'User has been updated.';
      if (this.accessTokenExpiringTime) {
        const timeElapsed = new Date().getTime() - this.accessTokenExpiringTime;
        message += ` Time elapsed from expiring is ${timeElapsed} ms.`;
      }
      this.log.log(message);
      this.accessTokenExpiringTime = null;
    });
  }

  /** Запуск тихого обновления токенов. */
  public startSilentRenew() {
    this.manager.startSilentRenew();
  }

  /** Попытка скрытного (тихого) входа. */
  public signinSilent(): Promise<any> {
    return this.manager.signinSilent().then(
      (user) => {
        this.log.log('User was authenticated: ' + user.profile.sub);
        this.setAuthSessionCookie();
        this.startSilentRenew();
        return user;
      },
      (response) => {
        const message = `Error! Auth hasn't been updated via signin silent. ${response}`;
        this.log.error(message);
        this.signinRedirect();
        throw message;
      },
    );
  }

  /** Сменить адрес на сохраненный в момент входа. */
  public redirectToSaveState() {
    const path = this.sessionStorageService.retrieve(this.savedPathStorageName);
    if (path) {
      window.history.pushState(null, '', path);
      this.sessionStorageService.clear(this.savedPathStorageName);
    }
  }

  private signinRedirect() {
    // Запомнить путь.
    const path = document.location.href;
    this.sessionStorageService.store(this.savedPathStorageName, path);
    this.manager.signinRedirect();
  }

  /** Возвращает текущего пользователя. */
  public getUser(): Promise<User> {
    return this.manager.getUser();
  }

  /** Возвращает заголовок аутентификации (Bearer). */
  getAuthorizationHeaderValue(): Observable<string> {
    return this.token$.pipe(map((token) => `Bearer ${token}`));
  }

  /** Запускает выход. */
  signOut() {
    // Прекратить замещение.
    this.cookieService.delete(this.loginAsStoreName, '/');
    this.manager.signoutRedirect();
  }

  /** Запускает выход со сбросом сессии на сервере. */
  signOutWithSessionDestroy() {
    this.data.model
      .action('DestroySession')
      .execute()
      .subscribe(() => {
        this.signOut();
      });
  }

  /** Обработка ответа паспорта. */
  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(
      () => {
        this.redirectToSaveState();
        this.startSilentRenew();
        this.manager.clearStaleState();
        this.setAuthSessionCookie();
      },
      (error) => {
        const message = `Authentication failure: response code is wrong or was rejected by passport. ${error}`;
        this.log.error(message);
        this.manager.signoutRedirect();
        throw new Error('message');
      },
    );
  }

  /** Войти в режим замещения. */
  public startSubstituting(email: string) {
    this.cookieService.set(this.loginAsStoreName, email, null, '/');

    this.updateSyncStore();
    location.reload();
  }

  /** Выйти из режима замещения. */
  public stopSubstituting() {
    this.cookieService.delete(this.loginAsStoreName, '/');
    this.updateSyncStore();
    location.reload();
  }

  /** Сообщить остальным вкладкам, что необходимо перезагрузиться. */
  private updateSyncStore() {
    this.localStorageService.store(this.syncStoreName, this.clientId);
  }

  private setAuthSessionCookie() {
    this.cookieService.set(this.authSessionStoreName, null, null, '/');
  }

  public checkAuthSession(): boolean {
    return this.cookieService.check(this.authSessionStoreName);
  }

  /** Настройка клиента аутентификации. */
  private getClientSettings(): UserManagerSettings {
    const url = window.location.href;
    const segments = url.split('/');
    const host = segments[0] + '//' + segments[2];

    return {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      stateStore: new WebStorageStateStore({ store: window.localStorage }),
      clockSkew: 36000,
      authority: AppConfigService.config.passport.url,
      client_id: 'web_app',
      redirect_uri: `${host}/auth-callback`,
      loadUserInfo: false,
      silent_redirect_uri: `${host}/assets/silent-refresh.html`,
      post_logout_redirect_uri: `${AppConfigService.config.passport.url}/logout-successful`,
      response_type: 'code',
      scope: 'openid profile all',
      filterProtocolClaims: true,
      silentRequestTimeout: 1000 * 60 * 2, // 2 минуты
      automaticSilentRenew: false, // Отложенный запуск
      accessTokenExpiringNotificationTime: 300, // 5 минут
      monitorSession: false,
      metadata: {
        issuer: AppConfigService.config.passport.url,
        jwks_uri: `${AppConfigService.config.passport.url}/.well-known/openid-configuration/jwks`,
        authorization_endpoint: `${AppConfigService.config.passport.url}/connect/authorize`,
        token_endpoint: `${AppConfigService.config.passport.url}/connect/token`,
        userinfo_endpoint: `${AppConfigService.config.passport.url}/connect/userinfo`,
        end_session_endpoint: `${AppConfigService.config.passport.url}/connect/endsession`,
        check_session_iframe: `${AppConfigService.config.passport.url}/connect/checksession`,
        revocation_endpoint: `${AppConfigService.config.passport.url}/connect/revocation`,
        introspection_endpoint: `${AppConfigService.config.passport.url}/connect/introspect`,
      },
    };
  }
}
