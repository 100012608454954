<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.resourceGroups.addingUsersModal.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="toolbar-container" [formGroup]="filterForm">
      <wp-text-box
        style="width: 250px"
        formControlName="name"
        placeholder="{{
          'shared.resourceGroups.addingGroupsModal.filter.defaultPlaceholder' | translate
        }}"
      ></wp-text-box>
    </div>

    <table class="wp-nested-table wp-nested-table-hover fix-table" [ngStyle]="leftTableStyles">
      <thead>
        <tr>
          <th
            style="width: 40%"
            title="{{ 'shared.resourceGroups.addingUsersModal.columns.user' | translate }}"
          >
            {{ 'shared.resourceGroups.addingUsersModal.columns.user' | translate }}
          </th>
          <th
            style="width: 40%"
            title="{{ 'shared.resourceGroups.addingUsersModal.columns.department' | translate }}"
          >
            {{ 'shared.resourceGroups.addingUsersModal.columns.department' | translate }}
          </th>
          <th
            style="width: 40%"
            title="{{ 'shared.resourceGroups.addingUsersModal.columns.resourcePool' | translate }}"
          >
            {{ 'shared.resourceGroups.addingUsersModal.columns.resourcePool' | translate }}
          </th>
        </tr>
      </thead>
    </table>

    <div class="scroll-container">
      <table
        #leftTbl
        (resized)="resizeLeftTbl()"
        class="wp-nested-table wp-nested-table-hover position-relative"
        style="width: 100%; margin: 0"
      >
        <thead>
          <tr>
            <th style="width: 40%">
              {{ 'shared.resourceGroups.addingUsersModal.columns.user' | translate }}
            </th>
            <th style="width: 40%">
              {{ 'shared.resourceGroups.addingUsersModal.columns.department' | translate }}
            </th>
            <th style="width: 40%">
              {{ 'shared.resourceGroups.addingUsersModal.columns.resourcePool' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of availableUsers; index as index" (click)="selectUser(user, index)">
            <td class="trim avatar-cell" title="{{ user.name }}">
              <img class="avatar-bg" [src]="user.id | avatar: 60" />
              <div class="trim">{{ user.name }}</div>
            </td>
            <td class="trim" title="{{ user.department?.name ?? '' }}">
              {{ user.department?.name ?? '' }}
            </td>
            <td class="trim" title="{{ user.resourcePool?.name ?? '' }}">
              {{ user.resourcePool?.name ?? '' }}
            </td>
          </tr>
          <tr *ngIf="availableUsers.length == 0 && !isLoading">
            <td colspan="3" class="text-center text-body-secondary">
              {{ 'shared.noDisplayData' | translate }}
            </td>
          </tr>
          <tr *ngIf="loadedPartly">
            <td colspan="3" class="text-body-secondary text-center text-uppercase">
              {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadLimit } }}<br />
              {{ 'shared.refineQueryMessage' | translate }}
            </td>
          </tr>
        </tbody>
      </table>
      <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
    </div>

    <div class="selected-roles-scroll-container">
      <div
        class="selected-resource-block trim"
        *ngFor="let user of selectedUsers; index as index"
        (click)="removeUser(user, index)"
      >
        <img class="avatar-bg" [src]="user.id | avatar: 60" />
        <div class="trim" title="{{ user.name }}">
          {{ user.name }}
        </div>

        <button type="button" class="close" (click)="removeUser(user, index)">&times;</button>
      </div>
      <div *ngIf="selectedUsers.length == 0" class="text-uppercase text-body-secondary no-data">
        {{ 'shared.resourceGroups.addingUsersModal.noDisplayData' | translate }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="selectedUsers.length > 0"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.add' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
