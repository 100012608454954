import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  StateBuilderService,
  WorkflowEntityState,
} from 'src/app/core/state-builder.service';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';

@Component({
  selector: 'tmt-workflow-instance-entity-cell',
  templateUrl: './workflow-instance-entity-cell.component.html',
  styleUrls: ['./workflow-instance-entity-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowInstanceEntityCellComponent
  implements GridTemplateCell, OnInit
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public state: WorkflowEntityState;

  public get groupValue(): any {
    return this.formGroup?.getRawValue();
  }

  constructor(private stateBuilderService: StateBuilderService) {}

  public ngOnInit(): void {
    this.state = this.stateBuilderService.getStateForWorkflowEntity(
      this.groupValue.id,
      this.groupValue.entity.type,
      this.groupValue.entity.id,
    );
  }
}
