import { CodedEntity } from './coded-entity.model';
import { TransitionForm } from './lifecycle/transition-form.model';
import { NamedEntity } from './named-entity.model';
import { Lifecycle } from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { Dictionary } from '../dictionary';

export interface State extends CodedEntity {
  style: string;
  index: number;
  isEntityProtected: boolean;
  isEntityDisabled: boolean;
  canBeSetByModerator: boolean;
  transitions: Transition[];
  lifecycle: Lifecycle;
  isInitial?: boolean;
}

export interface Transition {
  id: string;
  name: string;
  labelStrings: Dictionary<string>;
  nextStateId: string;
  nextStateName?: string;
  performersString?: string;
  performers: Performer[];
  hasTransitionForm: boolean;
  transitionForm: TransitionForm | null;
}

export interface LabelStrings {
  en?: string;
  ru: string;
}

export interface Performer extends NamedEntity {
  type: string;
}

export const stateStyles = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'light',
  'dark',
];
