import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NamingLocalizationModalComponent } from 'src/app/settings-app/shared/naming-localization/naming-localization-modal/naming-localization-modal.component';
import { TranslateService } from '@ngx-translate/core';

/**
 * Represents Naming Localization content.
 * */
@Component({
  selector: 'wp-naming-localization',
  templateUrl: './naming-localization.component.html',
  providers: [GridService],
})
export class NamingLocalizationComponent implements OnInit {
  @Input() readonly = false;
  /** Localization string labels from model. */
  @Input() labelStrings: UntypedFormControl;

  private _localizationBtnText = '';
  private _addLocalizationLabel = '';

  public get localizationBtnText() {
    return this._localizationBtnText;
  }

  constructor(
    private modal: NgbModal,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this._addLocalizationLabel = this.translate.instant(
      'settings.namingLocalization.addLocalization',
    );

    this.updateLocalizationBtnText();
  }

  /**
   * Opens Modal window to view/edit Naming Localization strings.
   * */
  public openSettings() {
    const ref = this.modal.open(NamingLocalizationModalComponent);
    const instance = ref.componentInstance as NamingLocalizationModalComponent;
    instance.readonly = this.readonly;
    instance.labelStrings = this.labelStrings;

    ref.result.then(
      (labelStrings) => {
        this.labelStrings.setValue(labelStrings);
        this.labelStrings.markAsDirty();
        this.updateLocalizationBtnText();
      },
      () => null,
    );
  }

  private updateLocalizationBtnText() {
    this._localizationBtnText = this.labelStrings.value
      ? JSON.stringify(this.labelStrings.value)
          .replace(/["{}]|(.+?)"/gm, '$1')
          .replace(/,|:/g, '$& ')
      : this._addLocalizationLabel;
  }
}
