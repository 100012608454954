<tmt-form-header
  [name]="issueCardService.formValue?.name"
  [isEditable]="(issueCardService.issue$ | async)?.editAllowed"
  [saveFn]="saveName"
/>
<wp-loading-indicator [state]="issueCardService.state$ | async" />
<tmt-not-found [state]="issueCardService.state$ | async" [urlMustContain]="entityId" />

@if ((issueCardService.state$ | async) === 'Ready') {
  <div class="subheader mt-3">
    <div class="subheader__props">
      <div class="subheader__prop"><wp-state-indicator /></div>
      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.created.value' | translate }}: </label>
        <div class="subheader__value">{{ issueCardService.issue?.created | date: 'short' }}</div>
      </div>
      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.createdBy.value' | translate }}: </label>
        <div class="subheader__value" #createdBy>
          <button
            type="button"
            (click)="openCreatedByUserInfo(createdBy)"
            class="btn btn-link btn-link-content"
          >
            {{ issueCardService.issue.createdBy?.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="main">
      <form [formGroup]="issueCardService.issueForm" class="form issue-container">
        <div class="form-group">
          <label>{{ 'shared2.props.description' | translate }}</label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'shared2.props.description' | translate }}"
          />
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label>{{ 'shared2.props.assigned' | translate }}</label>
            <wp-user-box
              placeholder="{{ 'shared2.props.assigned' | translate }}"
              formControlName="assigned"
            />
          </div>
          <div class="form-group col-6">
            <label>{{ 'shared2.props.type' | translate }}</label>
            <wp-select-box
              formControlName="type"
              collection="IssueTypes"
              [allowNull]="false"
              placeholder="{{ 'shared2.props.type' | translate }}"
            />
          </div>
        </div>
        <wp-custom-fields
          class="d-block custom-fields-container"
          [formGroup]="issueCardService.issueForm"
          entityType="Issue"
          [isVisibleHr]="false"
        />
      </form>
    </div>
    @if (!(issueCardService.issue$ | async)?.isActive) {
      <div class="resolution" ngbAccordion>
        <div ngbAccordionItem [collapsed]="false">
          <h4 ngbAccordionHeader>
            <button ngbAccordionButton>{{ 'base.result' | translate }}</button>
          </h4>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <form class="resolution" [formGroup]="issueCardService.issueForm">
                  @if ((issueCardService.issue$ | async)?.resolution) {
                    <div class="form-group">
                      <label>{{
                        'components.issueCardComponent.props.resolution' | translate
                      }}</label>
                      <div class="form-control-plaintext trim">
                        {{ (issueCardService.issue$ | async)?.resolution?.name }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label>{{
                        'components.issueCardComponent.props.resolutionComment' | translate
                      }}</label>
                      <wp-text-box formControlName="resolutionComment" [readonly]="true" />
                    </div>
                  }
                  <div class="form-group">
                    <label>{{ 'shared2.props.modified.value' | translate }}</label>
                    <div class="form-control-plaintext">
                      {{ (issueCardService.issue$ | async)?.modified | date: 'short' }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{ 'shared2.props.modifiedBy.value' | translate }}</label>
                    <wp-user-box formControlName="modifiedBy" [readonly]="true" />
                  </div>
                </form>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-4">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-comments-tab
          [entityId]="entityId"
          [collection]="commentedEntityCollectionType.Issues"
        />
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-attachments-tab
          [entityId]="entityId"
          [collection]="commentedEntityCollectionType.Issues"
        />
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <tmt-entity-workflow-tasks *ngbNavContent [entityId]="entityId" class="mt-2 d-block" />
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history [entityId]="entityId" *ngbNavContent class="mt-2 d-block" />
    </li>
    <wp-sort class="ms-auto" />
  </ul>

  <div [ngbNavOutlet]="nav"></div>
}
