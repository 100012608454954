<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ header | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>
    <form class="form" [hidden]="isLoading" [formGroup]="form">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>{{ 'settings.lifecycles.card.props.state.name.label' | translate }}</label>
              <wp-text-box
                formControlName="name"
                placeholder="{{ 'settings.lifecycles.card.props.state.name.ph' | translate }}"
              ></wp-text-box>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>{{ 'shared.props.code' | translate }}</label>
              <wp-text-box
                style="width: 150px"
                formControlName="code"
                placeholder="{{ 'shared.props.code' | translate }}"
              ></wp-text-box>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex">
            <div class="form-check">
              <input
                formControlName="isInitial"
                type="checkbox"
                class="form-check-input"
                id="isInitial"
              />
              <label class="form-check-label" for="isInitial">
                {{ 'settings.lifecycles.card.props.state.isInitial.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="'settings.lifecycles.card.props.state.isInitial.hint' | translate"
              ></i>
            </div>
            <div class="form-check ms-3">
              <input
                formControlName="isSystem"
                type="checkbox"
                class="form-check-input"
                id="isSystem"
              />
              <label class="form-check-label" for="isSystem">
                {{ 'settings.lifecycles.card.props.state.isSystem.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="'settings.lifecycles.card.props.state.isSystem.hint' | translate"
              ></i>
            </div>

            <div class="form-check ms-3">
              <input
                formControlName="isEntityProtected"
                type="checkbox"
                class="form-check-input"
                id="isEntityProtected"
              />
              <label class="form-check-label" for="isEntityProtected">
                {{ 'settings.lifecycles.card.props.state.isEntityProtected.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="
                  'settings.lifecycles.card.props.state.isEntityProtected.hint' | translate
                "
              ></i>
            </div>
            <!-- Закомментировано на время, пока все состояния считаем активными -->
            <!--             <div
              class="form-check ms-3"
            >
              <input
                formControlName="isActive"
                type="checkbox"
                class="form-check-input"
                id="isActive"
              />
              <label class="form-check-label" for="isActive">
                {{ 'settings.lifecycles.card.props.state.isActive.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="'settings.lifecycles.card.props.state.isActive.hint' | translate"
              ></i>
            </div> -->
            <div class="form-check ms-3">
              <input
                formControlName="isEntityDisabled"
                type="checkbox"
                class="form-check-input"
                id="isEntityDisabled"
              />
              <label class="form-check-label" for="isEntityDisabled">
                {{ 'settings.lifecycles.card.props.state.isEntityDisabled.label' | translate }}
              </label>
              <i
                class="group-hint bi bi-question-circle text-gray ms-auto"
                [ngbTooltip]="
                  'settings.lifecycles.card.props.state.isEntityDisabled.hint' | translate
                "
              ></i>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group flex">
              <label>{{ 'settings.lifecycles.card.props.state.style' | translate }}</label>
              <div ngbDropdown>
                <span
                  ngbDropdownToggle
                  class="badge"
                  [ngClass]="'text-bg-' + form.controls.style.value"
                  >{{
                    form.controls.name.value ? form.controls.name.value : form.controls.style.value
                  }}
                </span>
                <div ngbDropdownMenu style="min-width: fit-content">
                  <div class="btn-list">
                    <button
                      ngbDropdownItem
                      *ngFor="let style of stateStyles"
                      class="badge m-1 ms-2 me-2"
                      [ngClass]="'text-bg-' + style"
                      (click)="setStyle(style)"
                    >
                      {{ form.controls.name.value ? form.controls.name.value : style }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <h3>{{ 'settings.lifecycles.card.props.state.transitions' | translate }}</h3>
            <hr class="m-0" />
            <wp-grid
              [formArray]="form.controls.transitions"
              [options]="gridOptions"
              class="d-block"
            >
            </wp-grid>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
