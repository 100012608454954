import { RESOURCE_REQUEST_LIST } from '../lists/resource-request.list';
import { Navigation } from '../models/navigation/navigation';

export const RESOURCE_APP_NAVIGATION: Navigation = {
  name: 'resources',
  applicationTitle: 'resources.navigation.header',
  groups: [
    {
      name: 'resources',
      header: 'resources.navigation.resources.header',
      items: [
        {
          name: 'resources.booking',
          state: 'booking',
          header: 'resources.navigation.resources.booking.header',
          hint: 'resources.navigation.resources.booking.hint',
        },
        {
          name: 'resources.resources',
          state: 'resources',
          header: 'resources.navigation.resources.allResources.header',
          hint: 'resources.navigation.resources.allResources.hint',
        },
      ],
    },
    {
      name: 'requests',
      header: 'resources.navigation.requests.header',
      items: [
        {
          name: 'resources.openRequests',
          state: 'resourceRequests',
          header: 'resources.navigation.requests.active.header',
          hint: 'resources.navigation.requests.active.hint',
          indicator: {
            hint: 'resources.navigation.requests.active.indicator',
            list: RESOURCE_REQUEST_LIST,
            viewName: 'open',
          },
          stateParams: {
            view: 'open',
          },
        },
        {
          name: 'resources.requestsWithTask',
          state: 'resourceRequests',
          header: 'resources.navigation.requests.withTask.header',
          hint: 'resources.navigation.requests.withTask.hint',
          indicator: {
            hint: 'resources.navigation.requests.withTask.indicator',
            list: RESOURCE_REQUEST_LIST,
            viewName: 'withTask',
          },
          stateParams: {
            view: 'withTask',
          },
        },
        {
          name: 'resources.allRequests',
          state: 'resourceRequests',
          header: 'resources.navigation.requests.all.header',
          hint: 'resources.navigation.requests.all.hint',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'analytics',
      header: 'resources.navigation.analytics.header',
      items: [
        {
          name: 'resources.projectsSummary',
          state: 'projectsSummary',
          header: 'resources.navigation.analytics.projectSummary.header',
          hint: 'resources.navigation.analytics.projectSummary.hint',
        },
        {
          name: 'resources.usersSummary',
          state: 'usersSummary',
          header: 'resources.navigation.analytics.resourceSummary.header',
          hint: 'resources.navigation.analytics.resourceSummary.hint',
        },
      ],
    },
  ],
};
