import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldConfiguration } from 'src/app/shared/models/entities/settings/custom-field-configuration.model';
import { CustomFieldFormColumn } from 'src/app/shared/models/enums/custom-field-form-column.enum';
import { sortBy } from 'lodash';

export type CustomFieldMode = 'default' | 'filter';

@Component({
  selector: 'wp-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  @Input() entityType: CustomFieldEntityType;
  @Input() fields: CustomFieldConfiguration[];
  @Input() columnClasses = 'col-6';
  @Input() formGroup: UntypedFormGroup;
  @Input() isVisibleHr = true;
  @Input() mode: CustomFieldMode = 'default';

  constructor(private app: AppService) {}

  public getLeftFields(): CustomFieldConfiguration[] {
    return sortBy(
      this.fields.filter((f) => f.formColumn === CustomFieldFormColumn.Left),
      ['orderNumber'],
    );
  }

  public getRightFields(): CustomFieldConfiguration[] {
    return sortBy(
      this.fields.filter((f) => f.formColumn === CustomFieldFormColumn.Right),
      ['orderNumber'],
    );
  }

  public getControl(field: CustomFieldConfiguration): UntypedFormControl {
    return this.formGroup.controls[field.dataField] as UntypedFormControl;
  }

  public getLabel(field: CustomFieldConfiguration): string {
    return field.localizationStrings.find(
      (s) => s.culture === this.app.session.language,
    ).label;
  }

  public ngOnInit(): void {
    if (this.entityType) {
      this.fields = this.app.session.configuration.customFields.filter(
        (f) =>
          f.entityType === this.entityType &&
          (this.mode === 'default'
            ? f.isShownInEntityForms
            : f.isShownInEntityListFilters),
      );
    }
  }
}
