import {
  Component,
  OnInit,
  Input,
  Injector,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';
import { IssueCardService } from 'src/app/issues/card/issue-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { tap } from 'rxjs';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';

@Component({
  selector: 'tmt-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
  providers: [
    { provide: ENTITY_COLLECTION, useValue: 'Issues' },
    LifecycleService,
    IssueCardService,
    SavingQueueService,
    FormHeaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCardComponent implements OnInit {
  @Input() entityId: string;

  public activeTab: string;

  protected readonly commentedEntityCollectionType =
    CommentedEntityCollectionType;

  /**
   * Saves issue name.
   *
   * @param name issue name.
   */
  public saveName = (name: string) =>
    this.dataService
      .collection('Issues')
      .entity(this.entityId)
      .patch({ name })
      .pipe(tap(() => this.cdr.markForCheck()));

  constructor(
    public issueCardService: IssueCardService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private lifecycleService: LifecycleService,
    private actionPanelService: ActionPanelService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.setHasAutosave(true);
    this.issueCardService.load();
  }

  /**
   * Opens created by user info.
   *
   * @param target target element for info popup service.
   */
  public openCreatedByUserInfo(target: ElementRef): void {
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId: this.issueCardService.issue.createdBy.id,
        },
        injector: this.injector,
      },
    });
  }
}
