<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

@if (!isLoading) {
  <form class="modal-form" novalidate ngDraggable [formGroup]="actionForm">
    <div class="modal-header">
      <h3 class="modal-title">
        {{ 'settings.workflows.card.workflowForm.header' | translate }}
      </h3>
      <button
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
        [disabled]="isSaving"
      ></button>
    </div>
    <div class="modal-body">
      @for (control of actionProperties; track control.name) {
        <div class="form-group">
          <div [formGroup]="actionForm">
            <label class="control-label">{{ getTransitionPropertyName(control.name) }}</label>
            <label class="control-label"></label>
            @switch (control.type) {
              @case (TransitionFormPropertyType.date) {
                <wp-date-box formControlName="{{ control.name }}" />
              }
              @case (TransitionFormPropertyType.string) {
                <wp-text-box formControlName="{{ control.name }}" />
              }
              @case (TransitionFormPropertyType.integer) {
                <wp-number-box type="integer" formControlName="{{ control.name }}" />
              }
              @case (TransitionFormPropertyType.navigationId) {
                <wp-select-box
                  [values]="(collectionValues$ | async)?.[control.entityType]"
                  formControlName="{{ control.name }}"
                />
              }
            }
          </div>
        </div>
      }
      @if (requestComment) {
        <div class="form-group">
          <label class="control-label">{{
            'settings.lifecycles.card.transitionForm.comment' | translate
          }}</label>
          <wp-multiline-text-box formControlName="comment" />
        </div>
      }
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        (click)="ok()"
        wpButtonSpinner
        [isBusy]="isSaving"
      >
        {{ 'settings.workflows.card.action.perform' | translate }}
      </button>

      <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
