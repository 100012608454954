import { Injectable, Inject, Optional } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  CustomFieldConfiguration,
  CustomFieldLocalization,
} from 'src/app/shared/models/entities/settings/custom-field-configuration.model';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import {
  GridColumnType,
  GridComponentColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { List } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { View } from 'src/app/shared/models/inner/view';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';

import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { TaskCollapsibleCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-cell.component';
import { TaskCollapsibleReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-readonly-cell.component';

@Injectable()
export class ProjectTasksListService extends ListService {
  constructor(
    @Inject(LIST) list: List,
    @Inject(VIEW_NAME) viewName: string,
    app: AppService,
    modalService: NgbModal,
    @Optional() filterService: FilterService,
  ) {
    super(list, viewName, app, filterService, modalService);
  }

  /** Расширяет текущий список и все его представления дополнительными колонками (полями). */
  public override extendListWithCustomFields(): void {
    const fields = this.app.session.configuration.customFields.filter(
      (field: CustomFieldConfiguration) =>
        field.entityType === CustomFieldEntityType.ProjectTask &&
        field.isShownInEntityLists,
    );

    // Добавляем колонки данных.
    fields.forEach((field: CustomFieldConfiguration) => {
      if (this.list.columns.find((c) => c.name === field.dataField)) {
        return;
      }

      const name = field.dataField;
      const local = field.localizationStrings.find(
        (row: CustomFieldLocalization) =>
          row.culture === this.app.session.language,
      ).label;

      switch (field.type) {
        case CustomFieldType.decimal:
        case CustomFieldType.integer:
          this.list.columns.push(<GridComponentColumn>{
            name,
            type: GridColumnType.Component,
            contentType:
              field.type === CustomFieldType.decimal
                ? GridColumnType.Decimal
                : GridColumnType.Integer,
            component: TaskCollapsibleCellComponent,
            readonlyComponent: TaskCollapsibleReadonlyCellComponent,
            fixedWidth: true,
            header: local,
            hint: local,
            availableTotals: [TotalType.Sum],
          });
          break;
        case CustomFieldType.string:
          this.list.columns.push(<GridStringControlColumn>{
            name,
            type: GridColumnType.StringControl,
            fixedWidth: true,
            header: local,
            hint: local,
            forceCellUpdating: true,
            propagationMode: PropagationMode.onExitFromEditing,
          });
          break;
        case CustomFieldType.date:
          this.list.columns.push({
            name,
            type: GridColumnType.DateControl,
            fixedWidth: true,
            header: local,
            hint: local,
            forceCellUpdating: true,
          });
          break;
        case CustomFieldType.lookup:
          this.list.columns.push(<GridSelectControlColumn>{
            name,
            header: local,
            hint: local,
            type: GridColumnType.SelectControl,
            fixedWidth: true,
            query: {
              filter: [{ customFieldId: { type: 'guid', value: field.id } }],
            },
            collection: 'LookupValues',
            forceCellUpdating: true,
          });
          break;
        default:
          return;
      }

      this.list.views.forEach((view: View) => {
        if (view.columns.find((c) => c.column === name)) {
          return;
        }

        view.columns.push({
          column: name,
          width: 120,
          totalByDefault: TotalType.Sum,
          visibleByDefault: false,
        });
      });
    });
  }
}
