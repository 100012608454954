import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { LogService } from 'src/app/core/log.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { Constants } from 'src/app/shared/globals/constants';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { Certificate } from 'src/app/shared/models/entities/certificates/certificate.model';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-certificate-card',
  templateUrl: './certificate-card.component.html',
  styleUrls: ['./certificate-card.component.scss'],
  providers: [
    { provide: ENTITY_COLLECTION, useValue: 'Certificates' },
    LifecycleService,
    SavingQueueService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  public certificateEntity: Certificate;

  public readonly = false;

  public activeTab: string;

  public usersQuery = {
    filter: {
      isActive: true,
    },
  };

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    owner: [null, Validators.required],
    effectiveDate: [null, Validators.required],
    expiryDate: null,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  private certificateQuery = {
    select: [
      'id',
      'name',
      'ownerId',
      'effectiveDate',
      'expiryDate',
      'editAllowed',
    ],
    expand: [{ owner: { select: ['id', 'name'] } }],
  };

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  private certificateReloaded$ = new Subject<void>();

  constructor(
    public savingQueueService: SavingQueueService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private customFieldService: CustomFieldService,
    private lifecycleService: LifecycleService,
    private navigationService: NavigationService,
    private notification: NotificationService,
    private logService: LogService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.customFieldService.enrichFormGroup(
      this.form,
      CustomFieldEntityType.Certificate,
    );
  }

  ngOnInit(): void {
    this.loadCertificate();
    this.actionService.setHasAutosave(true);

    FormHelper.controlDatePair(
      this.form,
      'effectiveDate',
      'expiryDate',
      takeUntil(this.destroyed$),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.certificateReloaded$.next();
  }

  public saveName = (name: string) =>
    this.data
      .collection('Certificates')
      .entity(this.entityId)
      .patch({ name })
      .pipe(tap(() => this.changeDetector.markForCheck()));

  private reload() {
    this.savingQueueService.save().then(
      () => {
        this.loadCertificate();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  private loadCertificate() {
    this.certificateReloaded$.next();
    this.state$.next(CardState.Loading);

    this.customFieldService.enrichQuery(
      this.certificateQuery,
      CustomFieldEntityType.Certificate,
    );

    this.data
      .collection('Certificates')
      .entity(this.entityId)
      .get<Certificate>(this.certificateQuery)
      .subscribe({
        next: (certificate: Certificate) => {
          this.certificateEntity = certificate;
          this.form.patchValue(this.certificateEntity);
          this.changeDetector.markForCheck();

          this.initCardSubscriptions();

          this.navigationService.addRouteSegment({
            id: this.certificateEntity.id,
            localTitle: this.certificateEntity.name,
          });

          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.certificateEntity.editAllowed
            ? this.form.enable({ emitEvent: false })
            : this.form.disable({ emitEvent: false });

          this.state$.next(CardState.Ready);
          this.changeDetector.markForCheck();
        },
        error: (error: Exception) => {
          this.logService.error(error.message);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
          this.state$.next(CardState.Error);
          this.changeDetector.markForCheck();
        },
      });
  }

  /**
   * Inits Card level subscriptions.
   * */
  private initCardSubscriptions() {
    this.form.valueChanges
      .pipe(takeUntil(this.certificateReloaded$))
      .subscribe(() => this.save());

    this.actionService.reload$
      .pipe(takeUntil(this.certificateReloaded$))
      .subscribe(() => {
        this.reload();
      });

    this.lifecycleService.reload$
      .pipe(takeUntil(this.certificateReloaded$))
      .subscribe(() => {
        this.reload();
      });
  }

  private save(): void {
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const formValue = this.form.value;

    const certificate = {
      id: this.entityId,
      name: formValue.name,
      ownerId: formValue.owner.id,
      expiryDate: formValue.expiryDate,
      effectiveDate: formValue.effectiveDate,
    };

    this.customFieldService.assignValues(
      certificate,
      formValue,
      CustomFieldEntityType.Certificate,
    );

    this.savingQueueService.addToQueue(
      this.entityId,
      this.data
        .collection('Certificates')
        .entity(this.entityId)
        .update(certificate),
    );
  }
}
