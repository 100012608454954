/* eslint-disable @typescript-eslint/naming-convention */
import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CustomField } from 'src/app/shared/models/entities/settings/custom-field.model';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { CustomFieldConfiguration } from 'src/app/shared/models/entities/settings/custom-field-configuration.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import humps from 'lodash-humps';
import { CustomFieldFormColumn } from 'src/app/shared/models/enums/custom-field-form-column.enum';
import { isObject, uniq } from 'lodash';
import { CustomFieldCardService } from './custom-field-card.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-custom-field-card',
  templateUrl: './custom-field-card.component.html',
  providers: [CustomFieldCardService],
})
export class CustomFieldCardComponent implements OnInit {
  @Input() entityId: string;

  public state: CardState;
  public isSaving = false;
  private destroyRef = inject(DestroyRef);

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
    localStrings: this.fb.array([]),
    configuration: this.fb.group({
      lookupValues: this.fb.array([]),
      isRequired: [false],
      formColumn: [null],
      orderNumber: [null],
      defaultValue: [null],
    }),
    isShownInEntityListFilters: [false],
    isShownInEntityLists: [false],
    isShownInEntityForms: [false],
    isShownInReports: [false],
    isOnlyForApi: [false],
  });
  formColumns: NamedEntity[] = [];

  public get localStrings(): UntypedFormArray {
    return this.form.controls['localStrings'] as UntypedFormArray;
  }
  public get configuration(): UntypedFormGroup {
    return this.form.controls['configuration'] as UntypedFormGroup;
  }

  field: CustomField;
  readonly: boolean;
  type: string;
  entityType: string;
  languages: NamedEntity[];

  constructor(
    private fb: UntypedFormBuilder,
    private cardService: CustomFieldCardService,
    private translate: TranslateService,
    private data: DataService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
    private message: MessageService,
    private notification: NotificationService,
  ) {
    actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
  }

  // Загрузка данных.
  private load = () => {
    this.form.reset();
    this.localStrings.clear();
    (this.configuration.controls['lookupValues'] as UntypedFormArray).clear();

    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    const query = { expand: [{ lookupValues: { orderBy: 'name' } }] };

    // Загрузка данных.
    this.data
      .collection('CustomFields')
      .entity(this.entityId)
      .get<CustomField>(query)
      .subscribe({
        next: (field) => {
          this.form.patchValue(field);

          this.navigationService.addRouteSegment({
            id: field.id,
            title: field.name,
          });

          this.readonly = !field.editAllowed;
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.readonly ? this.form.disable() : this.form.enable();
          this.actionService.action('save').isShown = !this.readonly;

          this.field = field;
          this.type = this.translate.instant(
            'enums.customFieldType.' + field.type.toLowerCase(),
          );
          this.entityType = this.translate.instant(
            'enums.customFieldEntityType.' + field.entityType,
          );

          // Десериализация конфигурации.
          let configuration: CustomFieldConfiguration = JSON.parse(
            field.configuration,
          );
          configuration = humps(configuration);

          this.configuration.patchValue(configuration);

          // Добавить в форму варианты lookup-поля.
          field.lookupValues.forEach((lookupValue) => {
            (
              this.configuration.controls['lookupValues'] as UntypedFormArray
            ).push(
              this.fb.group({
                id: [lookupValue.id],
                name: [
                  lookupValue.name,
                  [
                    Validators.required,
                    Validators.maxLength(Constants.formNameMaxLength),
                  ],
                ],
                code: [lookupValue.code],
                isActive: [lookupValue.isActive],
              }),
            );
          });

          // Загрузка языков
          this.data.model
            .function('GetLanguages')
            .query<NamedEntity[]>()
            .subscribe((languages) => {
              this.languages = languages;
              configuration.localizationStrings.forEach((localString) => {
                const lang = languages.find(
                  (l) => l.id === localString.culture,
                );
                this.localStrings.push(
                  this.fb.group({
                    id: [Guid.generate()],
                    language: [lang, Validators.required],
                    label: [localString.label, Validators.required],
                    placeholder: [localString.placeholder],
                  }),
                );
              });

              if (configuration.formColumn) {
                this.configuration
                  .get('formColumn')
                  .setValue(
                    this.formColumns.find(
                      (fc) => fc.id === configuration.formColumn,
                    ),
                  );
              }
              if (
                field.type === CustomFieldType.lookup &&
                configuration.defaultValue
              ) {
                this.configuration
                  .get('defaultValue')
                  .setValue(
                    field.lookupValues.find(
                      (v) => v.id === configuration.defaultValue,
                    ),
                  );
              }
              this.state = CardState.Ready;
            });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  // Сохранение данных.
  public save = (): void => {
    this.form.markAllAsTouched();
    this.cardService.detectChanges();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const formData = this.form.value;

    // Проверить уникальность строк локализации.
    const localStrings = formData.localStrings as any[];
    if (
      uniq(localStrings.map((ls) => ls.language.id)).length !==
      localStrings.length
    ) {
      this.notification.warningLocal(
        'settings.customFields.card.messages.languageMustBeUnique',
      );
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const data: any = {
      id: this.entityId,
      name: formData.name,
      description: formData.description,
      isActive: formData.isActive,
      lookupValues: formData.configuration.lookupValues,
      isShownInEntityListFilters: formData.isShownInEntityListFilters,
      isShownInEntityLists: formData.isShownInEntityLists,
      isShownInEntityForms: formData.isShownInEntityForms,
      isShownInReports: formData.isShownInReports,
      isOnlyForApi: formData.isOnlyForApi,
    };

    // Сериализация конфигурации.
    const cfg = {
      EntityType: this.field.entityType,
      DefaultValue: isObject(formData.configuration.defaultValue)
        ? formData.configuration.defaultValue.id
        : //TODO Temp solution with toString() for server deserialization number to string
          formData.configuration.defaultValue?.toString(),
      IsRequired: formData.configuration.isRequired,
      OrderNumber: formData.configuration.orderNumber,
      FormColumn: formData.configuration.formColumn
        ? formData.configuration.formColumn.id
        : null,
      LocalizationStrings: [],
    };

    (<any[]>formData.localStrings).forEach((localString) => {
      cfg.LocalizationStrings.push({
        Culture: localString.language.id,
        Label: localString.label,
        Placeholder: localString.placeholder,
      });
    });

    data.configuration = JSON.stringify(cfg);

    this.data
      .collection('CustomFields')
      .entity(this.entityId)
      .update(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.customFields.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    // Создать список доступных колонок для размещения поля.
    for (const key of Object.keys(CustomFieldFormColumn)) {
      this.formColumns.push({
        id: key,
        name: this.translate.instant('enums.customFieldFormColumn.' + key),
      });
    }

    this.load();
  }
}
