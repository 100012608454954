import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import {
  Language,
  LocalString,
} from 'src/app/shared/models/enums/language.enum';

@Component({
  selector: 'tmt-metamodel-description',
  standalone: true,
  imports: [],
  templateUrl: './metamodel-description.component.html',
  styleUrl: './metamodel-description.component.scss',
})
export class MetamodelDescriptionComponent implements OnInit {
  public entities: MetaEntity[];

  public language = Language;

  constructor(private data: DataService) {}

  ngOnInit(): void {
    this.data
      .collection('MetaEntities')
      .query<MetaEntity[]>()
      .subscribe((entities) => {
        this.entities = entities;
      });
  }

  public joinString(strings: string[]): string {
    return strings.join(', ');
  }

  public getFormattedLocalStrings(localStrings: LocalString[]): string {
    return localStrings.map((x) => `${x.language}: ${x.value}`).join(', ');
  }
}
