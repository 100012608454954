import MarkdownIt from 'markdown-it';

export const md = MarkdownIt('commonmark', { html: false });

const defaultTextRenderer =
  md.renderer.rules.text ||
  function (tokens, idx, options, env, self) {
    return self.renderInline(tokens, idx, options);
  };

md.renderer.rules.mention = function (tokens, idx, options, env, self) {
  return `<span class="mention">${defaultTextRenderer(tokens, idx, options, env, self)}</span>`;
};

md.inline.ruler.push('mention', function (state, silent) {
  let pos = state.pos;
  const start = pos;

  if (pos > 0 && !/[,;!?.\s]/.test(state.src.charAt(pos - 1))) {
    return false;
  }

  if (state.src.charCodeAt(pos) === 0x40) {
    pos++;

    while (pos < state.posMax && /[a-zA-Z0-9_]/.test(state.src.charAt(pos))) {
      pos++;
    }

    if (pos > start + 1) {
      if (!silent) {
        const token = state.push('mention', '', 0);
        token.content = state.src.slice(start, pos);
      }

      state.pos = pos;
      return true;
    }
  }

  return false;
});
