<wp-loading-indicator [loading]="isLoading"></wp-loading-indicator>

<form class="modal-form" novalidate ngDraggable [hidden]="isLoading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.coManagersForm.header' | translate }}
    </h3>

    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'shared.coManagersForm.manager' | translate }}</label>
      <div class="trim avatar-cell" title="{{ manager.name }}" style="line-height: 30px">
        <img class="avatar-bg" [src]="manager.id | avatar: 60" />{{ manager.name }}
      </div>
    </div>
    <hr style="margin: 0" />

    <div class="toolbar-container">
      <button
        *ngIf="!readonly"
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.addLine' | translate }}"
        (click)="addLine()"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared.actions.addLine' | translate }}
      </button>
    </div>
    <wp-grid [formArray]="formArray" [options]="gridOptions" [readonly]="readonly"></wp-grid>
  </div>

  <div class="modal-footer">
    <button
      *ngIf="!readonly"
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
