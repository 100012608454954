export interface TransitionForm {
  hasTransitionForm: boolean;
  requestComment: boolean;
  commentIsRequired: boolean;
  requestedProperties: TransitionFormProperty[];
}

export interface TransitionFormProperty {
  name: string;
  type: string;
  isRequired: boolean;
}

export interface TransitionFormDescription {
  transitionFormPropertyValues: GetTransitionFormPropertyValue[];
  requestComment: boolean;
  commentIsRequired: boolean;
}

export interface GetTransitionFormPropertyValue {
  name: string;
  isRequired: boolean;
  value: string;
  type: TransitionFormPropertyType;
  entityType: string;
}

export enum TransitionFormPropertyType {
  string = 'String',
  integer = 'Integer',
  decimal = 'Decimal',
  date = 'Date',
  bool = 'Bool',
  navigationId = 'NavigationId',
}
