<div class="row">
  <div class="col-xs-12 col-lg-10">
    <label class="group">
      {{ 'settings.customFields.card.props.groupLocalStrings' | translate }}
    </label>
    <div class="toolbar-container pt-0">
      <button
        *ngIf="!readonly"
        type="button"
        class="btn btn-default"
        title="{{ 'shared.actions.add' | translate }}"
        (click)="addString()"
      >
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared.actions.add' | translate }}
      </button>
    </div>

    <wp-grid [formArray]="localStrings" [options]="gridOptions" [readonly]="readonly"></wp-grid>
  </div>
</div>
