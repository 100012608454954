<label class="group">{{ 'settings.permissionSets.granules.groupData' | translate }}</label>
<table class="table table-bordered compact-table" style="width: 500px; table-layout: fixed">
  <thead>
    <tr>
      <th style="width: 300px; vertical-align: middle" rowspan="2">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center" colspan="2">
        {{ 'settings.permissionSets.scopes.my' | translate }}
      </th>
    </tr>
    <tr>
      <td style="text-align: center">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </td>
      <td style="text-align: center">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </td>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.report' | translate }}
        <span
          class="bi bi-question-circle"
          ngbTooltip="{{ 'settings.permissionSets.granules.editMyReportHelp' | translate }}"
        ></span>
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Report.MyAndCommon.viewEnabled')"
          type="checkbox"
        />
      </td>

      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Report.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.dashboard' | translate }}</td>
      <td></td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Dashboard.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.deputy' | translate }}</td>
      <td></td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSubstitute.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.timesheet' | translate }}</td>
      <td colspan="2" class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheet.My.viewEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr *ngIf="app.checkFeature(feature.timeOff)">
      <td>
        {{ 'settings.permissionSets.granules.timeOffRequest' | translate }}
      </td>
      <td colspan="2" class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffRequest.My.viewEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr *ngIf="app.checkFeature(feature.expenses)">
      <td>
        {{ 'settings.permissionSets.granules.expenseRequest' | translate }}
      </td>
      <td colspan="2" class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseRequest.My.viewEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr *ngIf="app.checkFeature(feature.booking)">
      <td>{{ 'settings.permissionSets.granules.resourcePlan' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourcePlanEntry.My.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.comment' | translate }}</td>
      <td></td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Comment.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.skills' | translate }}</td>
      <td></td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSkill.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.certificates' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.My.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Certificate.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.issues' | translate }}</td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Issue.My.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('Issue.My.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>

<label class="group">{{ 'settings.permissionSets.granules.groupActions' | translate }}</label>
<table class="table table-bordered compact-table" style="width: 500px; table-layout: fixed">
  <thead>
    <tr>
      <th style="width: 300px; vertical-align: middle">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.scopes.my' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.dashboardPublish' | translate }}
      </td>
      <td style="text-align: center">
        <input
          class="form-check-input"
          [formControl]="form.get('DashboardPublish.My.actionEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>
