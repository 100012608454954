import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import {
  GridColumnType,
  GridSelectControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';

import { TransitionsService } from 'src/app/settings-app/lifecycle/card/transitions.service';
import { TransitionFormService } from './transition-form.service';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';

@Component({
  selector: 'wp-transition-form',
  templateUrl: './transition-form.component.html',
  styleUrls: ['./transition-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitionFormComponent implements OnInit, OnDestroy {
  @Input() transitionFormFormGroup: UntypedFormGroup;

  @Input() lifecycleId: string;

  private destroyed$ = new Subject<void>();

  public get requestedProperties(): UntypedFormArray {
    return this.transitionFormFormGroup.controls[
      'requestedProperties'
    ] as UntypedFormArray;
  }

  constructor(
    public gridService: GridService,
    private lifecycleCardService: LifecycleCardService,
    private transitionsService: TransitionsService,
    private transitionFormService: TransitionFormService,
  ) {}

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    commands: [
      {
        name: 'create',
        handlerFn: () => this.addProperty(),
      },
      {
        name: 'delete',
        handlerFn: () => this.deleteProperty(),
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup, index: number) =>
          this.deleteProperty(index),
      },
    ],
    view: {
      name: 'strings',
      columns: [
        <GridSelectControlColumn>{
          name: 'nameControl',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.SelectControl,
          placeholder: 'shared.columns.name',
          values: this.transitionFormService.allowedTransitionFormProperties$,
        },
        {
          name: 'isRequired',
          header:
            'settings.lifecycles.card.props.transition.form.columns.isRequired.header',
          hint: 'settings.lifecycles.card.props.transition.form.columns.isRequired.hint',
          type: GridColumnType.BooleanControl,
          placeholder:
            'settings.lifecycles.card.props.transition.form.columns.isRequired.header',
        },
      ],
    },
  };

  /** Добавить свойство. */
  addProperty() {
    const newGroup = this.transitionsService.getRequestedPropertyFormGroup();
    this.requestedProperties.push(newGroup);
    this.gridService.selectGroup(newGroup);
  }

  /** Удалить свойство. */
  deleteProperty(index?: number) {
    const requestedProperties = this.transitionFormFormGroup.controls
      .requestedProperties as FormArray;
    index =
      index ??
      requestedProperties.value.findIndex(
        (s) => s.id === this.gridService.selectedGroup.value.id,
      );

    requestedProperties.removeAt(index);
    requestedProperties.markAsDirty();
  }

  ngOnInit(): void {
    this.lifecycleCardService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.gridService.detectChanges());
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
