<div
  class="board__column"
  [class.board__column--alert]="isShowAlert"
  [tmtDragAndDrop]="cards"
  [tmtDragAndDropOptions]="ddOptions"
  [dragDisabled]="dragDisabled"
  [dropDisabled]="dropDisabled"
>
  @if (isShowAlert) {
    <div class="alert alert-danger">
      {{ 'team.tasksBoard.moveAlert' | translate }}
    </div>
  }

  @for (card of cards; track card.id) {
    <tmt-board-mini-card [card]="card" class="{{ ddOptions.draggableClass }}" [index]="$index" />
  }
</div>
