import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';
import { CustomFieldType } from 'src/app/shared/models/enums/custom-field-type.enum';
import { camelCase } from 'lodash';
import { CustomFieldConfiguration } from 'src/app/shared/models/entities/settings/custom-field-configuration.model';

/** Сервис работы с дополнительными полями. */
@Injectable({
  providedIn: 'root',
})
export class CustomFieldService {
  constructor(private app: AppService) {}

  /** Add custom-field controls in a `FormGroup`
   *
   * @param formGroup - FormGroup.
   * @param entityType - Type of entity.
   * @param disableValidators - Force disable validation of fields.
   * @param disabled - Control will be disabled.
   */
  public enrichFormGroup(
    formGroup: UntypedFormGroup,
    entityType: CustomFieldEntityType,
    disableValidators = false,
    disabled = false,
  ) {
    const fields = this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
    fields.forEach((f) => {
      const control = new UntypedFormControl({ value: null, disabled });
      if (f.isRequired && !disableValidators) {
        control.setValidators(Validators.required);
      }

      formGroup.addControl(f.dataField, control);
    });
  }

  /** Заполнить форму значениями по умолчаниями по списку дополнительных полей. */
  public enrichFormGroupWithDefaultValues(
    formGroup: UntypedFormGroup,
    entityType: CustomFieldEntityType,
  ) {
    const fields = this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
    fields.forEach((f) => {
      const control = formGroup.controls[f.dataField];

      if (f.defaultValue) {
        switch (f.type) {
          case CustomFieldType.decimal:
            control.setValue(parseFloat(f.defaultValue));
            break;
          case CustomFieldType.integer:
            // eslint-disable-next-line radix
            control.setValue(parseInt(f.defaultValue));
            break;
          case CustomFieldType.lookup:
            control.setValue(f.lookupDefaultValue);
            break;
          default:
            control.setValue(f.defaultValue);
        }
      }

      formGroup.addControl(f.dataField, control);
    });
  }

  /** Добавить query запрос дополнительных полей. */
  public enrichQuery(query: any, entityType: CustomFieldEntityType) {
    if (!query.select) {
      query.select = [];
    }
    if (!query.expand) {
      query.expand = [];
    }

    const fields = this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
    fields.forEach((field) => {
      const dataField = field.dataField;
      if (field.type === CustomFieldType.lookup) {
        const expand: any = {};
        expand[dataField] = { select: ['id', 'name'] };
        query.expand.push(expand);
      } else {
        query.select.push(dataField);
      }
    });
  }

  /** Копирует свойства источника в приемник по списку дополнительных полей. */
  public assignValues(
    target: any,
    source: any,
    entityType: CustomFieldEntityType,
    assignLookupValues = false,
  ) {
    const fields = this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
    fields.forEach((field) => {
      const dataField = field.dataField;
      if (field.type === CustomFieldType.lookup) {
        target[dataField + 'Id'] = source[dataField]
          ? source[dataField].id
          : null;

        if (assignLookupValues) {
          target[dataField] = source[dataField];
        }
      } else {
        target[dataField] = source[dataField];
      }
    });
  }

  /** Changes changed custom fields names for names which can be patchable.
   *
   * @param fieldsToCorrect fields for correcting.
   * @param entityType custom field entity type.
   * @returns changed list of field names.
   */
  public changeFieldsToPatch(
    fieldsToCorrect: string[],
    entityType: CustomFieldEntityType,
  ): string[] {
    const customFields = this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
    const lookupCustomFieldNames = customFields
      .filter((customField) => customField.type === CustomFieldType.lookup)
      .map((lookupField) => camelCase(lookupField.dataField));

    const correctedFields = fieldsToCorrect.map((field) =>
      lookupCustomFieldNames.includes(field) ? field + 'Id' : field,
    );
    return correctedFields;
  }

  /** Returns additional field list. */
  public getList(
    entityType: CustomFieldEntityType,
  ): CustomFieldConfiguration[] {
    return this.app.session.configuration.customFields.filter(
      (f) => f.entityType === entityType,
    );
  }
}
