export enum AppName {
  My = 'my',
  Team = 'team',
  Projects = 'projects',
  Resources = 'resources',
  Billing = 'billing',
  Finance = 'finance',
  Analytics = 'analytics',
  Settings = 'settings',
  Clients = 'clients',
}
