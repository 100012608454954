import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { IssueType } from 'src/app/settings-app/issue-types/model/issue-type.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';

@Injectable()
export class IssueTypeCardService {
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
    isDefault: false,
  });

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();

  private issueTypeSubject = new ReplaySubject<IssueType>();
  public issueType$ = this.issueTypeSubject.asObservable();

  constructor(
    @Inject('entityId') public entityId: string,
    private dataService: DataService,
    private notificationService: NotificationService,
    private actionPanelService: ActionPanelService,
    private navigationService: NavigationService,
    private fb: UntypedFormBuilder,
    private blockUIService: BlockUIService,
    private messageService: MessageService,
  ) {}

  /** Loads issueType. */
  public load(): void {
    this.state$.next(CardState.Loading);

    const query = {
      select: ['id', 'name', 'isActive', 'editAllowed'],
    };

    this.dataService
      .collection('issueTypes')
      .entity(this.entityId)
      .get<IssueType>(query)
      .subscribe({
        next: (issueType: IssueType) => {
          this.state$.next(CardState.Ready);
          this.actionPanelService.action('save').isShown =
            issueType.editAllowed;

          this.nameSubject.next(issueType.name);
          this.issueTypeSubject.next(issueType);

          this.navigationService.addRouteSegment({
            id: issueType.id,
            title: issueType.name,
          });
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notificationService.error(error.message);
        },
      });
  }

  /** Updates issueType name subject. */
  public updateName(value: any): void {
    this.nameSubject.next(value);
  }

  /** Saves Data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.blockUIService.start();
    this.actionPanelService.action('save').start();
    const issueType = <IssueType>this.form.value;

    const data = {
      name: issueType.name,
      isActive: issueType.isActive,
    };

    this.dataService
      .collection('IssueTypes')
      .entity(this.entityId)
      .patch(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving$.next(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal(
            'components.issueTypeCardService.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving$.next(false);
          this.blockUIService.stop();
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Reloads data. */
  public reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.form.markAsPristine();
          this.load();
        },
        () => null,
      );
    }
  }
}
