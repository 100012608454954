import { NgModule } from '@angular/core';
import { TimeOffRequestListComponent } from './list/time-off-request-list.component';
import { TimeOffRequestFilterComponent } from './list/filter/time-off-request-filter.component';
import { TimeOffPeriodComponent } from './shared/time-off-period/time-off-period.component';
import { TimeOffPeriodModalComponent } from './card/time-off-period-modal/time-off-period-modal.component';
import { IntersectedTimesheetsComponent } from './shared/intersected-timesheets-modal/intersected-timesheets-modal.component';
import { TimeOffRequestInfoComponent } from './time-off-request-info/time-off-request-info.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { TimeOffCardComponent } from './card/time-off-request-card.component';
import { TimeOffCreationComponent } from './creation/time-off-creation.component';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  ENTITY_COLLECTION,
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { TIME_OFF_LIST } from 'src/app/shared/lists/time-off.list';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';

/** Модуль реализует функциональность таймшитов, заявок на отсутствия и заявок на затраты,
 * используемую приложениями MyApp и TeamApp.
 */
@NgModule({
  declarations: [
    TimeOffRequestListComponent,
    TimeOffRequestFilterComponent,
    TimeOffCardComponent,
    TimeOffPeriodComponent,
    TimeOffPeriodModalComponent,
    TimeOffRequestInfoComponent,
    TimeOffCreationComponent,
    IntersectedTimesheetsComponent,
  ],

  imports: [
    CommonModule,
    SharedModule,
    BaseSharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'timeOffRequest',
          url: '/time-off/{entityId:guid}?{navigation}&{route}',
          params: {
            routeMode: null,
            navigation: 'my.timeOffRequestsAll',
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: TimeOffCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'timeOffRequests',
          url: '/time-off/{view:viewName}?{navigation}&{route}',
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: TIME_OFF_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            { provide: ENTITY_COLLECTION, useValue: 'TimeOffRequests' },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'timeOffRequest',
                entityPropertyName: 'timeOffRequest',
                queryData: {
                  select: ['id'],
                  expand: {
                    timeOffRequest: { select: ['id', 'stateId', 'name'] },
                  },
                },
              } as MassOperationParameters,
            },
          ],
          params: {
            navigation: null,
            routeMode: null,
            view: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: TimeOffRequestListComponent,
        },
      ],
    }),
    CommentsModule,
  ],
  exports: [],
})
export class TimeOffRequestsModule {}
