import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomFieldEntityType } from 'src/app/shared/models/enums/custom-field-entity-type.enum';

@Injectable()
export class CustomFieldCardService {
  public detectChanges$ = new Subject<void>();

  public detectChanges() {
    this.detectChanges$.next();
  }

  public isRequiredFieldVisible(entityType: CustomFieldEntityType): boolean {
    switch (entityType) {
      case CustomFieldEntityType.ActOfAcceptance:
      case CustomFieldEntityType.ExpenseRequest:
      case CustomFieldEntityType.TimeSheetLine:
      case CustomFieldEntityType.ProjectTask:
      case CustomFieldEntityType.TimeAllocation:
      case CustomFieldEntityType.ProjectVersion:
      case CustomFieldEntityType.TimeOffRequest:
      case CustomFieldEntityType.Certificate:
      case CustomFieldEntityType.Issue:
        return false;
      default:
        return true;
    }
  }
}
